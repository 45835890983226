import React, { useState, useEffect } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Link as RouterLink } from 'react-router-dom';

import Leftnav from "../inc/Leftnav";

import {useNavigate} from "react-router-dom";
// import { useHistory } from "react-router-dom";

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import axios from "axios";

const BackHeader = ({ variant, ...props }) => {
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();
  // let history = useHistory();

  let [pic,setPic]=useState("");
  const dispatch = useDispatch();

  const data=useSelector((state) => {
    return state.users;
  });

  async function getReduxToken() {
    dispatch(getToken());
    console.log(data.users.pic)
    if (data.users.pic===null || data.users.pic==="") {
      setPic('user.png');
    } else {
      setPic(data.users.pic);
    }
    
    console.log("profile pic ---->", data.users.pic);
  }
  
  // async function getCheckToken() {

  //     var fd=new FormData();
  //     fd.append("enrollid",localStorage.getItem("enrollid"));
  //     var resp=await axios.post("https://safalata.net/safalatalara/api/checkusertoken",fd);
  //     var data=resp.data;
  //     if (data.usertoken!=localStorage.getItem('usertoken')) {
  //       localStorage.clear();
  //       let path = `/login`; 
  //       navigate(path);
  //     }
  // }

  function gobackNavigate() {
    let path = `../dashboard`;
    navigate(path);
    // navigate(-1);
  }
    
  useEffect(()=>{
      getReduxToken();

      // getCheckToken();
  },[])

  return (
<>

<Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" color="transparent" className="navbar">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}  alignItems="center">
            News
          </Typography> */}
          <Typography onClick={gobackNavigate}>
          <Button startIcon={<NavigateBeforeIcon style={{ fontSize: 50 }} />} labelStyle={{ fontSize: '63px'}} component={RouterLink} className="btnBack"></Button>
          {/* NavigateBeforeIcon */}
          {/* to="/dashboard" */}
          </Typography>
          {/* <Button startIcon={<NavigateBeforeIcon style={{ fontSize: 50 }} />} labelStyle={{ fontSize: '63px'}} component={RouterLink} onClick={gobackNavigate} className="btnBack" ></Button> */}

          <Grid display="flex"  sx={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
          <img src="images/logo.png" />
          </Grid>
          {/* <Button variant="contained"  startIcon={<DehazeOutlinedIcon />}  onClick={() => setOpen(!open)} className="btnRounded" ></Button> */}
          <Button className="btnUser">
            {pic!='user.png'?
            <img src={"https://safalata.net/safalatalara/uploadpic/"+pic} className="btnUser" />
            :
            <img src="images/user.png" className="btnUser" />
            }
          </Button>
        </Toolbar>
      </AppBar>
    </Box>




    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Drawer
          variant={variant}
          {...props}
          open={open}
          onClose={() => setOpen(false)}  className="drawerBox"
        >

           
            <Leftnav />

          
        </Drawer>
      </Grid>

      
    </Grid>
    </>

  );
};

export default BackHeader;