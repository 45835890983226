import React, {useState, useEffect} from 'react';
import axios from 'axios';
// import validator from 'validator';
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router-dom";

import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';



//RADIO GROUP IMPORT
import FormControl from '@mui/joy/FormControl';
// import FormLabel from '@mui/joy/FormLabel';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
//RADIO GROUP IMPORT



const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function LoginContent() {

    let navigate = useNavigate();

    let [phone,setPhone]=useState("");
    let [password,setPassword]=useState("");

    let [isbtn,setIsbtn]=useState(true);

    let errphone="Registered Phone Number Required";
    let errpassword="Password Required";

    let [iserrphone,setIsrrphone]=useState(false);
    let [iserrpassword,setIsrrpassword]=useState(false);

    let [alert,setAlert]=useState(false);
    let [msg,setMsg]=useState("");
    let [msgalert,setMsgalert]=useState("");
    let [msgcolor, setMsgcolor]=useState("");
    let [msgcode, setMsgcode]=useState("");
    
    let [exammode,setExammode]=useState("PACKAGE");

    let [ischeckexammodemock,setIscheckexammodemock]=useState(false);
    let [ischeckexammodepackage,setIscheckexammodepackage]=useState(true);

    async function studLogin() {
        setMsgcode("");
        var flag=1;
        if (phone.length<10) {
            flag=0;
            setIsrrphone(true);
        }
        if (password.length==0) {
            flag=0;
            setIsrrpassword(true);
        }
        if (flag==1) {
        
            var fd=new FormData();
            fd.append("phone",phone);
            fd.append("password",password);

            const response = await axios.post('https://safalata.net/safalatalara/api/studlogin_mobapp', fd);
            const data = response.data;

            setAlert(true);
            setMsg(data.msg);
            setMsgalert(data.alert);
            setMsgcode(data.msgcode);

            if (data.msgcode==1) {
                setMsgcolor('green');
            } else {
                setMsgcolor('red');
            }

            if (data.msgcode==1) {

                localStorage.setItem('tokenID',data.stoken);

                var tokenval=localStorage.getItem("tokenID");

                if (tokenval!=null) {

                    var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
                        headers: {
                        "Content-Type":"application/json",
                        "Authorization":"Bearer "+tokenval
                        }
                    });
                    var data2=await resp.json();
                    localStorage.setItem("enrollid", data2.enroll_id);
                    localStorage.setItem("name", data2.name);
                    localStorage.setItem("status", data2.status);
                    localStorage.setItem("batchid",data2.batch_slno);
                    localStorage.setItem("pic",data2.pic);
                    localStorage.setItem("usertoken",data2.usertoken);
                    localStorage.setItem("pasval",password);
                    localStorage.setItem("phoneval",phone);

                    localStorage.setItem("exammode",exammode);

                    setIsbtn(true);
                    let path = `/dashboard`;
                    navigate(path);

                }

            }
            
        }
        
    }

    function getchecklogin() {
        if (localStorage.getItem("tokenID")==null) {
            let path = `/login`; 
            navigate(path);
        }
    }


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let [mobile,setMobile]=useState("");
    let errmobile="Valid Mobile Number Enter";
    let [iserrmobile,setIsrrmobile]=useState(false);

    let [name,setName]=useState("");
    let errname="Enter Name";
    let [iserrname,setIsrrname]=useState(false);

    async function mocktestlogin() {
        var flag=1;

        if (mobile.length<10) {
            setIsrrmobile(true);
            flag=0;
        } else {
            setIsrrmobile(false);
        }

        if (name.length==0) {
            setIsrrname(true);
            flag=0;
        } else {
            setIsrrname(false);
        }
        
        if (flag==1) {
            var fd=new FormData();
            fd.append("mobile",mobile);
            fd.append("name",name);
            const response = await axios.post('https://safalata.net/safalatalara/api/studlogin_mock', fd);
            const data = response.data;

            localStorage.setItem("enrollid_mock", data.enroll_id);
            localStorage.setItem("name_mock", data.name);
            
            let path = `/dashboardmocktest`;
            navigate(path);

            

            // setAlert(true);
            // setMsg(data.msg);
            // setMsgalert(data.alert);
            // setMsgcode(data.msgcode);
        }
        

    }

    useEffect(()=>{
        getchecklogin();
    }, []);

    return (

        <>

        <Container className='terms_bg_login_new'>

        <Grid  textAlign="center">
            <img src="../../images/logo.svg" alt="" width={250} />      
            </Grid>

            <Grid container pb={2} px={3} >
            
            <Grid item xs={12} p={0} color="white">
                <Typography variant="p" component="p">Proceed with your</Typography>
                <Typography variant="h4" component="h4" pb={5} fontWeight="medium">Login</Typography>

                {/* {alert?<Typography variant="h4" component="h4" pb={5} align="center" fontWeight="medium" color={msgcolor}>{msg}</Typography>:''} */}
                {msgcode==1?
                <Typography pb={3}>
                <Alert variant="filled" severity="success">
                    {msg}
                </Alert>
                </Typography>
                :msgcode==2 || msgcode==3?
                <Typography pb={3}>
                <Alert variant="filled" severity="warning">
                    {msg}
                </Alert>
                </Typography>
                :''
                }


                <Typography variant="div" component="div" pb={3} >
                    <TextField id="standard-basic" label="Registered Mobile Number" variant="standard" sx={{ input: { size: 50, color: 'white', paddingLeft: '10px' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setPhone(ev.target.value);
                        if (phone.length==10) {
                        setIsrrphone(false);
                        }
                        setAlert(false);
                    }} />
                    {iserrphone?<Typography variant="div" component="div" pb={3} sx={{ color: 'error.main' }}>{errphone}</Typography>:''}
                </Typography>

                <Typography variant="div" component="div" pb={3} className = "whitetextfield" sx={{ fontSize: 'large', color: 'white' }}>
                    <TextField id="password" label="Password" type="password" sx={{ input: { size: 50, color: 'white', paddingLeft: '10px' } }} variant="standard" fullWidth onChange={(ev)=>{
                        setPassword(ev.target.value);
                        if (password.length>=1) {
                        setIsrrpassword(false);
                        }
                        setAlert(false);
                    }} />
                    {iserrpassword?<Typography variant="div" component="div" pb={3} sx={{ color: 'error.main' }}>{errpassword}</Typography>:''}
                </Typography>
                
                
                

                {/* <Typography variant="div" component="div" py={3} textAlign="center" >
                    <Link to="" underline="none" className='whitexText'>
                    New Registration
                    </Link> <Button variant="contained" className='btn-green' onClick={()=>{
                        let path = `/register`;
                        navigate(path);
                    }}>Click Here</Button> 
                </Typography> */}
                <Typography variant="div" component="div" py={1} sx={{ marginBottom: '10px', paddingLeft: '10px', background: '#fff', borderRadius: '10px', display: 'none' }}>
                <FormControl styles={{border: '1px solid #000'}} sx={{ display: 'none' }}>
                                
                    <RadioGroup
                        // aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="q1"
                        name="radio-buttons-group">
                        <Radio value={"PACKAGE"} name="mode" label={"PACKAGE EXAM"} variant="outlined" checked={ischeckexammodepackage} onChange={(e)=>{ 
                            setExammode(e.target.value);
                            setIscheckexammodemock(false);
                            setIscheckexammodepackage(true);

                        }} />
                        <Radio value={"MOCK"} name="mode" label={"MOCK EXAM"} variant="outlined" checked={ischeckexammodemock} onChange={(e)=>{ 
                            setExammode(e.target.value);
                            setIscheckexammodemock(true);
                            setIscheckexammodepackage(false);

                        }} />
                        
                        {/* <FormControlLabel value={e.answer_slno} control={<Radio />} label={e.answer.replace(/&apos;/g, "'")} disabled={isradio}  */}
                        
                        {/* // <Radio value={e.answer_slno} label={e.answer.replace(/&apos;/g, "'")} variant="outlined" disabled={isradio} onChange={()=>{ */}
                        {/* //     setAnsslno(e.answer_slno);
                        //     setIssavebtn(false); */}
                        {/* // }} /> */}

                        {/* // )} */}
                        
                    </RadioGroup>

                </FormControl>
                </Typography>

                <Button variant="contained" fullWidth className='btn-green' onClick={studLogin}>Login</Button> 

                <Grid container spacing={0} mt={4} ml={1} mr={0} fullWidth className='hbtn btn-lightblueregistration-mock' >
                     

                     <Typography variant="div" component="div" py={1} textAlign="center" >
                         <Link to="" underline="none" className='bluexText'>
                         For New Registration
                         </Link> <Button variant="contained" className='btn-green' onClick={()=>{
                             localStorage.removeItem('enrollid_mock');
                             let path = `/register`;
                             navigate(path);
                         }}>Click Here</Button> 
                     </Typography>
 
                  
                </Grid>


                <Typography variant="div" component="div" py={2} textAlign="center" sx={{ fontSize: '1.4rem' }} >
                    <Link to="/forgotpassword" underline="none" className='whitexText'>
                    Forgot Password?
                    </Link>
                </Typography>

                {/* <Typography variant="div" component="div" py={4} textAlign="center" >
                    <Button variant="contained" className='btn-bluedeep' sx={{ fontSize: '0.8rem' }} onClick={()=>{
                        // handleOpen();
                        let path = `/register`;
                        navigate(path);
                    }}>GUEST LOGIN<br/>DEMO MOCK TEST</Button>
                </Typography> */}

            </Grid>
            {/* <Grid sm={12} display="flex" justifyContent="center" alignItems="center"></Grid>  */}

            



                {/* <Grid item sm={6} xs={6}  p={1}>
                    <Button variant="contained" fullWidth className='hbtn btn-blue'>            
                    <Grid><img src="../../images/live-exam.png" alt="" /></Grid> 
                    <Grid>Live Exam</Grid>                
                    </Button>     
                </Grid>

                <Grid item sm={6} xs={6}  p={1}>
                    <Button variant="contained" fullWidth className='hbtn btn-yellow'>            
                    <Grid><img src="../../images/practice-exam.png" alt="" /></Grid> 
                    <Grid>Practice Exam</Grid>                
                    </Button>     
                </Grid> */}

            


                

                {/* <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid>
                <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid>

                <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid> */}

            </Grid>   

            <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={styles}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Demo Test Login
                    </Typography>
                    
                    <Typography variant="div" component="div" pt={3} pb={1} >Enter Mobile Number
                    <TextField id="standard-basic" label="10 Digits Mobile Number" variant="standard" sx={{ input: { size: 50, border: '1px solid #222', paddingLeft: 1  } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setMobile(ev.target.value);
                        if (mobile.length>=9) {
                            setIsrrmobile(false);
                        }
                        setAlert(false);
                    }} />
                    {iserrmobile?<Typography variant="div" component="div" pb={0} sx={{ color: 'error.main' }}>{errmobile}</Typography>:''}
                    </Typography>

                    <Typography variant="div" component="div" pb={1} >Enter Full Name
                    <TextField id="standard-basic" label="Mobile Number" variant="standard" sx={{ input: { size: 50, border: '1px solid #222', paddingLeft: 1 } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setName(ev.target.value);
                        if (name.length>0) {
                        setIsrrname(false);
                        }
                        setAlert(false);
                    }} />
                    {iserrname?<Typography variant="div" component="div" pb={0} sx={{ color: 'error.main' }}>{errname}</Typography>:''}
                    </Typography>

                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={mocktestlogin}>Demo Test Login</Button> <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>

        </Container>
 
        </>
    );
}

export default LoginContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;