import React  from 'react';

import PracticeScoreMocktestContent from "../content/PracticeScoreMocktestContent";

// import Header from "../inc/Header";
import BackHeaderMock from "../inc/BackHeaderMock";
import { Grid } from '@mui/material';



function PracticeScoreMocktest() {
    return(
        <>
        
        <Grid className='bg-greenlight-mock'>
        <Grid className='home_bg'>
        <BackHeaderMock /> 
        </Grid>

        <Grid py={2}>
        <PracticeScoreMocktestContent/>
        </Grid> 
        </Grid>
        </>
    );
}

export default PracticeScoreMocktest;