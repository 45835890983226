import React, {useRef, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
// import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


// import { createTheme } from '@mui/material/styles';


import { useSelector,useDispatch } from 'react-redux';

import { getToken } from "../IncSlice/BackHeaderSlice";

import { getNoticeBoard } from '../IncSlice/NoticeboardSlice';

import { getSuccessStory } from '../IncSlice/SuccessStorySlice';

import axios from 'axios';

import dateFormat from 'dateformat';


import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

// FOR RAZORPAY PAYMENT GATE INTEGRATION
import $ from 'jquery';
import useRazorpay, { RazorpayOptions } from "react-razorpay";
// import ExamListLoader from './ExamListLoader';
import PackageBoxLoader from './PackageBoxLoader';

import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SchoolIcon from '@mui/icons-material/School';

import TouchAppIcon from '@mui/icons-material/TouchApp';

// import  useRazorpay  from 'react-razorpay';
// FOR RAZORPAY PAYMENT GATE INTEGRATION

const styles = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



function IndexContent(props) {

    const Razorpay = useRazorpay();
    let navigate = useNavigate();

    

    const dispatch = useDispatch();

    const data=useSelector((state) => {
        return state.users;
    });
    const data2=useSelector((state) => {
        return state.notice;
    });
    const data3=useSelector((state) => {
        return state.story;
    });


    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    async function getReduxToken() {
        dispatch(getToken());
        setName(data.users.name);
        setEnrollid(data.users.enroll_id);
        setStatus(data.users.status);
    }

    let [noticeboardlist, setNoticeboardlist]=useState([]);

    let [noticedatetime, setNoticedatetime]=useState("");



    async function getReduxNoticeBoard() {
        dispatch(getNoticeBoard());
        // setNoticename(data2.noticeboard.noticename);
        setNoticeboardlist(data2.noticeboard.noticeboard);
        setNoticedatetime(data2.noticeboard.noticedatetime);
        // console.log('print--------->', data2.noticeboard.noticename);

        console.log("NOTICEBOARD===>",data2.noticeboard.noticeboard);
        console.log("NOTICEBOARD TIME===>",data2.noticeboard.noticedatetime);
    }

    let [allnoticeboardlist, setAllnoticeboardlist]=useState([]);

    async function noticeboarddetails() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard_mobapp");
        var data=resp.data;
        setAllnoticeboardlist(data.noticeboard);
    }

    async function getSuccessStoryFunc() {
        dispatch(getSuccessStory());
        // console.log("=========>",successstory);
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    let [oneqst,setOneqst]=useState("");

    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);

    let [exammodetxt,setExammodetxt]=useState("");

    

    function modalaihandleOpen() {
        setOneqst("AI ChatGPT Pending");
        handleOpen();
    }

    let [studstatus, setStudstatus]=useState("");
    async function getstudlogincheck() {
        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.net/safalatalara/api/studlogincheck",fd);
        var data=resp.data;
        setStudstatus(data);
    }

    let [exampackages,setExampackages]=useState([]);
    let [packageloader,setPackageloader]=useState(true);
    
    async function getExamPackages() {
        setPackageloader(true);
        var resp=await axios.get("https://safalata.net/safalatalara/api/getexampackages");
        var data=resp.data;
        setExampackages(data.obj);
        setPackageloader(false);

        // if (localStorage.getItem("exammode")==null) {
        //     setExammodetxt("New system has updated now....");
        //     setTimeout(()=>{
        //         handleOpen3();
        //         localStorage.setItem("exammode","PACKAGE");
        //     },2000);
        // }
    }

    useEffect(()=>{
        getReduxToken();
        getReduxNoticeBoard();
        getSuccessStoryFunc();
        getNoticeBoard();

        getstudlogincheck();

        getExamPackages();

        noticeboarddetails();
    }, []);

    return(
        <>
            <Container>
                <Grid container >
                    {/* {localStorage.getItem("exammode")==null || localStorage.getItem("exammode")=='PACKAGE'? */}
                        {packageloader==false?
                        <Grid container>
                            {exampackages.map((e)=>
                            <Grid item sm={6} xs={6} p={1} pb={1} onClick={()=>{
                                let path = '/packages/'+e.package_id;
                                navigate(path);
                                }}>
                                <Box sx={{borderRadius: 2}} variant="contained" fullWidth className='hbtn btn-bluebg' align={'center'} >
                                    {/* <Grid><img src="../../images/live-exam.png" alt="" /></Grid>  */}
                                    {e.package_name.length<=19?
                                    <Grid height={'190px'}>
                                        <Typography sx={{color: '#fff',fontSize: '2rem', fontWeight: 900, paddingTop: 2}}>{e.package_name}</Typography>
                                        <Box sx={{ marginTop: 1.5, borderRadius: 2, marginLeft: '10px', marginRight: '10px', background: '#ffffff'}}>
                                            <Typography sx={{color: '#3d2be0',fontSize: '1.2rem', fontWeight: 900}}>Hindi & English</Typography>
                                            
                                        </Box>
                                        <Box sx={{ marginTop: 0.2, marginLeft: '10px', marginRight: '10px'}}>
                                            <Typography><TouchAppIcon sx={{color: '#fff',fontSize: '2rem', fontWeight: 900}} /></Typography>
                                            
                                        </Box>
                                    </Grid>
                                    :
                                    <Grid height={'190px'}>
                                        <Typography sx={{color: '#fff', fontSize: '1.8rem', fontWeight: 900, paddingTop: 2.5}}>{e.package_name}</Typography>
                                        <Box sx={{ marginTop: 0.7, marginLeft: '10px', marginRight: '10px'}}>
                                            <Typography ><TouchAppIcon sx={{color: '#fff',fontSize: '2rem', fontWeight: 900}} /></Typography>
                                        </Box> 
                                    </Grid>   
                                            
                                    }
                                    <Box sx={{borderRadius: 2}} fullWidth pt={-3} className='hbtn btn-whitebg' align={'center'}>
                                    <Typography sx={{color: '#3d2be0',fontSize: '1.4rem', fontWeight: 900}}>(MCQ - {e.total_mcq})</Typography>
                                    </Box>
                                </Box>
                                
                            </Grid>
                            )}
                            {/* <Grid item sm={6} xs={6} p={1} onClick={()=>{
                                // let path = `/practicesetscore`;
                                let path = `/practicescore`;
                                navigate(path);
                                }}>
                                <Button variant="contained" fullWidth className='hbtn btn-pink'>            
                                <Grid><img src="../../images/practice-score.png" alt="" /></Grid> 
                                <Grid><Typography sx={{fontSize: '1.1rem'}}>Practice Score</Typography></Grid>                
                                </Button>     
                            </Grid>
                            <Grid item sm={6} xs={6} p={1} onClick={()=>{
                                let path = `/monthwisescore`;
                                navigate(path);
                                }}>
                                <Button variant="contained" fullWidth className='hbtn btn-redlight'>            
                                <Grid><img src="../../images/monthwise-score.png" alt="" /></Grid> 
                                <Grid><Typography sx={{fontSize: '1.1rem'}}>Monthly Score</Typography></Grid>                
                                </Button>
                            </Grid> */}
                            
                        </Grid>
                        
                        :
                        <PackageBoxLoader />
                    // :
                    //     packageloader==false?
                    //     <Grid container>
                    //         <Grid item sm={6} xs={6} p={1} onClick={()=>{
                    //             let path = `/upcomingliveexam`;
                    //             navigate(path);
                    //             }}>
                    //             <Button variant="contained" fullWidth className='hbtn btn-blue'>            
                    //             <Grid><img src="../../images/live-exam.png" alt="" /></Grid> 
                    //             <Grid>Live Exam</Grid>                
                    //             </Button>     
                    //         </Grid>
                            
                    //         <Grid item sm={6} xs={6} p={1} onClick={()=>{
                    //             let path = `/upcomingpracticeexam`;
                    //             navigate(path);
                    //             }}>
                    //             <Button variant="contained" fullWidth className='hbtn btn-yellow'>            
                    //             <Grid><img src="../../images/practice-exam.png" alt="" /></Grid> 
                    //             <Grid>Practice Exam</Grid>                
                    //             </Button>     
                    //         </Grid>

                    //         <Grid item sm={6} xs={6}  p={1} onClick={()=>{
                    //             let path = `/livescore`;
                    //             navigate(path);
                    //             }}>
                    //             <Button variant="contained" fullWidth className='hbtn btn-green-dashboard'>            
                    //             <Grid><img src="../../images/live-score.png" alt="" /></Grid> 
                    //             <Grid>Live Score</Grid>                
                    //             </Button>     
                    //         </Grid>

                    //         <Grid item sm={6} xs={6} p={1} onClick={()=>{
                    //             let path = `/practicescore`;
                    //             navigate(path);
                    //             }}>
                    //             <Button variant="contained" fullWidth className='hbtn btn-pink'>            
                    //             <Grid><img src="../../images/practice-score.png" alt="" /></Grid> 
                    //             <Grid>Practice Score</Grid>                
                    //             </Button>     
                    //         </Grid>
                    //     </Grid>
                    //     :
                    //     <PackageBoxLoader />
                    }
                    

                    <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        // let path = `/paymenthistory`;
                        let path = `/mockpracticesetlist`;
                        navigate(path);
                        }}>
                        <Box sx={{borderRadius: 2}} variant="contained" fullWidth className='hbtn btn-testpinkbg' align={'center'} >    
                        {/* <Grid><img src="../../images/pyment-history.png" alt="" /></Grid>  */}
                        <Grid height={'188px'}>
                            <Typography sx={{color: '#fff',fontSize: '2rem', fontWeight: 900, paddingTop: 4}}>Mock Test</Typography>
                            <Box sx={{ marginTop: 4, borderRadius: 2, marginLeft: '15px', marginRight: '15px', background: '#ffffff'}}><Typography sx={{color: '#b6048f',fontSize: '1.8rem', fontWeight: 900}}>FREE</Typography></Box>
                        </Grid>                
                        
                        {/* <Box sx={{borderRadius: 2}} fullWidth pt={-3} className='hbtn btn-whitebg' align={'center'}>
                        <Typography sx={{color: '#3d2be0',fontSize: '1.4rem', fontWeight: 900}}>(MCQ - 200)</Typography>
                        </Box> */}
                        </Box> 
                        
                    </Grid>

                    <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        let path = `/successstory`;
                        navigate(path);
                        }}>
                        <Box sx={{borderRadius: 2}} variant="contained" fullWidth className='hbtn btn-testbluebg' align={'center'} >    
                        <Grid height={'116px'}><SchoolIcon sx={{fontSize: '6rem', color: 'white', paddingTop: 1.5}} /></Grid> 
                        {/* <Grid height={'150px'}><Typography sx={{color: '#fff',fontSize: '2rem', fontWeight: 900, paddingTop: 4}}></Typography></Grid>                 */}
                        <Typography sx={{color: '#fff',fontSize: '1.5rem', fontWeight: 900}}>SUCCESS<br/>STORY</Typography>
                        {/* <Box sx={{borderRadius: 2}} fullWidth pt={-3} className='hbtn btn-whitebg' align={'center'}>
                        <Typography sx={{color: '#3d2be0',fontSize: '1.3rem', fontWeight: 900}}>SUCCESS STORY</Typography>
                        </Box> */}
                        </Box>      
                    </Grid>

                    <Grid item sm={6} xs={12} onClick={()=>{
                        //  className='hbtn btn-redlight'
                        let path = `/packageplan`;
                        navigate(path);
                        }}>
                        <Typography color={'#581d1d'} textAlign='center' fontWeight={'bold'} pt={1} variant="p" component="p" pb={1}> 
                            <Button variant="contained" className='btn-btndeepgreen' sx={{borderRadius: 3,fontSize: '1.4rem'}} onClick={()=>{
                                var path=`/packageplan`;
                                navigate(path);
                            }}><ShoppingCartIcon sx={{fontSize: '1.4rem', paddingRight: 1}} /> PAY NOW</Button>
                             {/* fullWidth */}
                        </Typography>
                    </Grid>

                    {/* <Grid item sm={6} xs={6} p={1} onClick={modalaihandleOpen}>
                        <Button variant="contained" fullWidth className='hbtn btn-bluelight-ai'>            
                        <Grid><img src="../../images/ai_chatgpt.png" alt="" /></Grid> 
                        <Grid>AI ChatGPT</Grid>                
                        </Button>     
                    </Grid> */}

                    {/* {datetimedays<8?
                        studstatus==2?
                        <Grid container xs={12} spacing={0} mt={2} ml={1} mr={0} p={3} fullWidth className='hbtn btn-bluenoticeboard' >
                            <Typography color={'#581d1d'} fontWeight={'bold'}> 
                                Please click here for payment &#8377; 300/- for 30 days
                            </Typography> 

                            <Typography color={'#581d1d'} fontWeight={'bold'} variant="p" component="p" pt={1} pb={1}> 
                            <Button variant="contained" fullWidth className='btn-green' onClick={()=>{
                                var oid = Math.random().toString(16).slice(2);
                                console.log(oid+','+fees+','+name+','+enrollid);
                                pay_online(oid,fees,name,enrollid);
                                }} >PAY NOW</Button> 
                            </Typography>
                        </Grid>

                        : studstatus==1?
                        
                        endpaymentdatecount>0?
                            <Grid container xs={12} spacing={0} mt={2} ml={1} mr={0} p={3} fullWidth className='hbtn btn-bluenoticeboard' >
                                <Typography color={'#581d1d'} fontWeight={'bold'}> 
                                {startTimer(dateFormat(endpaymentdate, "mmmm dd, yyyy HH:MM:00"))}
                                NEXT DATE OF PAYMENT ( {datetimeinterval} )
                                </Typography> 
                                
                                <Typography color={'#581d1d'} fontWeight={'bold'} variant="p" component="p" pt={1} pb={1}> 
                                <Button variant="contained" fullWidth className='btn-green' onClick={()=>{
                                    var oid = Math.random().toString(16).slice(2);
                                    console.log(oid+','+fees+','+name+','+enrollid);
                                    pay_online(oid,fees,name,enrollid);
                                }} disabled={btnpay}>PAY NOW</Button>
                                </Typography>
                            </Grid>

                            : 
                            ''
                        :
                        ''
                    :
                    ''
                    } */}




                




                    {/* <Grid container xs={12} spacing={0} mt={2} ml={1} mr={0} p={3} fullWidth className='hbtn btn-bluenoticeboard' >
                        
                            {noticeboardlist.map((e)=>
                            <Typography color={'#581d1d'} fontWeight={'bold'}> 
                                {e.noticeboard_details}  
                            </Typography> 
                            )}

                            <Typography color={'#581d1d'} fontWeight={'bold'} variant="p" component="p" pt={1} pb={1}> 
                                Notice Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}
                            </Typography> 

                    </Grid> */}

                    {/* <Grid container xs={12} spacing={0} mt={2} ml={1} mr={0} p={3} fullWidth className='hbtn btn-bluenoticeboard' >
                        
                            {allnoticeboardlist.map((e)=>
                            <Typography color={'#581d1d'} fontWeight={'bold'}> 
                                {e.noticeboard_details}  
                            </Typography> 
                            )}

                            <Typography color={'#581d1d'} fontWeight={'bold'} variant="p" component="p" pt={1} pb={1}> 
                                Notice Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}
                            </Typography> 

                    </Grid> */}



                    <Grid container>
                            <Grid item sm={6} xs={12} p={1} pb={1}>
                                <Box sx={{borderRadius: 2}} variant="contained" fullWidth className='hbtn btn-noticeboardbg' >
                                    <Box align={'center'} sx={{ paddingTop: 0.9 }}>
                                        <Box sx={{ marginTop: 1.5, borderRadius: 3, width: '200px', marginLeft: '10px', marginRight: '10px', background: '#ffffff'}}>
                                            <Typography sx={{color: '#7e5e06',fontSize: '1.2rem', fontWeight: 900}}>NOTICE BOARD</Typography>
                                        </Box>
                                    </Box>
                                    <Grid height={'200px'}>
                                        {allnoticeboardlist.map((e)=>
                                        <Grid>
                                            <Typography sx={{color: '#fff',fontSize: '1.4rem', fontWeight: 900, paddingTop: 1.4, paddingLeft: 1.2, paddingRight: 1.2}}>{e.noticeboard_details}</Typography>
                                        </Grid>
                                        )}
                                    </Grid>
                                    <Box sx={{borderRadius: 2}} fullWidth pt={-3} className='hbtn btn-whitebg' align={'center'}>
                                    <Typography sx={{color: '#7e5e06',fontSize: '1.4rem', fontWeight: 900}}>Notice Date : {dateFormat(noticedatetime, "mmmm dd, yyyy")}</Typography>
                                    {/* mmmm dd, yyyy HH:MM */}
                                    </Box>
                                </Box>
                                
                            </Grid>
                    </Grid>

                </Grid>   
                
                {/* <Razorpay
                    options={razorpayOptions}
                    onSuccess={handlePaymentSuccess}
                    onError={handlePaymentError}
                /> */}

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className='hbtn btn-bluenoticeboard'
                >
                    <Box sx={styles}>
                    
                    <Typography id="modal-modal-description" className="text-red" sx={{ mt: 1, mb: 2 }}>
                        {oneqst}
                    </Typography>
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>

                {/* <Modal
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={styles}>
                    
                    <Typography id="modal-modal-description" color={'#581d1d'} fontWeight={'bold'} className="text-red" sx={{ mt: 1, mb: 2 }}>
                        Please click here for payment &#8377; 300/- for 30 days
                    </Typography>
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" className='btn-green' onClick={()=>{
                            var oid = Math.random().toString(16).slice(2);
                            console.log(oid+','+fees+','+name+','+enrollid);
                            pay_online(oid,fees,name,enrollid);
                        }}>Pay Now</Button> 
                        <Button variant="contained" className='btn-maroon' onClick={handleClose2}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal> */}


                <Modal
                    open={open3}
                    onClose={handleClose3}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className='hbtn btn-bluenoticeboard'
                >
                    <Box sx={styles}>
                    
                    <Typography id="modal-modal-description" className="text-red" sx={{ mt: 1, mb: 2 }}>
                        {exammodetxt}
                    </Typography>
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={handleClose3}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>

                
            </Container>

        </>
    );
}

export default IndexContent;

