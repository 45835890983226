import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import dateFormat from 'dateformat';

import axios from 'axios';

import {useNavigate} from 'react-router-dom';

import ProfileInfoLoader from './ProfileInfoLoader';

function StartPracticeSetContent() {
    let navigate = useNavigate();

    let [isbtn,setIsbtn]=useState(true);

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');

    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER

    let [pic,setPic]=useState(null);

    if (localStorage.getItem("practicesetid")==null) {
        let path = `/dashboard`;
        navigate(path);
    }

    async function getToken() {
      
      var tokenval=localStorage.getItem("tokenID");
      if (tokenval!=null) {
        var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
          headers: {
            "Content-Type":"application/json",
            "Authorization":"Bearer "+tokenval
          }
        });
        var data=await resp.json();
        setStudname(data.name);
        setEnrollid(data.enroll_id);
        setStudstatus(data.status);
        setPic(data.pic);

        if (data.name.length>1 && localStorage.getItem("enrollid")==null) {
          localStorage.setItem("enrollid",data.enroll_id);
        }

        

      } else {
        let path = `/login`;
        navigate(path);
      }

    }

    
    //PRACTICE EXAM DETAILS
    let [examname,setExamname]=useState("");
    let [examtotalqst,setExamtotalqst]=useState("");
    let [examstdate,setExamstdate]=useState("");
    let [exameddate,setExameddate]=useState("");
    let [shuffle,setShuffle]=useState("");

    async function practicesetdetails_mock() {
      setLoader(true);

      let practicesetid=localStorage.getItem("practicesetid");
      var fd=new FormData();
      fd.append("practicesetid",practicesetid);
      var resp=await axios.post("https://safalata.net/safalatalara/api/practicesetdetails_mock",fd);
      var data=resp.data;
      setExamname(data.practicesetname);
      setExamtotalqst(data.qcount);
      // setShuffle(data.practiceexamsetselection_shuffle);

      localStorage.setItem("examname",data.practicesetname);
      localStorage.setItem("exameddate",data.eddate);
      localStorage.setItem("qcount",data.qcount);

      setLoader(false);
      setIsbtn(false);

      // localStorage.setItem("shuffle",data.shuffle);
    }

    useEffect(()=>{
      getToken();
      practicesetdetails_mock();
    },[])

     return(


        <>

        <Container>
            <Grid container >
                
                  <Grid item xs={12} p={1} style={{marginTop: "20px"}}>
                    {loader==false?
                    <Typography>
                      <Typography variant="p" component="p" fontWeight="bold" fontSize={18} pb={1}> 
                          {studname}
                      </Typography>
                      <Typography variant="p" component="p" fontWeight="bold" fontSize={18} pb={1}> 
                          Enrollment ID : <span>{enrollid}</span>
                      </Typography>
                    
                      <Typography variant="p" component="p" fontWeight="bold" fontSize={18} color="#00630f" pb={1}> 
                    {studstatus==1?
                      "STATUS - ACTIVE"
                    :
                      "STATUS - INACTIVE"         
                    }
                      </Typography>
                    </Typography>
                    :
                    <ProfileInfoLoader />
                    }

                    {/* <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
                    Start Practice Exam
                    </Typography> */}
                    {/* <Grid item xs={12} p={1} style={{marginTop: "20px"}}> */}
                    <Card className='borderRadius10' style={{marginTop: "30px"}}>
                    <CardContent>
                        <Typography variant="h6" component="div" mb={2} pt={1.5} textAlign="center" className='text-green lineheight'>
                        Go To Test Your <br />Practice Set Online
                        </Typography>
                        <Typography mb={1} mt={4}>
                        This is Multiple Choice Practice Set To Test Your Knowledge
                        </Typography>
                        <Typography mb={1} mt={2} >
                        Practice Set Name : {examname}
                        </Typography>

                        <Typography mb={1} mt={2} >
                        Number of Questions : {examtotalqst}
                        </Typography>
                        {/* <Typography variant="body2"  mb={1} >
                        Exam Date & Time : {dateFormat(examstdate, "dd-mm-yyyy ( HH:MM TT")} - {dateFormat(exameddate, "HH:MM TT )")}
                        </Typography> */}
                        <Typography mb={1} mt={2} >
                        Question Type : Multiple Choice
                        </Typography>
                        <Typography mb={1} mt={2} >
                        Shuffling Mode : {localStorage.getItem("shuffle")==1? "Full Shuffling" : localStorage.getItem("shuffle")==2? "Partial Shuffling" : "Non Shuffling"}
                        </Typography>

                                              
                        

                    </CardContent>
                    
                    </Card >


                    

                </Grid>

                <Grid item xs={12} p={1} style={{marginTop: "30px"}}>
                    <Button fullWidth variant='contained' className='btn-green' onClick={async()=>{
                          console.log(localStorage.getItem("shuffle"));
                        
                          // if (localStorage.getItem("shuffle")==2) {
                          var fd=new FormData();
                          fd.append("practicesetid",localStorage.getItem("practicesetid"));
                          fd.append("enrollid",localStorage.getItem("enrollid"));
                          var resp=await axios.post("https://safalata.net/safalatalara/api/addstartpracticesetschedule",fd);
                          var data=resp.data;

                          if (localStorage.getItem("shuffle")==2) {
                            localStorage.setItem("querypagecount",0);

                            localStorage.setItem("exameddate",data.eddate);
                            let path = `/practicesetshuffle/1`;
                            navigate(path);
                          } else {
                            localStorage.setItem("querypagecount",0);

                            localStorage.setItem("exameddate",data.eddate);
                            let path = `/practiceset/1`;
                            navigate(path);
                          }
                          // } else {
                          //   let path = `/practiceset/1`;
                          //   navigate(path);
                          // }
                        
                    }} disabled={isbtn} >Start Practice Set</Button>
                </Grid>

            </Grid>   
        </Container>

        </>
    );
}

export default StartPracticeSetContent;
