import React  from 'react';

import PackagePlanContent from "../content/PackagePlanContent";

// import Header from "../inc/Header";
import BackHeader from "../inc/BackHeader";

import { Grid } from '@mui/material';

function PackagePlan() {
    return(
        <>
        
        <Grid className='bg-greenlight'>
        <Grid className='home_bg'>
        <BackHeader /> 
        </Grid>

        <Grid py={2}>
        <PackagePlanContent/>
        </Grid> 

        </Grid>
       
        </>
    );
}

export default PackagePlan;