import React, { useState, useEffect } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import LogoutIcon from '@mui/icons-material/Logout';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Link as RouterLink } from 'react-router-dom';

import Leftnav from "../inc/Leftnav";

import {useNavigate} from "react-router-dom";

// import { useSelector,useDispatch } from 'react-redux';
// import { getToken } from "../IncSlice/BackHeaderSlice";

const BackHeaderMock = ({ variant, ...props }) => {
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();

  return (
<>

<Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" color="transparent" className="navbar">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}  alignItems="center">
            News
          </Typography> */}

          <Button startIcon={<NavigateBeforeIcon style={{ fontSize: 50 }} />} labelStyle={{ fontSize: '63px'}} component={RouterLink} to="/dashboardmocktest"  className="btnBack" ></Button>

          <Grid display="flex"  sx={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
          <img src="images/logo.png" />
          </Grid>
          {/* <Button variant="contained"  startIcon={<DehazeOutlinedIcon />}  onClick={() => setOpen(!open)} className="btnRounded" ></Button> */}
          <Button variant="contained"  startIcon={<PowerSettingsNewIcon style={{ color: 'white', size: '30px' }} />}  className="btnRounded-logout btn" onClick={()=>{
            localStorage.removeItem('enrollid_mock');
            let path = `/login`;
            navigate(path);
          }}></Button>
        </Toolbar>
      </AppBar>
    </Box>




    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Drawer
          variant={variant}
          {...props}
          open={open}
          onClose={() => setOpen(false)}  className="drawerBox"
        >

           
            <Leftnav />

          
        </Drawer>
      </Grid>

      
    </Grid>
    </>

  );
};

export default BackHeaderMock;