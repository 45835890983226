import React  from 'react';

import PracticeSetContent from "../content/PracticeSetContent";

// import BackHeader from '../inc/BackHeader';
// import ExamBackHeader from '../inc/ExamBackHandler';

import { Grid } from '@mui/material';
import ExamBackHeaderPackage from '../inc/ExamBackHeaderPackage';

function PracticeSet() {
    return(
        <>
        
        <Grid className='terms_bg '>
        <Grid className='home_bg'>
        {/* <BackHeader />  */}
        {/* <ExamBackHeader/> */}
        <ExamBackHeaderPackage />
        </Grid>

        <Grid py={2}>
        <PracticeSetContent/>
        </Grid> 

        </Grid>
       
        </>
    );
}

export default PracticeSet;