import React, { useEffect, useState } from 'react';
import axios from 'axios';


import Container from '@mui/material/Container';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';


function ProfileSettingContent() {

    if (localStorage.getItem("tokenID")==null) {
        window.location.href="/login";
     }
  
     function getNostorage() {
        if (localStorage.getItem("livesetid")!=null) {
          localStorage.removeItem("livesetid");
        }
     }
     
     let [name,setName]=useState("");
     let [enroll,setEnroll]=useState("");

     let [password,setPassword]=useState("");
     let [passworderr,setPassworderr]=useState(false);

     let [password2,setPassword2]=useState("");
     let [passworderr2,setPassworderr2]=useState(false);

     let [checkmsg,setCheckmsg]=useState("");

     let [isupdate,setIsupdate]=useState(false);

     async function getToken() {
        var tokenval=localStorage.getItem("tokenID");
        if (tokenval!=null) {
           var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
              headers: {
                 "Content-Type":"application/json",
                 "Authorization":"Bearer "+tokenval
              }
           });
           var data=await resp.json();
           
           setName(data.name);
           setEnroll(data.enroll_id);
           
  
           } else {
           window.location.href="/";
           }
  
     }
  
     async function passwordupdate() {
        var noerr=1;
        
        setIsupdate(false);
        setCheckmsg("");
        
        if (password.length>=6) {
            setPassworderr(false);  
            //noerr=1;
        } else {
            noerr=0;
            setPassworderr(true);  
        }
        
        if (password2.length>=6) {
            setPassworderr2(false);  
            //noerr=1;
        } else {
            noerr=0;
            setPassworderr2(true);  
        }

        if (password==password2 && password.length>=6) {
            noerr=1;
            setCheckmsg("");
        } else {
            noerr=0;
            setCheckmsg("Password Must Be Same and Minimum 6-digits characters!!");
        }

        if (noerr==1) {
            // alert(noerr)
            var fd=new FormData();
            fd.append("password",password);
            fd.append("enrollid",localStorage.getItem("enrollid"));
            var resp=await axios.post("https://safalata.net/safalatalara/api/passwordupdate",fd);
            var data=resp.data;
            setIsupdate(true);
            setPassword("");
            setPassword2("");
        }
        
     }
  
     useEffect(()=>{
        getToken();
        getNostorage();
     },[])
    return(
        <>

        <Container>
            <Grid container py={3} sx={{textAlign: 'center'}}>
                <Typography variant="h6" component="h6" pb={1} pl={12} fontWeight="normal" align={"center"}>Update Password</Typography>
                {isupdate?
                <Grid container pb={0} px={1}  sx={{textAlign: 'center'}}>
                    <Typography variant="h6" align="center" component="h6" pb={1} pl={3} fontWeight="normal" sx={{color: 'green'}}>Password Updated Successfully!!</Typography>
                </Grid>
                :
                ''
                }
                {checkmsg.length>0?
                <Grid container pb={0} px={1}  sx={{textAlign: 'center'}}>
                    <Typography variant="h6" align="center" component="h6" pb={1} fontWeight="normal" sx={{color: 'red'}}>{checkmsg}</Typography>
                </Grid>
                :
                ''
                }
            </Grid>

            <Grid container pb={2} px={3} >
                <Grid item xs={12}  p={0} color="white">
                    {/* <InputLabel id="demo-simple-select-standard-label" className='large'>Name</InputLabel> */}
                    <Typography variant="div" component="div" pb={3}>

                    <TextField id="name" label="" disabled value={name} variant="outlined" fullWidth className = "textfield" />

                    </Typography>

                    
                    
                    
                    <Typography variant="div" component="div" pb={3}>
                    {passworderr?
                    <TextField id="password" label="Password" type="password" error variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setPassword(e.target.value);
                        if (password.length==6) {
                            setPassworderr(false);
                        } else {
                            setPassworderr(true);
                        }
                    }} />
                    :
                    <TextField id="password" label="Password" type="password" variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setPassword(e.target.value);
                        if (password.length==6) {
                            setPassworderr(false);
                        } else {
                            setPassworderr(true);
                        }
                    }} />
                    }
                    </Typography>

                    <Typography variant="div" component="div" pb={3}>
                    {passworderr2?
                    <TextField id="password" label="Re Type Password" type="password" error variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setPassword2(e.target.value);
                        if (password2.length>=6) {
                            setPassworderr2(false);
                        } else {
                            setPassworderr2(true);
                        }
                    }} />
                    :
                    <TextField id="password" label="Re Type Password" type="password" variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setPassword2(e.target.value);
                        if (password2.length>=6) {
                            setPassworderr2(false);
                        } else {
                            setPassworderr2(true);
                        }
                    }} />
                    }
                    </Typography>

                    

                    <Button variant="contained" mb={3} fullWidth className='btn-green' onClick={passwordupdate}>            
                    Update Password
                    </Button> 

                    {/* <Typography variant="div" component="div" py={3} textAlign="center" >
                    <Link to="/login" underline="none" className='whitexText'>
                        Login
                    </Link>
                    </Typography> */}
                

                </Grid>
            </Grid>

            
        </Container>

        </>
    );
}

export default ProfileSettingContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;