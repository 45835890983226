import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
// import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// import StarBorder from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';

import CachedIcon from '@mui/icons-material/Cached';

// import Grid from '@mui/material/Grid';

import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Link } from "react-router-dom";

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { useRef, useEffect, useState } from "react";

import Typography from '@mui/material/Typography';

import { getToken } from "../IncSlice/BackHeaderSlice";

import { useSelector,useDispatch } from 'react-redux';


export default function Leftnav() {
  const navigate=useNavigate();
  const [open, setOpen] = React.useState(false);
  const [resultopen, setResultopen] = React.useState(false);
  const [jobopen, setJobopen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const resulthandleClick= () => {
    setResultopen(!resultopen);
  };

  const jobhandleClick= () => {
    setJobopen(!jobopen);
  };

  const editproileClick = () =>{
    const path=`../profilesetting`;
    navigate(path);
  };

  const proileimageClick = () =>{
    const path=`../fileupload`;
    navigate(path);
  };

  const changepasswordClick = () =>{
    const path=`../changepassword`;
    navigate(path);
  };

  const livescoreClick = () => {
    const path=`../livescore`;
    navigate(path);
  };

  const practicescoreClick = () => {
    const path=`../practicescore`;
    navigate(path);
  };

  const monthlyscoreClick = () => {
    const path=`../monthwisescore`;
    navigate(path);
  };

  const paymenthistoryClick = () =>{
    const path=`../paymenthistory`;
    navigate(path);
  }

  const dispatch = useDispatch();

  const data=useSelector((state) => {
      return state.users;
  });

  let [pic,setPic]=useState("");
  async function getReduxToken() {
    dispatch(getToken());
    if (data.users.pic===null || data.users.pic==="") {
      setPic('user.png');
    } else {
      setPic(data.users.pic);
    }
    // setEnrollid(data.users.enroll_id);
    // setStatus(data.users.status);
  }

  useEffect(()=>{
    getReduxToken();
  }, []);

  return (
    <List
      sx={{ width: '100%', maxWidth: 450, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        // <ListSubheader>
          <ListSubheader sx={{ pt: 1, pb:2 }} component="div" id="nested-list-subheader">
            <Link to="/"><img src="../../images/logo.png" alt="" /></Link>
            <Box sx={{ pl: 0, fontWeight: 700, fontSize: '1rem', borderRadius: '10px',p: 2 }} style={{ color: 'red' }}>
              {/* {localStorage.getItem("name").split(' ')[0]} */}
              <Typography sx={{fontWeight: 700}}>
              {pic!='user.png'?
              <img src={"https://safalata.net/safalatalara/uploadpic/"+pic} className="leftnavUser" />
              :
              <img src="images/user.png" className="leftnavUser" />
              }
              </Typography>
              <Typography sx={{fontWeight: 700}}>{localStorage.getItem("name")}</Typography>
              <Typography sx={{fontWeight: 700, color: '#0fa602'}}>Enrollment ID - {localStorage.getItem("enrollid")}</Typography>
            </Box>
            
          </ListSubheader>

        // </ListSubheader>

      }
    >
    

     
      {/* <ListItemButton>
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <ListItemText primary="Sent mail" />
      </ListItemButton> */}
      
      {/* <ListItemButton>
        <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon>
        <ListItemText primary="Drafts" />
      </ListItemButton> */}

      <ListItemButton  onClick={handleClick} style={{ color: '#fff', backgroundColor: '#0b25b8', border: '1px solid #4356bf', borderRadius: '5px' }}>
        <ListItemIcon>
          <Person3OutlinedIcon style={{ color: '#fff' }} />
        </ListItemIcon>
        <ListItemText primary="Manage Profile" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={editproileClick} style={{ color: '#fff', backgroundColor: '#0b25b8' }}>          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: '#fff' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Profile Settings" 
            />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={changepasswordClick} style={{ color: '#fff', backgroundColor: '#0b25b8' }}>          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: '#fff' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Change Password" 
            />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={proileimageClick} style={{ color: '#fff', backgroundColor: '#0b25b8' }}>          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: '#fff' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Update Profile Picture" 
            />
          </ListItemButton>
        </List>



      </Collapse>

      

    
      <ListItemButton onClick={resulthandleClick} style={{ color: '#fff', backgroundColor: '#0b25b8', border: '1px solid #4356bf', borderRadius: '5px' }} >
        <ListItemIcon>
          <ContentPasteSearchOutlinedIcon style={{ color: '#fff' }} />
        </ListItemIcon>
        <ListItemText primary="Practice Result" />
        {resultopen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={resultopen} timeout="auto" unmountOnExit>
        {/* List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={livescoreClick} style={{ color: '#fff', backgroundColor: '#0b25b8' }} >          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: '#fff' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Live Exam Result" 
            />
          </ListItemButton>
        </List>< */}

        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={monthlyscoreClick} style={{ color: '#fff', backgroundColor: '#0b25b8' }}>          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: '#fff', backgroundColor: '#0b25b8' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Monthly Score Report" 
            />
          </ListItemButton>
        </List>

        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={practicescoreClick} style={{ color: '#fff', backgroundColor: '#0b25b8' }}>          
            <ListItemIcon>
              <ArrowRightIcon style={{ color: '#fff', backgroundColor: '#0b25b8' }} />
            </ListItemIcon>
            <ListItemText 
            primary="Practice Exam Result" 
            />
          </ListItemButton>
        </List>



      </Collapse>


      <ListItemButton onClick={paymenthistoryClick} style={{ color: '#fff', fontWeight: '700', backgroundColor: '#0b25b8', border: '1px solid #4356bf', borderRadius: '5px' }}>
        <ListItemIcon>
          <CurrencyRupeeOutlinedIcon color='primary' style={{ color: '#fff', fontWeight: '700' }} />
        </ListItemIcon>
        <ListItemText primary="Payment History" />
      </ListItemButton>

      {/* <ListItemButton sx={{ top: 20 }} style={{ color: '#fff', fontWeight: '700', backgroundColor: '#0b25b8', border: '1px solid #4356bf', borderRadius: '5px' }} onClick={()=>{
        localStorage.setItem("exammode","PACKAGE");
        window.location.href="/dashboard";
        // const path=`/dashboard`;
        // navigate(path);
      }}>
        <ListItemIcon>
          <CachedIcon style={{ color: '#fff', fontWeight: '700' }} />
        </ListItemIcon>
        <ListItemText primary="Transfer To Package Exam" />
      </ListItemButton>

      <ListItemButton sx={{ top: 10 }} style={{ color: '#fff', fontWeight: '700', backgroundColor: '#0b25b8', border: '1px solid #4356bf', borderRadius: '5px' }} onClick={()=>{
        localStorage.setItem("exammode","MOCK");

        window.location.href="/dashboard";
        // const path=`/dashboard`;
        // navigate(path);
      }}>
        <ListItemIcon>
          <CachedIcon style={{ color: '#fff', fontWeight: '700' }} />
        </ListItemIcon>
        <ListItemText primary="Transfer To Mock Exam" />
      </ListItemButton> */}

      <ListItemButton sx={{ top: 30}} onClick={()=>{
        localStorage.clear();
        const path=`/login`;
        navigate(path);
      }} style={{ color: '#fff', fontWeight: '700', backgroundColor: '#fc036f', border: '1px solid #db0049', borderRadius: '5px' }}>
        <ListItemIcon>
          <PowerSettingsNewIcon style={{ color: '#fff', fontWeight: '700' }} />
        </ListItemIcon>
        <ListItemText primary={<Typography variant="h6" style={{ color: '#fff' }}>Logout</Typography>} style={{ color: '#fff', fontSize: '1rem', fontWeight: 700 }} />
      </ListItemButton>


      <ListItemButton sx={{ top: 60 }}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Version : v2.0.0" />
      </ListItemButton>
      
{/* Today is Thursday, May 18, 2023

    Dashboard
    Manage My Profile
        Profile Settings
        Change Password
        Update Profile Picture
    Payment Section
        Payment History 
    Section Result
        Live Exam Result
        Month Wise Score Report
        Practice Exam Result

    Section Jobs
        FreeJobAlert - Government Jobs
        Sarkari Results - Government Jobs
        Freshers World - Government Jobs */}



    </List>
  );
}
