import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

// import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import TextField from '@mui/material/TextField';

import {useNavigate} from "react-router-dom";

function UpdatePasswordContent() {

    let navigate = useNavigate();
    
    // let [name,setName]=useState("");
    // let [enroll,setEnroll]=useState("");

    let [password,setPassword]=useState("");
    let [passworderr,setPassworderr]=useState(false);

    let [password2,setPassword2]=useState("");
    let [passworderr2,setPassworderr2]=useState(false);

    let [checkmsg,setCheckmsg]=useState("");

    let [isupdate,setIsupdate]=useState(false);

    async function passwordupdatebyphone() {

        var noerr=1;
        
        setIsupdate(false);
        setCheckmsg("");
        
        if (password.length>=6) {
            setPassworderr(false);  
            //noerr=1;
        } else {
            noerr=0;
            setPassworderr(true);  
        }
        
        if (password2.length>=6) {
            setPassworderr2(false);  
            //noerr=1;
        } else {
            noerr=0;
            setPassworderr2(true);  
        }

        if (password==password2 && password.length>=6) {
            noerr=1;
            setCheckmsg("");
        } else {
            noerr=0;
            setCheckmsg("Password Must Be Same and Minimum 6-digits characters!!");
        }

        if (noerr==1) {
            // alert(noerr)
            var fd=new FormData();
            fd.append("password",password);
            fd.append("phone",localStorage.getItem("phone"));
            var resp=await axios.post("https://safalata.net/safalatalara/api/passwordupdatebyphone",fd);
            var data=resp.data;

            if (data.msgcode==1) {
                setIsupdate(true);
                setPassword("");
                setPassword2("");

                setTimeout(()=>{
                    localStorage.removeItem('phone');
                    let path = `/login`; 
                    navigate(path);

                }, 2000);
            }

        }
    
    }

    return(
        <>

        <Container className='terms_bg'>

            <Grid  textAlign="center">
                <img src="../../images/logo.svg" alt="" width={250} />      
            </Grid>

            <Grid container pb={2} px={3} >

            <Grid item xs={12}  p={0} color="white">
                <Typography variant="p" component="p">Proceed with your</Typography>
                <Typography variant="h6" component="h6" pb={1} fontWeight="normal">Update Password</Typography>
                {isupdate?
                <Grid container pb={0} px={1}  sx={{textAlign: 'center'}}>
                    <Typography variant="h6" align="center" component="h6" pb={1} fontWeight="normal" sx={{color: 'green'}}>New Password Update Successfully!</Typography>
                </Grid>
                :
                ''
                }
                {checkmsg.length>0?
                <Grid container pb={0} px={1}  sx={{textAlign: 'center'}}>
                    <Typography variant="h6" align="center" component="h6" pb={1} fontWeight="normal" sx={{color: 'red'}}>{checkmsg}</Typography>
                </Grid>
                :
                ''
                }
            </Grid>

            <Grid container pb={2} px={3} >
                <Grid item xs={12}  p={0} color="white">
                    
                    
                    <Typography variant="div" component="div" pb={3}>
                    {passworderr?
                    <TextField id="password" label="Password" type="password" error variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setPassword(e.target.value);
                        if (password.length==6) {
                            setPassworderr(false);
                        } else {
                            setPassworderr(true);
                        }
                    }} />
                    :
                    <TextField id="password" label="Password" type="password" variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setPassword(e.target.value);
                        if (password.length==6) {
                            setPassworderr(false);
                        } else {
                            setPassworderr(true);
                        }
                    }} />
                    }
                    </Typography>

                    <Typography variant="div" component="div" pb={3}>
                    {passworderr2?
                    <TextField id="password" label="Re Type Password" type="password" error variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setPassword2(e.target.value);
                        if (password2.length>=6) {
                            setPassworderr2(false);
                        } else {
                            setPassworderr2(true);
                        }
                    }} />
                    :
                    <TextField id="password" label="Re Type Password" type="password" variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setPassword2(e.target.value);
                        if (password2.length>=6) {
                            setPassworderr2(false);
                        } else {
                            setPassworderr2(true);
                        }
                    }} />
                    }
                    </Typography>

                    

                    <Button variant="contained" mb={3} fullWidth className='btn-green' onClick={passwordupdatebyphone}>            
                    Update Password
                    </Button> 

                    {/* <Typography variant="div" component="div" py={3} textAlign="center" >
                    <Link to="/login" underline="none" className='whitexText'>
                        Login
                    </Link>
                    </Typography> */}
                

                </Grid>
            </Grid>
        
        </Grid>
            
            
        </Container>

        </>
    );
}

export default UpdatePasswordContent;

