import React, {useState} from 'react';
import axios from 'axios';
import validator from 'validator';
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router-dom";

import Container from '@mui/material/Container';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import InputAdornment from "@mui/material/InputAdornment";

//RADIO GROUP IMPORT
import FormControl from '@mui/joy/FormControl';
// import FormLabel from '@mui/joy/FormLabel';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
//RADIO GROUP IMPORT

import Alert from '@mui/material/Alert';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Grid from '@mui/material/Grid';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function RegisterContent() {

    

    let navigate = useNavigate();

    // const [progress, setProgress] = useState(0);

    // const baseurl="https://www.safalata.in/safalatalara";

    let [name,setName]=useState("");
    let [dob,setDob]=useState("");
    let [phone,setPhone]=useState("");
    let [email,setEmail]=useState("");
    let [city,setCity]=useState("");
    let [qualification,setQualification]=useState(null);
    let [extra,setExtra]=useState(null);
    let [password,setPassword]=useState("");
    let [password2,setPassword2]=useState("");

    let [studentmode,setStudentmode]=useState(1);
    let [isstudentmode,setIsstudentmode]=useState(false);

    let [nameerr,setNameerr]=useState("");
    let [doberr,setDoberr]=useState("");
    let [phoneerr,setPhoneerr]=useState("");
    let [emailerr,setEmailerr]=useState("");
    let [cityerr,setCityerr]=useState("");
    let [qualificationerr,setQualificationerr]=useState("");
    let [extraerr,setExtraerr]=useState("");
    let [passworderr,setPassworderr]=useState("");

    let [img,setImg]=useState(null);

    let [isquali,setIsquali]=useState(false);
    let [isextra,setIsextra]=useState(false);

    let [isbtn,setIsbtn]=useState(true);
    let [ischeck,setIscheck]=useState(false);

    let [msg,setMsg]=useState("");
    let [msgcode,setMsgcode]=useState("");
    let [msgcolor,setMsgcolor]=useState("");

    let [waitmsg,setWaitmsg]=useState("");

    let [mapassingyrlabel,setMapassingyrlabel]=useState("");
    let [mapassingyr,setMapassingyr]=useState(null);
    let [ismapassing,setIsmapassing]=useState(true);

    let [extrapassingyrlabel,setExtrapassingyrlabel]=useState("");
    let [extrapassingyr,setExtrapassingyr]=useState(null);
    let [isextrapassing,setIsextrapassing]=useState(true);
        
    let [loader,setLoader]=useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    async function formsubmit() {

        setWaitmsg("");

        setMsgcode("");

        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth"
        // });

        // window.scrollTo(0, 0);

        document.getElementById('terms_bg_register_new').scrollTo({
                top: 0,
                behavior: "smooth"
            });

        // console.log(studentmode);

        var flag=1;

        if (name.length==0) {
            setNameerr("Required Name");
            flag=0;
        }
        if (dob.length==0) {
            setDoberr("Required Date of Birth");
            flag=0;
        }
        if (phone.length<10 || phone.length>10) {
            setPhoneerr("Required Valid Mobile Number");
            flag=0;
        }
        if (!validator.isEmail(email)) {
            setEmailerr("Required Valid Email ID");
            flag=0;
        }
        if (city.length==0) {
            setCityerr("Required City");
            flag=0;
        }
        // if (isquali==false) {
        //     setQualificationerr("Required Qualification");
        //     flag=0;
        // }
        if (studentmode.length=="") {
            setIsstudentmode(true);
            flag=0;
        }
        // if (isextra==false) {
        //     setExtraerr("Required PG Course");
        //     flag=0;
        // }
        if (password.length>0 && password2.length>0) {
            if (password.length<6 || password2.length<6 && password!=password2) {
                setPassworderr("Required 6-Alfanumeric/Digits Password");
                flag=0;
            } else if (password.length>=6 && password2.length>=6 && password!=password2) {
                setPassworderr("Password does not match");
                flag=0;
            }
        } else {
                setPassworderr("Required 6-Alfanumeric/Digits Password");
                flag=0;
        }

        if (flag==1) {
            // setLoader(true);
            handleOpen();
            setWaitmsg("Please Wait....");
            
            setIsbtn(true);

            var fd=new FormData();
            fd.append("name",name);
            fd.append("dob",dob);
            fd.append("phone",phone);
            fd.append("email",email);
            fd.append("city",city);
            fd.append("qualification",qualification);
            fd.append("mapassingyr",mapassingyr);
            fd.append("extra",extra);
            fd.append("extrapassingyr",extrapassingyr);
            fd.append("password",password);
            fd.append("img",img);
            fd.append("studentmode",studentmode);
            var resp=await axios.post("https://www.safalata.net/safalatalara/api/studregister",fd);
            var data=resp.data;
            setWaitmsg("");
            // setLoader(false);
            handleClose();

            document.getElementById('terms_bg_register_new').scrollTo({
                top: 0,
                behavior: "smooth"
            });

            if (data.msgcode==1) {
                setMsg(data.msg);
                setMsgcode(data.msgcode);
                setMsgcolor(data.textcolor);

                setIsbtn(true);
                setIscheck(true);

                setName("");
                setDob("");
                setPhone("");
                setEmail("");
                setCity("");
                setQualification("");
                setExtra("");
                setPassword("");
                setPassword2("");
                setNameerr("");
                setDoberr("");
                setPhoneerr("");
                setEmailerr("");
                setCityerr("");
                setQualificationerr("");
                setExtraerr("");
                setPassworderr("");

                handleOpen2();
                // setTimeout(()=>{
                    
                // },3000);

            } else if (data.msgcode==2) {
                setMsg(data.msg);
                setMsgcode(data.msgcode);
                setMsgcolor(data.textcolor);
                setIsbtn(false);
  
            }


        }

    }

    return (

        <>

        <Container id="terms_bg_register_new" className='terms_bg_register_new'>

            <Grid  textAlign="center">
            <img src="../../images/logo.svg" alt="" width={150} />      
            </Grid>

            <Grid container pb={2} px={3} >
            
            <Grid item xs={12} p={0} color="white">
                {/* <Typography variant="p" component="p">Proceed with your</Typography> */}
                <Typography variant="h5" component="h5" pb={5} fontWeight="medium" align="center">Registration</Typography>

                {/* {alert?<Typography variant="h4" component="h4" pb={5} align="center" fontWeight="medium" sx={{ color: 'error.warning' }}>{msg}</Typography>:''} */}
                
                {/* <Typography variant="h4" component="h4" pb={5} align="center" fontWeight="medium" sx={{ color: 'error.warning' }}>{msg}</Typography> */}
                {msgcode==1?
                <Typography pb={3}>
                <Alert variant="filled" severity="success">
                    {msg}
                </Alert>
                </Typography>
                :msgcode==2 || msgcode==3 || msgcode==4?
                <Typography pb={3}>
                <Alert variant="filled" severity="warning">
                    {msg}
                </Alert>
                </Typography>
                :''
                }
                <Typography variant="div" component="div" pb={3} sx={{ fontSize: 16 }} >Student Name (In English)
                    <TextField id="standard-basic" label="" variant="standard" sx={{ input: { fontSize: 24, color: 'white' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setName(ev.target.value);
                        if (ev.target.value.length>0) {
                            setNameerr(false);
                        }
                    }} />
                    {name.length==0?<Typography variant="div" component="div" pb={3} sx={{ color: '#fffc61' }}>{nameerr}</Typography>:''}
                </Typography>

                <Typography variant="div" component="div" pb={3} sx={{ fontSize: 16 }} >Date Of Birth
                    <TextField id="standard-basic" type="date" variant="standard" sx={{ svg: { color: '#fff' }, input: { fontSize: 24, color: 'white' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setDob(ev.target.value);
                        if (ev.target.value.length>0) {
                            setDoberr(false);
                        }
                    }} />
                    {dob.length==0?<Typography variant="div" component="div" pb={3} sx={{ color: '#fffc61' }}>{doberr}</Typography>:''}
                </Typography>
                
                <Typography variant="div" component="div" pb={3} >
                    {/* <TextField id="standard-basic" type="number" maxLength={10} inputProps={{ maxLength: 10 }} label="10 Digits Mobile Number" variant="standard" sx={{ input: { size: 50, color: 'white' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setPhone(ev.target.value);
                        if (phone.length==10) {
                            setPhoneerr(false);
                        }
                    }} /> */}
                    <TextField
                        id="standard-basic"
                        type="tel"
                        error={phoneerr}
                        value={phone}
                        label="Enter 10 Digit Mobile Number"
                        InputLabelProps={{
                            style: { fontSize: 22, color: '#fff' },
                        }}                        
                        variant="standard"
                        sx={{ input: { fontSize: 24, color: 'white' } }}
                        fullWidth
                        onChange={(e) => {
                            setPhone(e.target.value);
                            if (e.target.value.length > 10 || e.target.value.length < 10) {
                                setPhoneerr(true);
                            } else {
                                setPhoneerr(false);
                            }
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <Typography variant="div" component="div" sx={{  fontSize: 24, color: 'white' }}>+91</Typography>
                            </InputAdornment>,
                        }}
                    />
                    {phone.length<10 || phone.length>10?<Typography variant="div" component="div" pb={3} sx={{ color: '#fffc61' }}>{phoneerr}</Typography>:''}
                </Typography>

                <Typography variant="div" component="div" pb={3} sx={{ fontSize: 16 }} >Email ID
                    <TextField id="standard-basic" label="" variant="standard" sx={{ input: { fontSize: 24, color: 'white' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setEmail(ev.target.value);
                        if (validator.isEmail(email)) {
                            setEmailerr("");
                        }
                    }} />
                    {!validator.isEmail(email)?<Typography variant="div" component="div" pb={3} sx={{ color: '#fffc61' }}>{emailerr}</Typography>:''}
                </Typography>

                <Typography variant="div" component="div" pb={3} sx={{ fontSize: 16 }} >City
                    <TextField id="standard-basic" label="" variant="standard" sx={{ input: { fontSize: 24, color: 'white' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setCity(ev.target.value);
                        if (ev.target.value.length>0) {
                            setCityerr("");
                        }
                    }} />
                    {city.length==0?<Typography variant="div" component="div" pb={3} sx={{ color: '#fffc61' }}>{cityerr}</Typography>:''}
                </Typography>

                <Typography variant="h6" component="h6" pb={1} sx={{ display: 'none' }}>Educational Qualification</Typography>
                <Typography variant="div" component="div" pb={0} sx={{ display: 'none' }}>M.A. in Hindi/English with Hindi and English as an Honours Paper/Elective Paper/Medium of studies at Degree(B.A.) Level — *</Typography>
                <FormControl sx={{ display: 'none' }}>            
                    <RadioGroup
                        // aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="q1"
                        name="radio-buttons-group"
                        color="white"
                    >
                            <Radio value="1" label="Completed" sx={{color:'white'}} variant="outlined" onChange={(ev)=>{
                                setQualification(ev.target.value);
                                setIsquali(true);
                                setMapassingyrlabel("Month & Year of Passing");
                                setIsmapassing(false);
                            }} />
                            <Radio value="2" label="Persuring" sx={{color:'white'}} variant="outlined" onChange={(ev)=>{
                                setQualification(ev.target.value);
                                setIsquali(true);
                                setMapassingyrlabel("Month & Year of Completion");
                                setIsmapassing(false);
                            }} />
                            <Radio value="3" label="Others" sx={{color:'white'}} variant="outlined" onChange={(ev)=>{
                                setQualification(ev.target.value);
                                setIsquali(true);
                                setMapassingyrlabel("");
                                setIsmapassing(true);
                                setMapassingyr("");
                            }} />
                        
                    </RadioGroup>
                </FormControl>

                <Typography variant="div" component="div" pb={3} sx={{ display: 'none' }}>
                    <TextField id="standard-basic" label="mm/yyyy [e.g. 10/2017]" variant="standard" sx={{ input: { size: 50, color: 'white' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setMapassingyr(ev.target.value);
                    }} disabled={ismapassing} />
                    {isquali==false?<Typography variant="div" component="div" pb={3} sx={{ color: '#fffc61' }}>{qualificationerr}</Typography>:''}
                </Typography>

                <Typography variant="h6" component="h6" pt={3} pb={1} sx={{ display: 'none' }}>PG Diploma</Typography>
                <Typography variant="div" component="div" pb={0} sx={{ display: 'none' }}>Post Graduation Diploma in Translation (P.G.D.T.) — *</Typography>
                <FormControl sx={{ display: 'none' }}>            
                    <RadioGroup
                        // aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="q1"
                        name="radio-buttons-group2"
                    >
                            <Radio value="1" label="Completed" sx={{color:'white'}} variant="outlined" onChange={(ev)=>{
                                setExtra(ev.target.value);
                                setIsextra(true);
                                setExtrapassingyrlabel("Month & Year of Passing");
                                setIsextrapassing(false);
                            }} />
                            <Radio value="2" label="Persuring" sx={{color:'white'}} variant="outlined" onChange={(ev)=>{
                                setExtra(ev.target.value);
                                setIsextra(true);
                                setExtrapassingyrlabel("Month & Year of Completion");
                                setIsextrapassing(false);
                            }} />
                            <Radio value="3" label="Others" sx={{color:'white'}} variant="outlined" onChange={(ev)=>{
                                setExtra(ev.target.value);
                                setIsextra(true);
                                setExtrapassingyrlabel("");
                                setIsextrapassing(true);
                                setExtrapassingyr("");
                            }} />
                        
                    </RadioGroup>
                </FormControl>
                

                <Typography variant="div" component="div" pb={3} sx={{ display: 'none' }}>
                    <TextField id="standard-basic" label="mm/yyyy [e.g. 10/2017]" variant="standard" sx={{ input: { size: 50, color: 'white' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setExtrapassingyr(ev.target.value);
                    }} disabled={isextrapassing} />
                    {isextra==false?<Typography variant="div" component="div" pb={3} sx={{ color: '#fffc61' }}>{extraerr}</Typography>:''}
                </Typography>



                <Typography variant="div" component="div" pb={3} className = "whitetextfield" sx={{ fontSize: 16, color: 'white' }}>New Password
                    <TextField id="password" label="" type="password" sx={{ input: { fontSize: 24, color: 'white' } }} variant="standard" fullWidth onChange={(ev)=>{
                        setPassword(ev.target.value);
                        if (ev.target.value.length>=6 && password2.length>=6) {
                            setPassworderr("");
                        }
                    }} />
                </Typography>

                <Typography variant="div" component="div" pb={3} className = "whitetextfield" sx={{ fontSize: 16, color: 'white' }}>Retype New Password
                    <TextField id="password" label="" type="password" sx={{ input: { fontSize: 24, color: 'white' } }} variant="standard" fullWidth onChange={(ev)=>{
                        setPassword2(ev.target.value);
                        if (ev.target.value.length>=6 && password.length>=6) {
                            setPassworderr("");
                        }
                    }} />
                    <Typography variant="div" component="div" pb={3} sx={{ color: '#fffc61' }}>{passworderr}</Typography>
                </Typography>

                <Typography variant="div" component="div" pb={1} >Upload Profile Image</Typography>
                <Typography variant="div" component="div" pb={3} >
                    <TextField id="standard-basic" type="file" label="" variant="standard" sx={{ input: { size: 50, color: 'white' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setImg(ev.target.files[0]);
                    }} />
                </Typography>
                
                <Button variant="contained" fullWidth className='btn-green' onClick={formsubmit}>Register</Button> 
            


                <Typography variant="div" component="div" py={3} textAlign="center" >
                    <Link to="/login" underline="none" className='whitexText'>
                    Existing Login
                    </Link>
                </Typography>

            </Grid>
            {/* <Grid sm={12} display="flex" justifyContent="center" alignItems="center"></Grid>  */}

            



                {/* <Grid item sm={6} xs={6}  p={1}>
                    <Button variant="contained" fullWidth className='hbtn btn-blue'>            
                    <Grid><img src="../../images/live-exam.png" alt="" /></Grid> 
                    <Grid>Live Exam</Grid>                
                    </Button>     
                </Grid>

                <Grid item sm={6} xs={6}  p={1}>
                    <Button variant="contained" fullWidth className='hbtn btn-yellow'>            
                    <Grid><img src="../../images/practice-exam.png" alt="" /></Grid> 
                    <Grid>Practice Exam</Grid>                
                    </Button>     
                </Grid> */}

            


                

                {/* <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid>
                <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid>

                <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid> */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{backgroundColor: 'transparent',opacity: 1}}
                >
                    <Box sx={styles}>
                                        
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <img src="../images/submit_loader.gif" style={{display:'block', marginLeft: 'auto', marginRight: 'auto'}} />
                    </Typography>
                         
                    </Box>
                </Modal>

                <Modal
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{backgroundColor: 'transparent',opacity: 1}}
                >
                    <Box sx={styles}>
                                        
                    <Typography id="modal-modal-description" align='center' sx={{ fontSize: 20, mt: 2, color: 'green' }}>
                        Registration Successful!
                    </Typography>
                    <Typography id="modal-modal-description" align='center' sx={{ fontSize: 18, mt: 1, color: 'blue' }}>
                        Please Click on Login Button!
                    </Typography>
                        
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={()=>{
                            handleClose2();
                            let path = `/login`; 
                            navigate(path);
                        }}>Login</Button>  
                    </Grid> 

                    </Box>
                </Modal>


            </Grid>   
        </Container>
 
        </>
    );
}

export default RegisterContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;