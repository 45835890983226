import React, {useState, useEffect} from 'react';
import axios from 'axios';
// import validator from 'validator';
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router-dom";

import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import TextField from '@mui/material/TextField';


function ForgotPasswordContent() {

    let navigate = useNavigate();

    // if (localStorage.getItem("tokenID")!=null) {
    //     window.location.href="/dashboard";
    // }

    // INPUT FIELD
    // let [loginid,setLoginid]=useState("");
    let [phone,setPhone]=useState("");
    let [dob,setDob]=useState("");
    // INPUT FIELD

    let [isbtn,setIsbtn]=useState(true);

    // VALIDATION
    // let errloginid="Valid Email ID Required";
    let errphone="Registered Phone Number Required";
    let errdob="Date of Birth Required";

    // let [iserrloginid,setIsrrloginid]=useState(false);
    let [iserrphone,setIsrrphone]=useState(false);
    let [iserrdob,setIsrrdob]=useState(false);
    // VALIDATION

    // MSG
    let [alert,setAlert]=useState(false);
    let [msg,setMsg]=useState("");
    let [msgalert,setMsgalert]=useState("");
    let [msgcolor, setMsgcolor]=useState("");
    // MSG

    async function studforgotPassword() {
        var flag=1;
        
        if (phone.length<10) {
        flag=0;
        setIsrrphone(true);
        }
        if (dob.length==0) {
        flag=0;
        setIsrrdob(true);
        }
        if (flag==1) {
            
            console.log(dob);

            var fd=new FormData();
            fd.append("phone",phone);
            fd.append("dob",dob);

            const response = await axios.post('https://safalata.net/safalatalara/api/studforgotpassword', fd);
            const data = response.data;

            setAlert(true);
            setMsg(data.msg);
            setMsgalert(data.alert);
            setMsgcolor(data.alertcolor);

            if (data.msgcode==1) {

                setIsbtn(true);
                setTimeout(()=>{
                    localStorage.setItem('phone',phone);
                    let path = `/updatepassword`; 
                    navigate(path);
                }, 2000);

            }
        
        }
        
    }

    return (

        <>

        <Container className='terms_bg'>

            <Grid  textAlign="center">
                <img src="../../images/logo.svg" alt="" width={250} />      
            </Grid>

            <Grid container pb={2} px={3} >
            
            <Grid item xs={12}  p={0} color="white">
                <Typography variant="p" component="p">Proceed with your</Typography>
                <Typography variant="h4" component="h4" pb={5} fontWeight="medium">Forgot Password</Typography>

                {alert?<Typography variant="h6" component="h6" pb={5} align="center" fontWeight="medium" color={msgcolor}>{msg}</Typography>:''}
                
                <Typography variant="div" component="div" pb={3} >Register Phone Number
                    <TextField id="standard-basic" label="" variant="standard" sx={{ input: { size: 50, color: 'white' } }} fullWidth className = "textPrimary whitetextfield" onChange={(ev)=>{
                        setPhone(ev.target.value);
                        if (ev.target.value.length==10) {
                        setIsrrphone(false);
                        }
                        setAlert(false);
                    }} />
                    {iserrphone?<Typography variant="div" component="div" pb={3} sx={{ color: 'error.main' }}>{errphone}</Typography>:''}
                </Typography>

                <Typography variant="div" component="div" pb={3}>Select Date Of Birth
                    <TextField id="date" label="" type="date" sx={{ input: { size: 50, color: 'white' } }} variant="standard" fullWidth onChange={(ev)=>{
                        setDob(ev.target.value);
                        if (ev.target.value.length>=1) {
                            setIsrrdob(false);
                        }
                        setAlert(false);
                    }} />
                    {iserrdob?<Typography variant="div" component="div" pb={3} sx={{ color: 'error.main' }}>{errdob}</Typography>:''}
                </Typography>
                
                <Button variant="contained" fullWidth className='btn-green' onClick={studforgotPassword}>Forgot Password</Button> 

                <Typography variant="div" component="div" py={3} textAlign="center" >
                    <Link to="/login" underline="none" className='whitexText'>
                    Existing Login?
                    </Link>
                </Typography>

            </Grid>
            {/* <Grid sm={12} display="flex" justifyContent="center" alignItems="center"></Grid>  */}

            



                {/* <Grid item sm={6} xs={6}  p={1}>
                    <Button variant="contained" fullWidth className='hbtn btn-blue'>            
                    <Grid><img src="../../images/live-exam.png" alt="" /></Grid> 
                    <Grid>Live Exam</Grid>                
                    </Button>     
                </Grid>

                <Grid item sm={6} xs={6}  p={1}>
                    <Button variant="contained" fullWidth className='hbtn btn-yellow'>            
                    <Grid><img src="../../images/practice-exam.png" alt="" /></Grid> 
                    <Grid>Practice Exam</Grid>                
                    </Button>     
                </Grid> */}

            


                

                {/* <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid>
                <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid>

                <Grid item xs={4}  p={2}>
                    <Button variant="contained" fullWidth>Contained</Button>     
                </Grid> */}

            </Grid>   
        </Container>
 
        </>
    );
}

export default ForgotPasswordContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;