
import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function Variants() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />
      <Skeleton variant="text" width={210} sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.2rem'}} />

    </Stack>
  );
}