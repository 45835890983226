import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import dateFormat from 'dateformat';

import axios from 'axios';

import {useNavigate} from 'react-router-dom';

import { useSelector,useDispatch } from 'react-redux';
// import { getrefreshToken } from "../IncSlice/RefreshTokenSlice";

function ThankYouContent() {
    let navigate = useNavigate();

    // const dispatch = useDispatch();
    // const data=useSelector((state) => {
    //     return state.userstk;
    // });

    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    // async function getReduxToken() {
    //     dispatch(getrefreshToken());
    //     setName(data.userstk.name);
    //     setEnrollid(data.userstk.enroll_id);
    //     setStatus(data.userstk.status);

    //     // console.log("status============>",data.userstk.status);
    // }


    async function getUserDetails() {
        var tokenval=localStorage.getItem("tokenID");
        if (tokenval!=null) {
            var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
            headers: {
                "Content-Type":"application/json",
                "Authorization":"Bearer "+tokenval
            }
            });
            var data=await resp.json();
            
            setName(data.name);
            setEnrollid(data.enroll_id);
            setStatus(data.status);

            
            // setPic(data.pic);
            // localStorage.setItem("pic",data.pic);
        }
    }

    useEffect(()=>{
      
    //   getReduxToken();
    //   getBatchname();
    //   getToken(); 

        getUserDetails();

    },[])

     return(

        <>

        <Container>
            <Grid container >
                    <Grid item xs={12}  p={1}>
                    <Typography variant="h6" component="h6" fontSize={24} pb={1.5} fontWeight="bold" color="red"> 
                        {name}
                    </Typography>
                    <Typography variant="h6" component="h6" fontSize={24} pb={1}> 
                        Enrollment ID: <span>{enrollid}</span>
                    </Typography>
                    {status==1?
                    <Typography variant="h6" component="h6" fontSize={24} fontWeight="bold" color="#00630f" pb={1}> 
                        Student Status: ACTIVE
                    </Typography> 
                    :
                    <Typography variant="h6" component="h6" fontSize={24} fontWeight="bold" color="#eb0520" pb={1}> 
                        Student Status: INACTIVE
                    </Typography>
                    }

                    <Typography variant="h6" component="h6" align="center" color={"#02008a"} fontSize={26} pt={1.5} pb={1.5} fontWeight={700}> 
                    PAYMENT SUCCESSFUL
                    </Typography>

                    <Grid item xs={12} p={1}>
                        <Button fullWidth variant='contained' className='btn-green' onClick={async()=>{
                            let path = `/dashboard`;
                            navigate(path);
                        }}>BACK TO MAIN</Button>
                    </Grid>

                </Grid>

            </Grid>   
        </Container>

        </>
    );
}

export default ThankYouContent;






