import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';
import {useNavigate} from 'react-router-dom';

import dateFormat from 'dateformat';

import axios from 'axios';

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { useParams } from 'react-router-dom';

import PackageLoader from './PackageLoader';

import Stack from '@mui/material/Stack';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function PackagesContent() {
    
    let navigate = useNavigate();
    const params=useParams();

    const dispatch = useDispatch();
    const data=useSelector((state) => {
        return state.users;
    });

    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    async function getReduxToken() {
        dispatch(getToken());
        setName(data.users.name);
        setEnrollid(data.users.enroll_id);
        setStatus(data.users.status);
        // console.log("profile pic ------->", data.users.name);
    }

    function getchecklogin() {
        if (localStorage.getItem("tokenID")==null) {
            let path = `/login`; 
            navigate(path);
        }
    }

    //UPCOMING PRACTICE EXAM LIST
    let [loader,setLoader]=useState(false);
    let [parentpackagename,setParentpackagename]=useState("");
    let [examsubpackages,setExamsubpackages]=useState([]);
    let [subpackagecount,setSubpackagecount]=useState("");
    let [pkgvaliditycount,setPkgvaliditycount]=useState("");
    let [tempvaliditycount,setTempvaliditycount]=useState("");

    let [expirydays,setExpirydays]=useState("");

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    let [showmsg,setShowmsg]=useState("");
    let [showmsg2,setShowmsg2]=useState("");
    let [showmsg3,setShowmsg3]=useState("");

    async function getExamSubPackages() {
      setLoader(true);
      var fd=new FormData();
      fd.append("packageid",params.packageid);
      fd.append("enrollid",localStorage.getItem("enrollid"));
      var resp=await axios.post("https://safalata.net/safalatalara/api/getexamsubpackages",fd);
      var data=resp.data;
      setParentpackagename(data.package_name);
      setExamsubpackages(data.obj);
      setSubpackagecount(data.count);
      setPkgvaliditycount(data.isValidity);
      setTempvaliditycount(data.tempvaliditycount);
      setExpirydays(data.expiry_days);
      setShowmsg(data.msg);
      setShowmsg2(data.msg2);
      setShowmsg3(data.msg3);
    //   console.log('current date ======>',data.curdate,' | package id =====>',params.packageid,' | enroll id =====>',localStorage.getItem("enrollid"))
    //   console.log('isValidity==>',data.isValidity);
      setLoader(false);
    }

    // let [orderstatus, setOrderstatus]=useState("");
    // async function getstudlogincheck() {
    //     var fd=new FormData();
    //     fd.append("enrollid",localStorage.getItem("enrollid"));
    //     var resp=await axios.post("https://safalata.net/safalatalara/api/studlogincheck",fd);
    //     var data=resp.data;
    //     setOrderstatus(data);
    // }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let [oneqst,setOneqst]=useState("");

    let [inactivemsg,setInactivemsg]=useState("your plan has been expired. please renew plan and try again.");

    function modalexamdonehandleOpen() {
        setOneqst("Exam Already Done");
        handleOpen();
    }

    function modalexamsoonhandleOpen() {
        setOneqst("Exam Soon");
        handleOpen();
    }

    function paymentbtn() {
        let path = `/packageplan`; 
        // let path = `/dashboard`;
        navigate(path);
    }

    useEffect(()=>{
        getReduxToken();

        getchecklogin();

        // getstudlogincheck();

        getExamSubPackages();

    }, []);

    return(
        <>

            <Container>
                <Grid container >
                    <Grid item xs={12}  p={1}>
                        <Typography variant="h6" component="h6" pt={1.5} pb={0} fontWeight="normal"> 
                            {parentpackagename}
                        </Typography>
                        <Typography variant="h6" component="h6" pb={1.5} fontWeight="bold" color="red"> 
                            {expirydays!=-1000? "(Validity left "+expirydays+" days)" : "(Purchase this plan)"}
                        </Typography>
                        
                        {loader==false?
                        <Grid>
                        {/* <Card> */}
                        {/* <CardContent> */}
                            {examsubpackages.map((e)=>
                            <Typography key={e.package_id}>
                                <Box sx={{borderRadius: 2, background: '#fff', lineHeight: 2, pl:0,pr:2, pt: 1, pb: 1, }} component="div" mb={1} color="maroon" onClick={()=>{
                                    // if (tempvaliditycount!=0) {
                                    // if (pkgvaliditycount!=0) {
                                    if (expirydays>=0) {
                                        // alert(expirydays);
                                        let path = `/exampracticesetlist/`+e.package_id+`/`+params.packageid; 
                                        navigate(path);
                                    } else {
                                        // alert(expirydays);
                                        setOpen(true);
                                    }
                                    
                                }}>
                                    <Stack direction="row" alignItems="center" gap={0}>
                                    <ArrowRightIcon /><Typography variant="body1" sx={{fontSize:  '1.2rem'}}> {e.package_name} ({e.count_exam_allot} Sets) </Typography>
                                    </Stack>
                       
                                {/* <Button size="small" variant='contained' onClick={()=>{
                                    let path = `/exampracticesetlist/`+e.package_id; 
                                    navigate(path);
                                }}>Exam List</Button>  */}
                    
                                </Box>
                                <Grid py={0.2}>
                                {/* <Divider  /> */}
                                </Grid>
                            </Typography>
                            )}
                            
                            
                        {/* </CardContent>
                        
                        </Card> */}
                        </Grid>
                        :
                        <Card>
                        <CardContent>
                            <PackageLoader />
                        </CardContent>
                        </Card>
                        }
                    


                    </Grid>




                    

                    {/* <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>
                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>

                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid> */}

                </Grid>   

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={styles}>
                        {/* <Typography className="text-red" spacing={2} textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '16px' }}>
                            {inactivemsg}
                        </Typography> */}

                        <Typography className="text-deepblue" spacing={2} textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '1.1rem', fontWeight: 700 }}>
                            {showmsg}
                        </Typography>
                        <Typography className="text-deepblue" textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '1.1rem', fontWeight: 700 }}>
                            {showmsg2}
                        </Typography>

                        <Typography className="text-deepblue" textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '1.1rem', fontWeight: 700 }}>
                            {showmsg3}
                        </Typography>
                        
                        <Typography className="text-maroon" textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '16px' }}>
                            <Button variant="contained" sx={{ mt: 2, mb: 2, fontSize: '1.3rem' }} color='success' onClick={paymentbtn}>Pay</Button>  
                        </Typography>
                        {/* <Grid Container mt={2} textAlign="center" className='btnRow'>
                            <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                        </Grid>      */}
                    </Box>
                </Modal>


            </Container>

        </>
    );
}

export default PackagesContent;
