import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getrefreshToken = createAsyncThunk(
    "getrefreshToken",
    async (args, { rejectWithValue }) => {
        try {

            if(localStorage.getItem('phoneval')!=null) {
                var fd=new FormData();
                fd.append("phone",localStorage.getItem('phoneval'));
                fd.append("password",localStorage.getItem('pasval'));

                const response = await axios.post('https://safalata.net/safalatalara/api/studlogin_mobapp', fd);
                const authdata = response.data;

                localStorage.setItem('tokenID',authdata.stoken);

                console.log(authdata.stoken);

                var tokenval=localStorage.getItem("tokenID");
                if (tokenval!=null) {
                    var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
                    headers: {
                        "Content-Type":"application/json",
                        "Authorization":"Bearer "+tokenval
                    }
                    });
                    var data=await resp.json();

                    //var profilepic=data.pic;

                    //return profilepic;
                    
                    return data;

                    
                    // setPic(data.pic);
                    // localStorage.setItem("pic",data.pic);
                }
                //  else {
                //     let path = `/`; 
                //     navigate(path);
                // }
            }
            
        } catch (err) {
            return rejectWithValue("Opps found an error", err.response.data);
            // let path = `/`; 
            // navigate(path);
        }
    }
);


export const RefreshTokenSlice = createSlice({
  name: "getrefreshToken",
  initialState: {
      userstk: [],
      loading: false,
      error: null,
      // searchData: [],
  },
  extraReducers: {
      
      //get single user
      [getrefreshToken.pending]: (state) => {
        state.loading = true;
      },
      [getrefreshToken.fulfilled]: (state, action) => {
        state.loading = false;
        state.userstk = action.payload;
        // console.log("fulfilled action --->", action.payload)
      },
      [getrefreshToken.rejected]: (state) => {
        state.loading = false;
      },
      
  }
});
export default RefreshTokenSlice.reducer;