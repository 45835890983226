import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function ExamListLoader() {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" width={210} height={15} sx={{ fontSize: '1rem', maginBottom: '20px' }} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '40px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} sx={{ fontSize: '1rem', maginBottom: '20px' }} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '40px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
      <Skeleton variant="text" width={210} height={15} />
      <Skeleton variant="text" width={50} sx={{ maginBottom: '0px' }} />
      <Skeleton variant="rectangular" sx={{ fontSize: '0.1rem'}} />
    </Stack>
  );
}