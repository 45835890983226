import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

// import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {useNavigate} from "react-router-dom";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDropzone } from "react-dropzone";

function FileUploadContent() {

    //CROP IMAGE CODE-1
    // const [image, setImage] = useState(null);
    // const [crop, setCrop] = useState({ aspect: 16 / 9 });
    // const [completedCrop, setCompletedCrop] = useState(null);

    // const onDrop = (acceptedFiles) => {
    //     const file = acceptedFiles[0];
    //     const reader = new FileReader();

    //     reader.onload = () => {
    //     setImage(reader.result);
    //     };

    //     reader.readAsDataURL(file);
    // };

    // const { getRootProps, getInputProps } = useDropzone({
    //     onDrop,
    //     accept: "image/*",
    // });

    // const makeClientCrop = async (crop) => {
    //     if (image && crop.width && crop.height) {
    //     const croppedImage = await getCroppedImage(image, crop, "newFile.jpeg");
    //     console.log("Cropped image:", croppedImage);
    //     }
    // };

    // const getCroppedImage = (image, crop, fileName) => {
    //     const canvas = document.createElement("canvas");
    //     const scaleX = image.naturalWidth / image.width;
    //     const scaleY = image.naturalHeight / image.height;
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;
    //     const ctx = canvas.getContext("2d");

    //     ctx.drawImage(
    //     image,
    //     crop.x * scaleX,
    //     crop.y * scaleY,
    //     crop.width * scaleX,
    //     crop.height * scaleY,
    //     0,
    //     0,
    //     crop.width,
    //     crop.height
    //     );

    //     return new Promise((resolve, reject) => {
    //     canvas.toBlob((blob) => {
    //         if (!blob) {
    //         console.error("Canvas is empty");
    //         return;
    //         }
    //         blob.name = fileName;
    //         resolve(blob);
    //     }, "image/jpeg");
    //     });
    // };



    //CROP IMAGE CODE-2
    // const [src, setSrc] = useState(null);
    // const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });

    // const onImageLoaded = (image) => {
    //     setSrc(image);
    // };

    // const onCropComplete = (crop) => {
    //     makeClientCrop(crop);
    // };

    // const makeClientCrop = async (crop) => {
    //     if (src && crop.width && crop.height) {
    //     const croppedImageUrl = await getCroppedImg(src, crop);
    //     // Handle the cropped image URL
    //     }
    // };

    // const getCroppedImg = (image, crop) => {
    //     const canvas = document.createElement('canvas');
    //     const scaleX = image.naturalWidth / image.width;
    //     const scaleY = image.naturalHeight / image.height;
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;
    //     const ctx = canvas.getContext('2d');

    //     ctx.drawImage(
    //     image,
    //     crop.x * scaleX,
    //     crop.y * scaleY,
    //     crop.width * scaleX,
    //     crop.height * scaleY,
    //     0,
    //     0,
    //     crop.width,
    //     crop.height
    //     );

    //     return new Promise((resolve, reject) => {
    //     canvas.toBlob((blob) => {
    //         if (!blob) {
    //         console.error('Canvas is empty');
    //         return;
    //         }
    //         blob.name = 'cropped.jpg';
    //         resolve(window.URL.createObjectURL(blob));
    //     }, 'image/jpeg');
    //     });
    // };
    //CROP IMAGE CODE-2

    let navigate = useNavigate();

     function getNostorage() {
        if (localStorage.getItem("livesetid")!=null) {
          localStorage.removeItem("livesetid");
        }
     }
  
     let [msg,setMsg]=useState("");
     let [msgcode,setMsgcode]=useState("");
     let [msgcolor,setMsgcolor]=useState("");
  
     let [pic,setPic]=useState(null);
     let [newpic,setNewpic]=useState(null);
  
     let [isbtn,setIsbtn]=useState(true);
     
     async function getToken() {
        var tokenval=localStorage.getItem("tokenID");
        if (tokenval!=null) {
           var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
              headers: {
                 "Content-Type":"application/json",
                 "Authorization":"Bearer "+tokenval
              }
           });
           var data=await resp.json();
           setPic(data.pic);
           localStorage.setItem("pic",data.pic);
        } else {
                let path = `/`; 
                navigate(path);
           }
  
     }
  
     
     
     async function picupdate() {
        
        // if (flag==1) {
           alert(newpic.name)
           var fd=new FormData();
  
           fd.append("pic",newpic);
           fd.append("enrollid",localStorage.getItem("enrollid"));
           var resp=await axios.post("https://safalata.net/safalatalara/api/picupdate",fd);
           var data=resp.data;
           setMsg(data.msg);
           setMsgcode(data.msgcode);
           setMsgcolor(data.msgcolor);
  
           if (data.msgcode==1) {
               setIsbtn(true);
  
               window.scrollTo({
                   top: 0,
                   behavior: "smooth"
               });
               getToken();
           }
        // }
     }
  
     useEffect(()=>{
        getToken();
        getNostorage();
     },[])

    return(
        <>

        <Container>
            <Grid container py={3} >
                <Typography variant="h6" component="h6" pb={1} fontWeight="normal">Profile Update Picture</Typography>
                <img src={"https://safalata.net/safalatalara/uploadpic/"+pic} alt="" className="profile_img rounded border border-success" style={{width: '200px'}} />
            </Grid>


            {/* CROPING IMAGE CODE */}
            {/* <div>
                <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop an image here, or click to select an image</p>
                </div>
                {image && (
                    <ReactCrop
                    src={image}
                    crop={crop}
                    onImageLoaded={(image) => setCompletedCrop({ width: image.width, height: image.height })}
                    onComplete={(crop) => makeClientCrop(crop)}
                    onChange={(newCrop) => setCrop(newCrop)}
                    />
                )}
            </div> */}

            {/* <input type="file" onChange={(e) => setSrc(URL.createObjectURL(e.target.files[0]))} />
            {src && (
                <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={(newCrop) => setCrop(newCrop)}
                />
            )} */}
            {/* CROPING IMAGE CODE */}

            <Grid container pb={2} px={3} >
                <Grid item xs={12}  p={0} color="white">
                    
                    



                    <Typography variant="div" component="div" pb={3}>
                    <input 
                        type="file" 
                        className="form-control-file" 
                        id="exampleFormControlFile1" 
                        onChange={(ev)=>{
                            setNewpic(ev.target.files[0]);
                            if (ev.target.files[0].name!=null) {
                            setIsbtn(false);
                            } else {
                            setIsbtn(true);
                            }
                        }} 
                        accept=".jpg,.jpeg,.png"/>
                    </Typography>

                    {/* <div>
                        {src && (
                            <div>
                                <ReactCrop src={src} onImageLoaded={setImage}
                                    crop={crop} onChange={setCrop} />
                                <br />
                                <button onClick={cropImageNow}>Crop</button>
                                <br />
                                <br />
                            </div>
                        )}
                    </div>

                    <div>
                        {output && <img src={output} />}
                    </div> */}

                    <Button variant="contained" mb={3} disabled={isbtn} fullWidth className='btn-green' onClick={picupdate}>            
                    Update Profile Image
                    </Button> 

                

                </Grid>
            </Grid>

            
        </Container>

        </>
    );
}

// const dropzoneStyles = {
//     border: "2px dashed #aaa",
//     borderRadius: "4px",
//     textAlign: "center",
//     padding: "20px",
//     cursor: "pointer",
// };

export default FileUploadContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;