import React  from 'react';

import PracticeExamMocktestContent from "../content/PracticeExamMocktestContent";

// import BackHeader from '../inc/BackHeader';
import ExamBackHeaderMock from '../inc/ExamBackHeaderMock';

import { Grid } from '@mui/material';

function PracticeExamMocktest() {
    return(
        <>
        
        <Grid className='terms_bg '>
        <Grid className='home_bg'>
        {/* <BackHeader />  */}
        <ExamBackHeaderMock/>
        </Grid>

        <Grid py={2}>
        <PracticeExamMocktestContent/>
        </Grid> 

        </Grid>
       
        </>
    );
}

export default PracticeExamMocktest;