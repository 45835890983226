
import React, { useRef, useEffect, useState } from "react";
import axios from 'axios';
import dateFormat from 'dateformat';
// import { useParams } from 'react-router-dom';
import {useNavigate} from "react-router-dom";

// import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';

function LiveScoreMocktestContent() {

    let navigate = useNavigate();


    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    //LIVE EXAM SCORE LIST
    let [livescorearr,setLivescorearr]=useState([]);
    let [livescorecount,setLivescorecount]=useState(0);

    async function livescorelist() {
      let enrollid=localStorage.getItem("enrollid_mock");
      var fd=new FormData();
      fd.append("enrollid",enrollid);
      var resp=await axios.post("https://safalata.net/safalatalara/api/livescorelist_mock",fd);
      var data=resp.data;
      console.log(data.livescore_count);
      setLivescorearr(data.livescore);
      setLivescorecount(data.livescore_count);

      // listcount=data.livescore_count;
    }


    function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
    }

    // let [noticename, setNoticename]=useState("");
    // let [noticedatetime, setNoticedatetime]=useState("");

    // async function getnoticeboard() {
    //     var resp=await axios.get("https://safalata.in/safalatalara/api/getnoticeboard");
    //     var data=resp.data;
    //     setNoticename(data.noticename);
    //     setNoticedatetime(data.noticedatetime);
    // }

    useEffect(()=>{

      livescorelist();
      getNostorage();

    //   getnoticeboard();
      
    },[])
    return(
        <>

            <Container>
                <Grid container >
                    <Grid item xs={12}  p={1}>
                      <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
                      Live Score List For Mock Test
                      </Typography>

                      <Card>
                      <CardContent className='font14'>
                          
                      {livescorecount>0?
                      livescorearr.map((e)=>
                      <Typography key={e.stud_live_set_id}>
                          <Typography variant="h6" sx={{fontSize: '16px'}} component="div" mb={1} color="maroon">
                          {e.liveset_name}
                          </Typography>
                      
                          <Grid container >
                          <Grid item xs={5}  className='fontBold'>Date</Grid>
                          <Grid item xs={5}  className='fontBold'>Total Mark</Grid>
                          <Grid item xs={2}  className='fontBold'>Scored</Grid>
                          <Grid item xs={5}  pb={2} className='fontBold'>{dateFormat(e.studliveexamscore_date, "dd/mm/yyyy")}</Grid>
                          <Grid item xs={5}  pb={2}>{e.total_mark}</Grid>
                          <Grid item xs={2}  pb={2}>{e.stud_liveset_score}</Grid>
                          </Grid>

                          <Grid container >
                          <Grid item xs={5}  className='fontBold'>Correct</Grid>
                          <Grid item xs={5}  className='fontBold'>Wrong</Grid>
                          <Grid item xs={2}  className='fontBold'>Skip</Grid>
                          <Grid item xs={5}  pb={2}>{e.studliveexamscore_correct}</Grid>
                          <Grid item xs={5}  pb={2}>{e.studliveexamscore_wrong}</Grid>
                          <Grid item xs={2}  pb={2}>{e.studliveexamscore_skip}</Grid>
                          </Grid>

                          

                          {/* <Button size="small" variant='contained' onClick={()=>{
                            var curdate=dateFormat(e.studliveexamscore_date, "yyyy-mm-dd");
                            // alert(curdate);
                            // let path = `/livescoreview/`+e.score_unique_id+"/"+e.stud_live_set_id; 
                            let path = `/livescoreview/`+curdate+"/"+e.stud_live_set_id; 
                            navigate(path);
                          }}>View</Button> */}
                          <Grid py={2}>
                          <Divider  />
                          </Grid>
                      </Typography>
                      )
                    :
                    ''
                    }



                      </CardContent>
                      
                      </Card>



                    </Grid>



                </Grid>   
            </Container>

        </>
    );
}

export default LiveScoreMocktestContent;






