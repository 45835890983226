import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';
// import { PinDropOutlined } from '@mui/icons-material';

function ProfileSettingContent() {

    if (localStorage.getItem("tokenID")==null) {
        window.location.href="/login";
     }
  
     function getNostorage() {
        if (localStorage.getItem("livesetid")!=null) {
          localStorage.removeItem("livesetid");
        }
     }
     
     let [name,setName]=useState("");
     let [nameerr,setNameerr]=useState(false);
     let [email,setEmail]=useState("");
     let [emailerr,setEmailerr]=useState(false);
     let [enroll,setEnroll]=useState("");
     let [phone,setPhone]=useState("");
     let [city,setCity]=useState("");
     let [cityerr,setCityerr]=useState(false);
     let [pin,setPin]=useState("");
     let [pinerr,setPinerr]=useState(false);
     let [address,setAddress]=useState("");
     let [addresserr,setAddresserr]=useState(false);
  
     let [batchname,setBatchname]=useState('');

     let [isupdate,setIsupdate]=useState(false);
  
     let [isbtn,setIsbtn]=useState(true);
     async function getToken() {
        var tokenval=localStorage.getItem("tokenID");
        if (tokenval!=null) {
           var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
              headers: {
                 "Content-Type":"application/json",
                 "Authorization":"Bearer "+tokenval
              }
           });
           var data=await resp.json();
           
           setName(data.name);
           setEmail(data.email);
           setEnroll(data.enroll_id);
           setPhone(data.phone);
           setCity(data.city);
           setPin(data.pin_code);
           setAddress(data.addr);
        //    setDept(data.department);
        //    setDesign(data.designation);
  
           } else {
           window.location.href="/";
           }
  
     }
  
     async function dataupdate() {
        var noerr=1;

        setIsupdate(false);
        
        if (name.length>2) {
            //noerr=1;
            setNameerr(false);            
        } else if (name.length==0) {
            noerr=0;
            setNameerr(true);
            setName("");
        }
        
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setEmailerr(false);  
            //noerr=1;
        } else {
            noerr=0;
            setEmailerr(true);  
            setEmail("");
        }
        
        if (city.length>2) {
            setCityerr(false);  
            //noerr=1;
        } else {
            noerr=0;
            setCityerr(true);  
            setCity("");
        }
        
        if (pin.length==6) {
            setPinerr(false);  
            //noerr=1;
        } else {
            noerr=0;
            setPinerr(true);  
            setPin("");
        }

        if (address.length>2) {
            setAddresserr(false);  
            //noerr=1;
        } else {
            noerr=0;
            setAddresserr(true);  
            setAddress("");
        }

        // alert(localStorage.getItem("enrollid"))

        if (noerr==1) {
            alert(pin);
            // alert(noerr)
            var fd=new FormData();
            // fd.append("name",name);
            fd.append("email",email);
            fd.append("city",city);
            fd.append("pin",pin);
            
            fd.append("addr",address);
            fd.append("enrollid",localStorage.getItem("enrollid"));
            var resp=await axios.post("https://safalata.net/safalatalara/api/appdataupdate",fd);
            var data=resp.data;

            setIsbtn(true);
            getToken();

            alert(data.statcode);

            setIsupdate(true);
        }
        
     }
  
     async function getBatchname() {
        var fd=new FormData();
        fd.append("batchid",localStorage.getItem("batchid"));
        var resp=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
        var data=resp.data;
        setBatchname(data);
    }
  
     useEffect(()=>{
        getBatchname();
        getToken();
        getNostorage();
     },[])
    return(
        <>

        <Container>
            <Grid container py={3} >
                <Typography variant="h6" component="h6" pb={1} fontWeight="normal"> Edit Profile</Typography>
                {isupdate?
                <Grid container pb={0} px={1}  sx={{textAlign: 'center'}}>
                    <Typography variant="h6" align="center" component="h6" pb={1} fontWeight="normal" sx={{color: 'green'}}>Updated Succeed</Typography>
                </Grid>
                :
                ''
                }
            </Grid>

            <Grid container pb={2} px={3} >
                <Grid item xs={12}  p={0} color="white">
                    {/* <InputLabel id="demo-simple-select-standard-label" className='large'>Name</InputLabel> */}
                    <Typography variant="div" component="div" pb={3}>
                    {nameerr?
                    <TextField id="name" label="Name" error value={name} variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setName(e.target.value);
                        if (e.target.value.length>2) {
                            setNameerr(false);
                        } else {
                            setNameerr(true);
                        }
                    }} />
                    :
                    <TextField id="name" label="Name" value={name} variant="outlined" fullWidth className = "textfield" disabled onChange={(e)=>{
                        setName(e.target.value);
                        if (e.target.value.length>2) {
                            setNameerr(false);
                        } else {
                            setNameerr(true);
                        }
                    }} />
                    }
                    </Typography>

                    
                    <Typography variant="div" component="div" pb={3}>
                    <TextField id="enroll" label="Enroll ID" value={enroll} variant="outlined" fullWidth className = "textfield" disabled />
                    </Typography>

                    <Typography variant="div" component="div" pb={3}>
                    <TextField id="batchname" label="Batch Name" value={batchname} variant="outlined" fullWidth className = "textfield" disabled />
                    </Typography>

                    
                    <Typography variant="div" component="div" pb={3}>
                    {emailerr?
                    <TextField id="email" label="Email" error value={email} variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setEmail(e.target.value);
                        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
                            setEmailerr(false);
                        } else {
                            setEmailerr(true);
                        }
                    }} />
                    :
                    <TextField id="email" label="Email" value={email} variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setEmail(e.target.value);
                        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
                            setEmailerr(false);
                        } else {
                            setEmailerr(true);
                        }
                    }} />
                    }
                    </Typography>

                    <Typography variant="div" component="div" pb={3}>
                    <TextField id="phone" label="Phone" value={phone} variant="outlined" fullWidth className = "textfield" disabled />
                    </Typography>
                    
                    <Typography variant="div" component="div" pb={3}>
                    {cityerr?
                    <TextField id="city" label="City" error value={city} variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setCity(e.target.value);
                        if (city.length>2) {
                            setCityerr(false);
                        } else {
                            setCityerr(true);
                        }
                    }} />
                    :
                    <TextField id="city" label="City" value={city} variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setCity(e.target.value);
                        if (city.length>2) {
                            setCityerr(false);
                        } else {
                            setCityerr(true);
                        }
                    }} />
                    }
                    </Typography>

                    <Typography variant="div" component="div" pb={3}>
                    {pinerr?
                    <TextField id="pincode" label="Pincode" error value={pin} variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setPin(e.target.value);
                        if (e.target.value.length==6) {
                            setPinerr(false);
                        } else {
                            setPinerr(true);
                        }
                    }} />
                    :
                    <TextField id="pincode" label="Pincode" value={pin} variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setPin(e.target.value);
                        if (e.target.value.length==6) {
                            setPinerr(false);
                        } else {
                            setPinerr(true);
                        }
                    }} />
                    }
                    </Typography>

                    {/* <Typography variant="div" component="div" pb={3}>
                    <TextField id="address" label="Address" value={addr} variant="outlined" fullWidth className = "textfield" />
                    </Typography> */}

                    <Typography variant="div" component="div" pb={3}>
                    {addresserr?
                    <TextField id="address" label="Address" error value={address} variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setAddress(e.target.value);
                        if (address.length>2) {
                            setAddresserr(false);
                        } else {
                            setAddresserr(true);
                        }
                    }} />
                    :
                    <TextField id="address" label="Address" value={address} variant="outlined" fullWidth className = "textfield" onChange={(e)=>{
                        setAddress(e.target.value);
                        if (address.length>2) {
                            setAddresserr(false);
                        } else {
                            setAddresserr(true);
                        }
                    }} />
                    }
                    </Typography>

                    <Button variant="contained" mb={3} fullWidth className='btn-green' onClick={dataupdate}>            
                    Update Profile
                    </Button> 

                    {/* <Typography variant="div" component="div" py={3} textAlign="center" >
                    <Link to="/login" underline="none" className='whitexText'>
                        Login
                    </Link>
                    </Typography> */}
                

                </Grid>
            </Grid>

            
        </Container>

        </>
    );
}

export default ProfileSettingContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;