import React, { useRef, useEffect, useState } from "react";
import axios from 'axios';
import dateFormat from 'dateformat';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';

import { Link as RouterLink } from 'react-router-dom';

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

import PackageLoader from './PackageLoader';

function PracticeScoreContent() {

    let [isbtn,setIsbtn]=useState(true);
    //localStorage.setItem("studid",1000);

    // if (localStorage.getItem("studtoken")==null) {
    //   window.location.href="/";
    // }

    // let [studname,setStudname]=useState('');
    // let [enrollid,setEnrollid]=useState('');
    // let [studstatus,setStudstatus]=useState('');
    // let [batchname,setBatchname]=useState('');

    const dispatch = useDispatch();
    const data=useSelector((state) => {
        return state.users;
    });

    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    async function getReduxToken() {
        dispatch(getToken());
        setName(data.users.name);
        setEnrollid(data.users.enroll_id);
        setStatus(data.users.status);
        // console.log("profile pic ------->", data.users.name);
    }
    
    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");


   

    let [pic,setPic]=useState(null);

    // async function getToken() {

    //   setLoader(true);

    //   var tokenval=localStorage.getItem("tokenID");
    //   if (tokenval!=null) {
    //     var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
    //       headers: {
    //         "Content-Type":"application/json",
    //         "Authorization":"Bearer "+tokenval
    //       }
    //     });
    //     var data=await resp.json();
    //     setStudname(data.name);
    //     setEnrollid(data.enroll_id);
    //     setStudstatus(data.status);
    //     setPic(data.pic);

    //     if (data.name.length>1 && localStorage.getItem("enrollid")==null) {
    //       localStorage.setItem("enrollid",data.enroll_id);
    //     }

    //     var fd=new FormData();
    //     fd.append("batchid",data.batch_slno);
    //     var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
    //     var data2=resp2.data;
    //     setBatchname(data2);

    //     setLoader(false);

    //   } else {
    //     window.location.href="/";
    //   }

    // }

    // const listcount=0;

    //PRACTICE EXAM SCORE LIST
    let [practicescorearr,setPracticescorearr]=useState([]);

     //LOADER
     let [loader,setLoader]=useState(false);
     //LOADER

    async function practicescorelist() {
        setLoader(true);
        let enrollid=localStorage.getItem("enrollid");

        var fd=new FormData();
        fd.append("enrollid",enrollid);
        var resp=await axios.post("https://safalata.net/safalatalara/api/practicesetscorelist",fd);
        var data=resp.data;
      
        setPracticescorearr(data.practicescore);
        setLoader(false);
      

      // listcount=data.practicescore_count;
    }

    function getNostorage() {
      if (localStorage.getItem("practicesetid")!=null) {
        localStorage.removeItem("practicesetid");
      }
    }

    // let [noticename, setNoticename]=useState("");
    // let [noticedatetime, setNoticedatetime]=useState("");

    // async function getnoticeboard() {
    //     var resp=await axios.get("https://safalata.in/safalatalara/api/getnoticeboard");
    //     var data=resp.data;
    //     setNoticename(data.noticename);
    //     setNoticedatetime(data.noticedatetime);
    // }

    useEffect(()=>{
      // getToken();
      getReduxToken();
    //   getBatchname();
      
      practicescorelist();
      getNostorage();

    //   getnoticeboard();
      
    },[])
     return(
        <>

            <Container>
                <Grid container >
                    <Grid item xs={12}  p={1}>
                    <Typography variant="h6" component="h6" pb={1.5} fontWeight="bold" color="red"> 
                        {name}
                    </Typography>
                    {/* <Typography variant="p" component="p" pb={1}> 
                        Batch Name: <span>{batchname}</span>
                    </Typography> */}
                    <Typography variant="p" component="p" pb={1}> 
                        Enrollment ID: <span>{enrollid}</span>
                    </Typography>
                    {status==1?
                    <Typography variant="p" component="p" fontWeight="bold" color="#00630f" pb={1}> 
                        Student Status: ACTIVE
                    </Typography> 
                    :
                    <Typography variant="p" component="p" fontWeight="bold" color="#eb0520" pb={1}> 
                        Student Status: INACTIVE
                    </Typography>
                    }
                        

                        <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
                        Practice Score List
                        </Typography>

                        <Card>
                        <CardContent className='font14'>
                        
                        {!loader?
                        practicescorearr.map((e)=>
                        <Typography key={e.stud_practice_set_id}>
                            <Typography variant="h6" sx={{fontSize: '16px'}} component="div" mb={1} color="maroon">
                            {e.practiceset_name}
                            </Typography>
                        
                            <Grid container >
                            <Grid item xs={5} sx={{fontWeight: '700'}}>Date</Grid>
                            <Grid item xs={5} sx={{fontWeight: '700'}}>Total Mark</Grid>
                            <Grid item xs={2} sx={{fontWeight: '700'}}>Scored</Grid>
                            <Grid item xs={5}  pb={2} className='fontBold'>{dateFormat(e.studpracticeexamscore_date, "dd/mm/yyyy")}</Grid>
                            <Grid item xs={5}  pb={2}>{e.total_mark}</Grid>
                            <Grid item xs={2}  pb={2}>{e.stud_practiceset_score}</Grid>
                            </Grid>

                            <Grid container >
                            <Grid item xs={5} sx={{fontWeight: '700'}}>Correct</Grid>
                            <Grid item xs={5} sx={{fontWeight: '700'}}>Wrong</Grid>
                            <Grid item xs={2} sx={{fontWeight: '700'}}>Skip</Grid>
                            <Grid item xs={5}  pb={2}>{e.studpracticeexamscore_correct}</Grid>
                            <Grid item xs={5}  pb={2}>{e.studpracticeexamscore_wrong}</Grid>
                            <Grid item xs={2}  pb={2}>{e.studpracticeexamscore_skip}</Grid>
                            </Grid>

                            {e.studpracticeexamscore_duration!=null? 
                            <Grid container >
                                <Grid item xs={5} sx={{fontWeight: '700'}}>Exam Duration</Grid>
                                <Grid item xs={2}  className='fontBold'></Grid>
                                <Grid item xs={5}  className='text-right' pb={2}>{e.studpracticeexamscore_duration} minutes</Grid>
                            </Grid>
                            :
                            ''
                            } 

                            {/* <Button size="small" variant='contained' component={RouterLink} to="/practicescoreview">View</Button> */}
                            <Grid py={1}>
                            <Divider  />
                            </Grid>
                        </Typography>
                        )

                        :
                        <PackageLoader />
                        }
                        </CardContent>
                        
                        </Card>



                    </Grid>



                </Grid>   
            </Container>

        </>
    );
}

export default PracticeScoreContent;






