import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';
import {useNavigate} from 'react-router-dom';

import dateFormat from 'dateformat';

import axios from 'axios';

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { useParams } from 'react-router-dom';

import PackageLoader from './PackageLoader';

import Stack from '@mui/material/Stack';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

// import Paper from '@mui/material/Paper';
import { BottomNavigation } from '@mui/material';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import $ from 'jquery';
import useRazorpay, { RazorpayOptions } from "react-razorpay";

// import Stack from '@mui/material/Stack';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const showcheck=[];
const packagename=[];
const perpackageamount=[];
let showcount=0;
let sumamount=0.00;

let i=0;

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function PackagePlanContent() {
    
    let navigate = useNavigate();
    const params=useParams();

    const Razorpay = useRazorpay();

    const dispatch = useDispatch();
    const data=useSelector((state) => {
        return state.users;
    });

    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    async function getReduxToken() {
        dispatch(getToken());
        setName(data.users.name);
        setEnrollid(data.users.enroll_id);
        setStatus(data.users.status);
        // console.log("profile pic ------->", data.users.name);
    }

    function getchecklogin() {
        if (localStorage.getItem("tokenID")==null) {
            let path = `/login`; 
            navigate(path);
        }
    }

    //UPCOMING PRACTICE EXAM LIST
    let [loader,setLoader]=useState(false);
    let [parentpackagename,setParentpackagename]=useState("");
    let [examsubpackages,setExamsubpackages]=useState([]);
    let [subpackagecount,setSubpackagecount]=useState("");
    let [pkgvaliditycount,setPkgvaliditycount]=useState("");
    let [tempvaliditycount,setTempvaliditycount]=useState("");

    let [packageplan,setPackageplan]=useState([]);

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    let [showmsg,setShowmsg]=useState("");

    
    

    async function getPackageplan() {
      setLoader(true);
      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid"));
      var resp=await axios.post("https://safalata.net/safalatalara/api/getpackageplan",fd);
      var data=resp.data;
      setPackageplan(data.obj);
      setLoader(false);
    }

    const [openwrongcoupon, setOpenwrongcoupon] = useState(false);
    const handleOpenwrongcoupon = () => setOpenwrongcoupon(true);
    const handleClosewrongcoupon = () => setOpenwrongcoupon(false);

    const [opencoupon, setOpencoupon] = useState(false);
    const handleOpencoupon = () => setOpencoupon(true);
    const handleClosecoupon = () => setOpencoupon(false);

    const [opengateway, setOpengateway] = useState(false);
    const handleOpengateway = () => setOpengateway(true);
    const handleClosegateway = () => setOpengateway(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // const [open2, setOpen2] = useState(false);
    // const handleOpen2 = () => setOpen2(true);
    // const handleClose2 = () => setOpen2(false);

    let [oneqst,setOneqst]=useState("");

    function modalexamdonehandleOpen() {
        setOneqst("Exam Already Done");
        handleOpen();
    }

    function modalexamsoonhandleOpen() {
        setOneqst("Exam Soon");
        handleOpen();
    }

    function inactivebackbtn() {
        let path = `/dashboard`; 
        navigate(path);
    }

    let [packagelist,setPackagelist]=useState([]);
    let [packagecount,setPackagecount]=useState(0);
    let [packageamount,setPackageamount]=useState(0);
    let [couponcode,setCouponcode]=useState("");

    let [couponamount,setCouponamount]=useState(0);

    let [arraycount,setArraycount]=useState(0);
    let [apply,setApply]=useState("Apply");

    
    function updatecart() {
        setPackagelist(showcheck);
        // setArraycount(parseInt(showcheck.length)+1);
        setPackagecount(showcount);
        setPackageamount(sumamount);
        setTimeout(()=>{
            console.log(packagelist);
            console.log(showcount);
        },500)     
        
        
    }

    let [couponlist,setCouponlist]=useState([]);
    
    let [btndisabled,setBtndisabled]=useState("disabled");

    let [id,setId]=useState("");
    let [discounttype,setDiscounttype]=useState("");
    let [discount,setDiscount]=useState("");
    let [discountamount,setDiscountamount]=useState(0.00);
    let [totalpackage,setTotalpackage]=useState("");

    let [grandtotal,setGrandtotal]=useState(0.00);
    let [sumtotal,setSumtotal]=useState(0.00);

    async function getCouponlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/getcouponsetting");
        var data=resp.data;
        setCouponlist(data.obj);
    }


    let [btnpaytxt,setBtnpaytxt]=useState("");

    //PAYMENT GATEWAY RAZORPAY INTEGRATION
    // function pay_online(oid,st,name,enrollid) {
    function pay_online(oid,grandtotal,packageids,enrollid,countpackage,discounttype,discount,couponcode,discountamount,sumtotal) {
        var amt=Number(grandtotal);
        // alert(oid)
        $.ajax({
                type:'post',
                url:'https://safalata.net/safalatalara/api/gopackagepayment',
                data:"oid="+oid+"&enrollid="+enrollid+"&packageids="+packageids+"&countpackage="+countpackage+"&discounttype="+discounttype+"&discount="+discount+"&couponcode="+couponcode+"&discountamount="+discountamount+"&sumtotal="+sumtotal,
                success:function(result){
                    var options = {
                        "key": "rzp_live_QrhcwcL0TIPoRe", 
                        // "key": "rzp_test_3pFj1UUL1ja69Q",
                        "amount": amt*100, 
                        "currency": "INR",
                        "name": "Safalata - Shraddha Group of Education",
                        "description": "Exam Fees", 
                        "image": "https://www.safalata.net/safalatalara/payimage/payimg.jpg",
                        "handler": function (response){
                            $.ajax({
                                type:'post',
                                url:'https://safalata.net/safalatalara/api/gopackagepayment',
                                data:"payment_id="+response.razorpay_payment_id+"&oid="+oid+"&enrollid="+enrollid+"&amt="+amt+"&countpackage="+countpackage+"&packageids="+packageids+"&sumtotal="+sumtotal,
                                success:function(result){
                                // alert("payment_id="+response.razorpay_payment_id+"&oid="+oid+"&enrollid="+enrollid+"&amt="+amt);
                                // setBtnpaytxt("Please wait .. it will be redirected to thank you page. "+response.razorpay_payment_id)
                                // window.location.href="/";
                                // let path = `/order/`+oid;
                                // if (response==1) {
                                    // setBtnpaytxt("PAY NOW");

                                    let path = `/thankyou`;
                                    // let path = `/packageplan`;
                                    navigate(path);
                                // } else {
                                //   // setBtnpaytxt("PAY NOW");
                                // }
                                
                                }
                            });
                        }
                    };
                    var rzp1 = new Razorpay(options);
                    rzp1.open();
                }
        });  
    }

    useEffect(()=>{
        getReduxToken();

        getchecklogin();

        // getstudlogincheck();

        getPackageplan();
        getCouponlist();

        setPackageplan([]);

    }, []);

    return(
        <>

            <Container>
                <Grid container >
                    <Grid item xs={12} p={1}>
                        <Typography sx={{fontWeight: 700, fontSize: '1.6rem'}} align="center" variant="h6" component="h6" pt={0} pb={0} fontWeight="normal"> 
                            Select your plan
                        </Typography>
                        <Typography align="center" variant="h6" component="h6" pt={0} pb={1} fontWeight="normal"> 
                           (Each plan has 30 days validity)
                        </Typography>
                        {/* <Typography variant="h6" component="h6" pb={1.5} fontWeight="bold" color="red"> 
                            Sub Packages List
                        </Typography> */}
                        
                        {loader==false?
                        <Box sx={{ flexGrow: 1 }}>

                            {packageplan.map((e)=>
                            

                            // {e.packagevaliditycount==0? "ddd" : ''}
                            
                            <Grid container spacing={0} key={e.package_id} mb={1} sx={{borderRadius: 2, background: '#e3f2fd', lineHeight: 2, pl:0.5,pr:0, pt: 0.5, pb: 0 }}>
                               
                                <Grid item xs={8} spacing={1} sx={{background: '#e3f2fd', color: '#000000'}}>
                                    <Item sx={{fontSize:  '1.1rem', textAlign: 'left', fontWeight: 900, border: 0, pt: 1.3}}>
                                        <FormControlLabel control={<Checkbox {...label} color="info" disabled={e.expiry_days<8? '' : 'true'} defaultChecked={e.expiry_days<8? '' : 'true'} />} onChange={(c)=>{
                                        // disabled={e.packagevaliditycount>0? 'true' : ''}
                                        // defaultChecked={e.packagevaliditycount>0? 'true' : ''}
                                        setId("");
                                        setDiscounttype("");
                                        setDiscount("");
                                        setCouponcode("");
                                        setDiscountamount(0.00);

                                        if (c.target.checked==true) {                                            

                                            showcheck.push(e.package_id);

                                            showcount=parseInt(showcount)+parseInt(1);
                                            sumamount=parseFloat(sumamount)+parseFloat(e.package_amount);
                                            packagename.push(e.package_name);
                                            perpackageamount.push(e.package_amount);
                                            setGrandtotal(sumamount);
                                            setSumtotal(sumamount);

                                        } else {
                                            const idx = showcheck.indexOf(e.package_id);
                                            showcheck.splice(idx, idx !== -1 ? 1 : 0);

                                            showcount=parseInt(showcount)-1;
                                            sumamount=parseFloat(sumamount)-parseFloat(e.package_amount);
                                            const idx2 = packagename.indexOf(e.package_name);
                                            packagename.splice(idx2, idx2 !== -1 ? 1 : 0);

                                            const idx3 = perpackageamount.indexOf(e.package_amount);
                                            perpackageamount.splice(idx3, idx3 !== -1 ? 1 : 0);
                                            setGrandtotal(sumamount);
                                            setSumtotal(sumamount.toFixed(2));

                                        }

                                        updatecart();
                                        
                                    }}/>{e.package_name}<br />TOTAL SETS : {e.exam_count}<br />TOTAL MCQs : {e.total_mcq}</Item>
                                </Grid>
                                <Grid xs={4} spacing={0}>
                                    {e.package_name.length<20?
                                    <Item sx={{fontSize:  '1.1rem', textAlign: 'right', fontWeight: 900, border: 0, pt: 1.8, pb: 8.9}}>Rs. {e.package_amount}</Item>
                                    :
                                    <Item sx={{fontSize:  '1.1rem', textAlign: 'right', fontWeight: 900, border: 0, pt: 1.8, pb: 12}}>Rs. {e.package_amount}</Item>
                                    }
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <Item sx={{fontSize:  '1.2rem', textAlign: 'left', fontWeight: 700}}>SET : {e.exam_count}</Item>
                                </Grid>
                                <Grid item xs={6}>
                                    <Item sx={{fontSize:  '1.2rem', textAlign: 'right', fontWeight: 700}}>MCQs : {e.total_mcq}</Item>
                                </Grid> */}

                                {/* {e.packagevaliditycount>0? */}
                                {e.packagevaliditycount>0 && e.expiry_days>=0 && e.expiry_days<8?
                                <Grid item xs={12}>
                                    <Item sx={{fontSize:  '1.1rem', textAlign: 'left', fontWeight: 700, color: 'red'}}>Next Due Date on {e.expiry_date}</Item>
                                </Grid>
                                :
                                e.packagevaliditycount>0 && e.expiry_days>=8?
                                <Grid item xs={12}>
                                    <Item sx={{fontSize:  '1.1rem', textAlign: 'left', fontWeight: 700, color: 'red'}}>Next Due Date on {e.expiry_date}</Item>
                                </Grid>
                                :
                                e.packagevaliditycount==0 && e.plantakencount>0?
                                <Grid item xs={12}>
                                    <Item sx={{fontSize:  '1.1rem', textAlign: 'left', fontWeight: 700, color: 'red'}}>Plan Expired on {e.expiry_date}</Item>
                                </Grid>
                                :
                                ''
                                }

                                {/* <Grid item xs={4}>
                                    
                                </Grid> */}
                                {/* <Grid item xs={12}>
                                    <Item sx={{textAlign: 'left'}}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox {...label} color="secondary" />} label={<Typography sx={{ color: "#651fff", fontWeight: 500}}>{e.checkboxlabel}</Typography>} />
                                    </FormGroup>

                                    </Item>
                                </Grid> */}
                            </Grid>
                            )}
                            
                            
                        </Box>
                        :
                        <Card>
                        <CardContent>
                            <PackageLoader />
                        </CardContent>
                        </Card>
                        }
                        
                        <Box sx={{ flexGrow: 1 }} mb={6}>
                        {packagelist.map((e,i)=>
                        <Grid container spacing={0} key={e.package_id} mb={0.1} sx={{borderRadius: 2, background: '#e3f2fd', lineHeight: 1, pl:0.5,pr:0, pt: 0, pb: 0 }}>
                            <Grid item xs={7}>
                                <Item sx={{fontSize:  '1.2rem', textAlign: 'left'}}>
                                {/* {showcheck[i]} {packagename[i]} */}
                                {packagename[i].length>20? packagename[i].substr(0,17)+'..' : packagename[i]}                                
                                </Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item sx={{fontSize:  '1.2rem', textAlign: 'right'}}>
                                Rs. {perpackageamount[i]}
                                </Item>
                            </Grid>
                            <Typography sx={{ display: 'none' }}>{++i}</Typography>  
                        </Grid>  
                                            
                        )}
                        <Grid container spacing={0} mb={0.1} mt={1} sx={{borderRadius: 2, background: '#e3f2fd', lineHeight: 1, pl:0.5,pr:0, pt: 0, pb: 0 }}>
                            <Grid item xs={7}>
                                <Item sx={{fontSize:  '1.3rem', textAlign: 'center', fontWeight: 700}}>
                                TOTAL AMOUNT
                                </Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item sx={{fontSize:  '1.3rem', textAlign: 'right', fontWeight: 700}}>
                                Rs. {sumtotal}
                                {/* Rs. {grandtotal.toFixed(2)} */}
                                </Item>
                            </Grid>
                        </Grid> 
                        {/* <Grid container spacing={0} mb={0.1} sx={{borderRadius: 2, background: '#e3f2fd', lineHeight: 1, pl:0.5,pr:0, pt: 0, pb: 0 }}>
                            <Grid item xs={7}>
                                <Item sx={{fontSize:  '1.3rem', textAlign: 'center', fontWeight: 700}}>
                                <Stack direction="row" alignItems="center" gap={0}><NoteAddIcon sx={{pr: 2, color: 'blue'}} onClick={()=>{
                                    setOpencoupon(true)
                                }} /> Apply Coupon</Stack>
                                </Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item sx={{fontSize:  '1.3rem', textAlign: 'right', fontWeight: 700}}>
                                ~Rs. {discountamount}
                                </Item>
                            </Grid>
                        </Grid>  */}
                        {/* <Grid container spacing={0} mb={0.1} sx={{borderRadius: 2, background: '#e3f2fd', lineHeight: 1, pl:0.5,pr:0, pt: 0, pb: 0 }}>
                            <Grid item xs={7}>
                                <Item sx={{fontSize:  '1.3rem', textAlign: 'center', fontWeight: 700}}>
                                GRAND AMOUNT
                                </Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item sx={{fontSize:  '1.3rem', textAlign: 'right', fontWeight: 700}}>
                                Rs. {grandtotal.toFixed(2)}
                                </Item>
                            </Grid>
                        </Grid>  */}

                        </Box>
                        
                        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigation
                            showLabels
                            // value={value}
                            // onChange={(event, newValue) => {
                            //     // setValue(newValue);
                            // }}
                            onClick={()=>{
                                var enroll_id=localStorage.getItem("enrollid");
                                if (showcount==0) {
                                    console.log(oid+','+grandtotal+','+packageids+','+enroll_id+' , '+showcount+' , '+discounttype+' , '+discount+','+couponcode+','+discountamount);
                                    setOpengateway(true);
                                } else {
                                    //PAYMENT GATEWAY CODE
                                    var packageids=showcheck;
                                    var oid = Math.random().toString(16).slice(2);
                                    var countpackage=showcount;                                    
                                    console.log(oid+','+grandtotal+','+packageids+','+enroll_id+' , '+showcount+' , '+discounttype+' , '+discount+','+couponcode+','+discountamount);
                                    pay_online(oid,grandtotal,packageids,enroll_id,countpackage,discounttype,discount,couponcode,discountamount,sumtotal);
                                }
                            }}
                            sx={{borderRadius: 2, background: '#027fd4'}}>
                            <Typography align="center" variant="h6" component="h6" sx={{fontSize: '1.5rem', pt: 1.4, color: '#fff'}}>
                                PAY NOW {grandtotal!=0 || grandtotal!=0.00? 'Rs.'+grandtotal : ''}
                            </Typography>
                        </BottomNavigation>
                        </Paper>

                    </Grid>



                </Grid>   

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={styles}>
                    
                    <Typography className="text-red" textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '16px' }}>
                        {/* {inactivemsg} */}
                        {showmsg}
                        <Button variant="contained" sx={{ mt: 2, mb: 2 }} color='success' onClick={inactivebackbtn}>Go To Dashboard & Pay Now</Button>  
                    </Typography>
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>


                <Modal
                    open={opencoupon}
                    onClose={handleClosecoupon}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={styles}>
                    
                    {couponlist.map((e)=>
                    <Typography key={e.id}>
                        <Typography className="text-red" textAlign="center" sx={{ mt: 1, mb: 0.5, alignContent: 'center', justifyContent: 'center', fontSize: '1.2rem' }}>
                            Coupon Code : {e.coupon_code}
                        </Typography>
                        <Typography className="text-red" textAlign="center" sx={{ mt: 0, mb: 0.5, alignContent: 'center', justifyContent: 'center', fontSize: '1.2rem' }}>
                            ~Discount : {e.discount_type=='PERCENT'? e.discount+"%" : "Rs."+e.discount}
                        </Typography>
                        <Typography className="text-red" textAlign="center" sx={{ mt: 0, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '1rem' }}>
                            {e.description}
                            {showcount==0?
                            <Button variant="contained" sx={{ mt: 2, mb: 2 }} color='success' disabled >Apply</Button>  
                            :
                            <Button variant="contained" sx={{ mt: 2, mb: 2 }} color='success' onClick={()=>{
                                // setApply("Coupon Applied");
                                if (e.total_package<=showcount) {
                                    setId(e.id);
                                    setDiscounttype(e.discount_type);
                                    setDiscount(e.discount);
                                    setCouponcode(e.coupon_code);
                                    var totalamount=sumamount.toFixed(2);
                                    var sumtotal=0.00;
                                    var discounttotal=0.00;
                                    if (e.discount_type=='PERCENT') {
                                        discounttotal=((totalamount*e.discount)/100).toFixed(2);
                                        sumtotal=(totalamount-discounttotal)
                                    } else {
                                        discounttotal=(e.discount).toFixed(2);
                                        sumtotal=(totalamount-discounttotal)
                                    }
                                    setDiscountamount(discounttotal);
                                    setGrandtotal(sumtotal);
                                    // setTotalpackage(e.total_package);
                                    // alert(e.total_package+'===>'+showcount)
                                } else {
                                    // alert(e.total_package+'===>'+showcount)
                                    setTotalpackage(e.total_package);
                                    handleOpenwrongcoupon(true);
                                }
                                
                                // setBtndisabled
                            }} {...id==e.id? {btndisabled} : "" } >{id==e.id && e.total_package<=showcount? "Coupon Applied" : "Apply" }</Button>
                            }
                        </Typography>
                        <Grid>
                        <Divider  />
                        </Grid>
                    </Typography>
                    )}

                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='error' onClick={handleClosecoupon}>Close</Button>  
                    </Grid>    

                    </Box>
                </Modal>

                <Modal
                    open={openwrongcoupon}
                    onClose={handleClosewrongcoupon}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={styles}>
                    
                    <Typography className="text-red" textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '16px' }}>
                        {/* {inactivemsg} */}
                        You Can't Apply This Coupon. Minimum Required {totalpackage} No of Packages or More..
                    </Typography>
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={handleClosewrongcoupon}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>

                <Modal
                    open={opengateway}
                    onClose={handleClosegateway}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={styles}>
                    <Typography className="text-red" textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '16px' }}>
                        Before 'PAY NOW', first choose and select your package..
                    </Typography>
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={handleClosegateway}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>


            </Container>

        </>
    );
}

export default PackagePlanContent;
