import React, {useRef, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


// import { createTheme } from '@mui/material/styles';


// import { useSelector,useDispatch } from 'react-redux';

// import { getToken } from "../IncSlice/BackHeaderSlice";

// import { getNoticeBoard } from '../IncSlice/NoticeboardSlice';

// import { getSuccessStory } from '../IncSlice/SuccessStorySlice';

// import axios from 'axios';

// import dateFormat from 'dateformat';


import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

// FOR RAZORPAY PAYMENT GATE INTEGRATION
import $ from 'jquery';
import useRazorpay, { RazorpayOptions } from "react-razorpay";

// import  useRazorpay  from 'react-razorpay';
// FOR RAZORPAY PAYMENT GATE INTEGRATION

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



function DashboardMocktestContent(props) {

    let navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    async function getstudlogincheck() {
        // if (!localStorage.getItem("enrollid_mock")) {
        //     if (localStorage.getItem("enrollid")) {
        //         localStorage.removeItem("enrollid");
        //     }
        //     let path = `/login`;
        //     navigate(path);
        // }
    }

    useEffect(()=>{
        getstudlogincheck();
    }, []);

    return(
        <>
            <Container>
                <Grid container pt={8} >
                    <Grid item sm={6} xs={6} pt={8} p={1} onClick={()=>{
           
                        let path = `/upcomingliveexam_mock`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-blue'>            
                        <Grid><img src="../../images/live-exam.png" alt="" /></Grid> 
                        <Grid>Live Exam</Grid>                
                        </Button>     
                    </Grid>

                    <Grid item sm={6} xs={6} pt={8} p={1} onClick={()=>{
                        let path = `/upcomingpracticeexam_mock`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-yellow'>            
                        <Grid><img src="../../images/practice-exam.png" alt="" /></Grid> 
                        <Grid>Practice Exam</Grid>                
                        </Button>     
                    </Grid>

                    <Grid item sm={6} xs={6}  p={1} pt={4} onClick={()=>{
                        let path = `/livescore_mock`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-green-dashboard'>            
                        <Grid><img src="../../images/live-score.png" alt="" /></Grid> 
                        <Grid>Live Score</Grid>                
                        </Button>     
                    </Grid>

                    <Grid item sm={6} xs={6} p={1} pt={4} onClick={()=>{
                        let path = `/practicescore_mock`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-pink'>            
                        <Grid><img src="../../images/practice-score.png" alt="" /></Grid> 
                        <Grid>Practice Score</Grid>                
                        </Button>     
                    </Grid>


                    <Grid container xs={12} spacing={0} mt={2} ml={1} mr={0} p={3} fullWidth className='hbtn btn-bluenoticeboard-mock' >
                        
                        
                    <Typography color={'#fff'} fontWeight={'bold'}> 
                        Mock tests for guest users only. Please register and pay Rs. 300/- and get Mock tests regularly for 30 days.
                    </Typography>

                    </Grid>
                    <Grid container xs={12} spacing={0} mt={4} ml={1} mr={0} p={2} fullWidth className='hbtn btn-lightblueregistration-mock' >
                     

                    <Typography variant="div" component="div" py={1} textAlign="center" >
                        <Link to="" underline="none" className='bluexText'>
                        For New Registration
                        </Link> <Button variant="contained" className='btn-green' onClick={()=>{
                            localStorage.removeItem('enrollid_mock');
                            let path = `/register`;
                            navigate(path);
                        }}>Click Here</Button> 
                    </Typography>

                 
                    </Grid>

                    

                    {/* <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        let path = `/monthwisescore`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-redlight'>            
                        <Grid><img src="../../images/monthwise-score.png" alt="" /></Grid> 
                        <Grid>Monthly score</Grid>                
                        </Button>
                    </Grid>

                    <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        let path = `/paymenthistory`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-bluelight'>            
                        <Grid><img src="../../images/pyment-history.png" alt="" /></Grid> 
                        <Grid>Payment History</Grid>                
                        </Button>     
                    </Grid> */}

                    {/* <Grid item sm={6} xs={6} p={1} onClick={()=>{
                        let path = `/successstory`;
                        navigate(path);
                        }}>
                        <Button variant="contained" fullWidth className='hbtn btn-redlight-story'>            
                        <Grid><img src="../../images/success-story.png" alt="" /></Grid> 
                        <Grid>Success Story</Grid>                
                        </Button>     
                    </Grid> */}

                    {/* <Grid item sm={6} xs={6} p={1} onClick={modalaihandleOpen}>
              
                        <Button variant="contained" fullWidth className='hbtn btn-bluelight-ai'>            
                        <Grid><img src="../../images/ai_chatgpt.png" alt="" /></Grid> 
                        <Grid>AI ChatGPT</Grid>                
                        </Button>     
                    </Grid> */}

                    {/* {datetimedays<8?
                        studstatus==2?
                        <Grid container xs={12} spacing={0} mt={2} ml={1} mr={0} p={3} fullWidth className='hbtn btn-bluenoticeboard' >
                            <Typography color={'#581d1d'} fontWeight={'bold'}> 
                                Please click here for payment &#8377; 300/- for 30 days
                            </Typography> 

                            <Typography color={'#581d1d'} fontWeight={'bold'} variant="p" component="p" pt={1} pb={1}> 
                            <Button variant="contained" fullWidth className='btn-green' onClick={()=>{
                                var oid = Math.random().toString(16).slice(2);
                                console.log(oid+','+fees+','+name+','+enrollid);
                                pay_online(oid,fees,name,enrollid);
                                }} >PAY NOW</Button> 
                            </Typography>
                        </Grid>

                        : studstatus==1?
                        
                        <Grid container xs={12} spacing={0} mt={2} ml={1} mr={0} p={3} fullWidth className='hbtn btn-bluenoticeboard' >
                            <Typography color={'#581d1d'} fontWeight={'bold'}> 
                            {startTimer(dateFormat(endpaymentdate, "mmmm dd, yyyy HH:MM:00"))}
                            NEXT DATE OF PAYMENT ( {datetimeinterval} )
                            </Typography> 
                            
                            <Typography color={'#581d1d'} fontWeight={'bold'} variant="p" component="p" pt={1} pb={1}> 
                            <Button variant="contained" fullWidth className='btn-green' onClick={()=>{
                                var oid = Math.random().toString(16).slice(2);
                                console.log(oid+','+fees+','+name+','+enrollid);
                                pay_online(oid,fees,name,enrollid);
                            }} disabled={btnpay}>PAY NOW</Button>
                            </Typography>
                        </Grid>

                        : 
                        ''
                    :
                    ''
                    } */}




                




                    {/* <Grid container xs={12} spacing={0} mt={2} ml={1} mr={0} p={3} fullWidth className='hbtn btn-bluenoticeboard' >
                        
                        
                            <Typography color={'#581d1d'} fontWeight={'bold'}> 
                                {noticename}  
                            </Typography> 

                            <Typography color={'#581d1d'} fontWeight={'bold'} variant="p" component="p" pt={1} pb={1}> 
                                Notice Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}
                            </Typography> 
                 

                    </Grid> */}

                </Grid>   
                
                {/* <Razorpay
                    options={razorpayOptions}
                    onSuccess={handlePaymentSuccess}
                    onError={handlePaymentError}
                /> */}

                

                
            </Container>

        </>
    );
}

export default DashboardMocktestContent;

