import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import dateFormat from 'dateformat';

import axios from 'axios';

import {useNavigate} from 'react-router-dom';

function StartLiveExamContent() {
    let navigate = useNavigate();

    let [isbtn,setIsbtn]=useState(true);

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');

    let [pic,setPic]=useState(null);

    if (localStorage.getItem("livesetid")==null) {
        window.location.href="/dashboard";
    }

    async function getBatchname() {
      console.log(localStorage.getItem("pic"));
      setLoader(true);
      var fd=new FormData();
      fd.append("batchid",localStorage.getItem("batchid"));
      var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
      var data2=resp2.data;
      setBatchname(data2);
      setPic(localStorage.getItem("pic"));
      setStudname(localStorage.getItem("name"));
      setEnrollid(localStorage.getItem("enrollid"));
      setStudstatus(localStorage.getItem("status"));
      setLoader(false);
  }

    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER
    
    //LIVE EXAM DETAILS
    let [examname,setExamname]=useState("");
    let [examtotalqst,setExamtotalqst]=useState("");
    let [examstdate,setExamstdate]=useState("");
    let [exameddate,setExameddate]=useState("");

    async function liveexamdetails() {
      let livesetid=localStorage.getItem("livesetid");
      var fd=new FormData();
      fd.append("livesetid",livesetid);
      var resp=await axios.post("https://safalata.net/safalatalara/api/liveexamdetails",fd);
      var data=resp.data;
      setExamname(data.livesetname);
      setExamstdate(data.stdate);
      setExameddate(data.eddate);
      setExamtotalqst(data.qcount);

      localStorage.setItem("examname",data.livesetname);
      localStorage.setItem("exameddate",data.eddate);
      localStorage.setItem("qcount",data.qcount);

      localStorage.setItem("shuffle",data.shuffle);
    }

    useEffect(()=>{
        // getToken();
        getBatchname();
        liveexamdetails();
  
        // getnoticeboard();
  
      },[])
     return(
        <>

<Container>
    <Grid container >
        <Grid item xs={12}  p={1}>
            <Typography variant="h6" component="h6" pb={1.5} fontWeight="normal"> 
                {studname}
            </Typography>
            <Typography variant="p" component="p" pb={1}> 
                Batch Name: <span>{batchname}</span>
            </Typography>
            <Typography variant="p" component="p" pb={1}> 
                Enrollment ID: <span>{enrollid}</span>
            </Typography>
            <Typography variant="p" component="p" pb={1}> 
                Student Status: {localStorage.getItem("status")==1?<span>Active</span>:<span>InActive</span>}
            </Typography> 
            

            <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
            Start Live Exam
            </Typography>

            <Card className='borderRadius10'>
            <CardContent>
                <Typography variant="h6" component="div" mb={2} textAlign="center" className='text-green lineheight'>
                Go To Test Your <br />Live Online Exam
                </Typography>
                <Typography mb={1}>
                This is Multiple Choice Live Set To Test Your Knowledge
                </Typography>
                <Typography variant="body2"  mb={1} >
                Live Exam Set Name : <span className="startspantxt">{examname}</span>
                </Typography>

                <Typography variant="body2"  mb={1} >
                Number of Questions : {examtotalqst}
                </Typography>
                <Typography variant="body2"  mb={1} >
                Exam Date & Time : {dateFormat(examstdate, "dd-mm-yyyy ( HH:MM TT")} - {dateFormat(exameddate, "HH:MM TT )")}
                </Typography>
                <Typography variant="body2"  mb={1} >
                Question Type : Multiple Choice
                </Typography>

            </CardContent>
            
            </Card >

             

        </Grid>

        <Grid item xs={12}  p={1}>
            <Button fullWidth variant='contained' className='btn-green' onClick={async ()=>{
                var fd=new FormData();
                fd.append("livesetid",localStorage.getItem("livesetid"));
                fd.append("enrollid",localStorage.getItem("enrollid"));
                var resp=await axios.post("https://safalata.net/safalatalara/api/addstartliveexamschedule",fd);
                var data=resp.data;
                localStorage.setItem("exameddate",data.eddate);
                let path = `/liveexam/1`;
                navigate(path);
                }}>Start Exam</Button>
        </Grid>


        

        {/* <Grid item xs={4}  p={2}>
            <Button variant="contained" fullWidth>Contained</Button>     
        </Grid>
        <Grid item xs={4}  p={2}>
            <Button variant="contained" fullWidth>Contained</Button>     
        </Grid>

        <Grid item xs={4}  p={2}>
            <Button variant="contained" fullWidth>Contained</Button>     
        </Grid> */}

    </Grid>   
</Container>

        </>
    );
}

export default StartLiveExamContent;






