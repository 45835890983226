import React, { useRef, useEffect, useState } from "react";
import axios from 'axios';
import dateFormat from 'dateformat';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';


function PracticeScoreMocktestContent() {

    

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");
    

    //PRACTICE EXAM SCORE LIST
    let [practicescorearr,setPracticescorearr]=useState([]);
    let [practicescorecount,setPracticescorecount]=useState(0);

    async function practicescorelist() {
      let enrollid=localStorage.getItem("enrollid_mock");
      var fd=new FormData();
      fd.append("enrollid",enrollid);
      var resp=await axios.post("https://safalata.net/safalatalara/api/practicescorelist_mock",fd);
      var data=resp.data;
      setPracticescorearr(data.practicescore);
      setPracticescorecount(data.practicescore_count);

      console.log(data.practicescore_count);

      // listcount=data.practicescore_count;
    }

    function getNostorage() {
      if (localStorage.getItem("practicesetid")!=null) {
        localStorage.removeItem("practicesetid");
      }
    }


    useEffect(()=>{
      practicescorelist();
      getNostorage();

    //   getnoticeboard();
      
    },[])
     return(
        <>

            <Container>
                <Grid container >
                    <Grid item xs={12}  p={1}>
                    
                        <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
                        Practice Score List For Mock Test
                        </Typography>

                        <Card>
                        <CardContent className='font14'>
                            
                        {practicescorecount>0?
                        practicescorearr.map((e)=>
                        <Typography key={e.stud_practice_set_id}>
                            <Typography variant="h6" sx={{fontSize: '16px'}} component="div" mb={1} color="maroon">
                            {e.practiceset_name}
                            </Typography>
                        
                            <Grid container >
                            <Grid item xs={5}  className='fontBold'>Date</Grid>
                            <Grid item xs={5}  className='fontBold'>Total Mark</Grid>
                            <Grid item xs={2}  className='fontBold'>Scored</Grid>
                            <Grid item xs={5}  pb={2} className='fontBold'>{dateFormat(e.studpracticeexamscore_date, "dd/mm/yyyy")}</Grid>
                            <Grid item xs={5}  pb={2}>{e.total_mark}%</Grid>
                            <Grid item xs={2}  pb={2}>{e.stud_practiceset_score}</Grid>
                            </Grid>

                            <Grid container >
                            <Grid item xs={5}  className='fontBold'>Correct</Grid>
                            <Grid item xs={5}  className='fontBold'>Wrong</Grid>
                            <Grid item xs={2}  className='fontBold'>Skip</Grid>
                            <Grid item xs={5}  pb={2}>{e.studpracticeexamscore_correct}</Grid>
                            <Grid item xs={5}  pb={2}>{e.studpracticeexamscore_wrong}</Grid>
                            <Grid item xs={2}  pb={2}>{e.studpracticeexamscore_skip}</Grid>
                            </Grid>

                            {/* <Button size="small" variant='contained' component={RouterLink} to="/practicescoreview">View</Button> */}
                            <Grid py={1}>
                            <Divider  />
                            </Grid>
                        </Typography>
                        )
                        :
                        ''
                        }


                        </CardContent>
                        
                        </Card>



                    </Grid>



                </Grid>   
            </Container>

        </>
    );
}

export default PracticeScoreMocktestContent;






