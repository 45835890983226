
import React, { useRef, useEffect, useState } from "react";
import axios from 'axios';
import dateFormat from 'dateformat';
// import { useParams } from 'react-router-dom';
import {useNavigate} from "react-router-dom";

// import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';

// import { Link as RouterLink } from 'react-router-dom';

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

function LiveScoreContent() {

    let navigate = useNavigate();


    // if (localStorage.getItem("studtoken")==null) {
    //   window.location.href="/";
    // }

    // let [studname,setStudname]=useState('');
    // let [enrollid,setEnrollid]=useState('');
    // let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');

    const dispatch = useDispatch();
    const data=useSelector((state) => {
        return state.users;
    });

    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    async function getReduxToken() {
        dispatch(getToken());
        setName(data.users.name);
        setEnrollid(data.users.enroll_id);
        setStatus(data.users.status);
        // console.log("profile pic ------->", data.users.name);
    }

    async function getBatchname() {
      // console.log(localStorage.getItem("pic"));
      // setLoader(true);
      var fd=new FormData();
      fd.append("batchid",localStorage.getItem("batchid"));
      var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
      var data2=resp2.data;
      setBatchname(data2);
      // setLoader(false);
    }

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");


    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER

    let [pic,setPic]=useState(null);

    // async function getToken() {

    //   setLoader(true);

    //   var tokenval=localStorage.getItem("tokenID");
    //   if (tokenval!=null) {
    //     var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
    //       headers: {
    //         "Content-Type":"application/json",
    //         "Authorization":"Bearer "+tokenval
    //       }
    //     });
    //     var data=await resp.json();
    //     setStudname(data.name);
    //     setEnrollid(data.enroll_id);
    //     setStudstatus(data.status);
    //     setPic(data.pic);

    //     if (data.name.length>1 && localStorage.getItem("enrollid")==null) {
    //       localStorage.setItem("enrollid",data.enroll_id);
    //     }

    //     var fd=new FormData();
    //     fd.append("batchid",data.batch_slno);
    //     var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
    //     var data2=resp2.data;
    //     setBatchname(data2);

    //     setLoader(false);

    //   } else {
    //     window.location.href="/";
    //   }

    // }

    // const listcount=0;

    //LIVE EXAM SCORE LIST
    let [livescorearr,setLivescorearr]=useState([]);

    async function livescorelist() {
      let enrollid=localStorage.getItem("enrollid");
      var fd=new FormData();
      fd.append("enrollid",enrollid);
      var resp=await axios.post("https://safalata.net/safalatalara/api/livescorelist",fd);
      var data=resp.data;
      setLivescorearr(data.livescore);

      // listcount=data.livescore_count;
    }


    function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
    }

    // let [noticename, setNoticename]=useState("");
    // let [noticedatetime, setNoticedatetime]=useState("");

    // async function getnoticeboard() {
    //     var resp=await axios.get("https://safalata.in/safalatalara/api/getnoticeboard");
    //     var data=resp.data;
    //     setNoticename(data.noticename);
    //     setNoticedatetime(data.noticedatetime);
    // }

    useEffect(()=>{

      getReduxToken();
      getBatchname();
      // getToken();
      livescorelist();
      getNostorage();

    //   getnoticeboard();
      
    },[])
    return(
        <>

            <Container>
                <Grid container >
                    <Grid item xs={12}  p={1}>
                    <Typography variant="h6" component="h6" pb={1.5} fontWeight="bold" color="red"> 
                        {name}
                    </Typography>
                    <Typography variant="p" component="p" pb={1}> 
                        Batch Name: <span>{batchname}</span>
                    </Typography>
                    <Typography variant="p" component="p" pb={1}> 
                        Enrollment ID: <span>{enrollid}</span>
                    </Typography>
                    {status==1?
                    <Typography variant="p" component="p" fontWeight="bold" color="#00630f" pb={1}> 
                        Student Status: ACTIVE
                    </Typography> 
                    :
                    <Typography variant="p" component="p" fontWeight="bold" color="#eb0520" pb={1}> 
                        Student Status: INACTIVE
                    </Typography>
                    }
                        

                      <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
                      Live Score List
                      </Typography>

                      <Card>
                      <CardContent className='font14'>
                          
                      {livescorearr.map((e)=>
                      <Typography key={e.stud_live_set_id}>
                          <Typography variant="h6" sx={{fontSize: '16px'}} component="div" mb={1} color="maroon">
                          {e.liveset_name}
                          </Typography>
                      
                          <Grid container >
                          <Grid item xs={5}  className='fontBold'>Date</Grid>
                          <Grid item xs={5}  className='fontBold'>Total Mark</Grid>
                          <Grid item xs={2}  className='fontBold'>Scored</Grid>
                          <Grid item xs={5}  pb={2} className='fontBold'>{dateFormat(e.studliveexamscore_date, "dd/mm/yyyy")}</Grid>
                          <Grid item xs={5}  pb={2}>{e.total_mark}%</Grid>
                          <Grid item xs={2}  pb={2}>{e.stud_liveset_score}</Grid>
                          </Grid>

                          <Grid container >
                          <Grid item xs={5}  className='fontBold'>Correct</Grid>
                          <Grid item xs={5}  className='fontBold'>Wrong</Grid>
                          <Grid item xs={2}  className='fontBold'>Skip</Grid>
                          <Grid item xs={5}  pb={2}>{e.studliveexamscore_correct}</Grid>
                          <Grid item xs={5}  pb={2}>{e.studliveexamscore_wrong}</Grid>
                          <Grid item xs={2}  pb={2}>{e.studliveexamscore_skip}</Grid>
                          </Grid>

                          

                          <Button size="small" variant='contained' onClick={()=>{
                            var curdate=dateFormat(e.studliveexamscore_date, "yyyy-mm-dd");
                            // alert(curdate);
                            // let path = `/livescoreview/`+e.score_unique_id+"/"+e.stud_live_set_id; 
                            let path = `/livescoreview/`+curdate+"/"+e.stud_live_set_id; 
                            navigate(path);
                          }}>View</Button>
                          <Grid py={2}>
                          <Divider  />
                          </Grid>
                      </Typography>
                      )}



                            {/* <Typography variant="h6" component="div" mb={1}>
                            Hindi Paper Set 143
                            </Typography>
                        
                            <Grid container >
                            <Grid item xs={4}  className='fontBold'>Date</Grid>
                            <Grid item xs={4}  className='fontBold'>Total Mark</Grid>
                            <Grid item xs={4}  className='fontBold'>Scored</Grid>
                            <Grid item xs={4}  pb={2}>20-05-2023</Grid>
                            <Grid item xs={4}  pb={2}>100%</Grid>
                            <Grid item xs={4}  pb={2}>0.75</Grid>
                            </Grid>

                            <Grid container >
                            <Grid item xs={4}  className='fontBold'>Correct</Grid>
                            <Grid item xs={4}  className='fontBold'>Wrong</Grid>
                            <Grid item xs={4}  className='fontBold'>Skip</Grid>
                            <Grid item xs={4}  pb={2}>1</Grid>
                            <Grid item xs={4}  pb={2}>1</Grid>
                            <Grid item xs={4}  pb={2}>1</Grid>
                            </Grid>


                            <Button size="small" variant='contained'>View</Button>             */}
                            

                      </CardContent>
                      
                      </Card>



                    </Grid>




                    

                    {/* <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>
                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>

                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid> */}

                </Grid>   
            </Container>

        </>
    );
}

export default LiveScoreContent;






