import React  from 'react';


// import Header from "../inc/Header";
import BackHeaderMock from "../inc/BackHeaderMock";
import { Grid } from '@mui/material';
import LiveScoreMocktestContent from '../content/LiveScoreMocktestContent';



function LiveScoreMocktest() {
    return(
        <>
        
        <Grid className='bg-greenlight-mock'>
        <Grid className='home_bg'>
        <BackHeaderMock /> 
        </Grid>

        <Grid py={2}>
        <LiveScoreMocktestContent />
        </Grid> 
        </Grid>
        </>
    );
}

export default LiveScoreMocktest;