import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function ProfileInfoLoader() {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" width={250} height={30} sx={{ fontSize: '1rem', maginBottom: '20px' }} />
      <Skeleton variant="text" width={200} height={30} sx={{ maginBottom: '40px' }} />
      <Skeleton variant="text" width={150} height={30} sx={{ maginBottom: '40px' }} />
    </Stack>
  );
}