import React  from 'react';

import DashboardMocktestContent from "../content/DashboardMocktestContent";

// import BackHeaderMock from "../inc/BackHeaderMock";
// import HeaderMocktest from "../inc/HeaderMocktest";
import Banner from "../inc/Banner";

import { Grid } from '@mui/material';
import HeaderMocktest from '../inc/HeaderMocktest';



function DashboardMocktest() {
    return(
        <>
        <Grid className='bg-leafgreen-home-mock'>
            <Grid className='home_bg_mock'>
                {/* <Grid className='home_bg'> */}
                {/* <BackHeaderMock />
                */}
                <HeaderMocktest/>
                <DashboardMocktestContent/>
            </Grid>
        </Grid>
        </>
    );
}

export default DashboardMocktest;