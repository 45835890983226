import { configureStore } from '@reduxjs/toolkit';
import BackHeaderSlice from '../IncSlice/BackHeaderSlice';
import NoticeboardSlice from '../IncSlice/NoticeboardSlice';
import SuccessStorySlice from '../IncSlice/SuccessStorySlice';
import RefreshTokenSlice from '../IncSlice/RefreshTokenSlice';

export const store = configureStore({
  reducer: {
    users: BackHeaderSlice,
    notice: NoticeboardSlice,
    story: SuccessStorySlice,
    userstk: RefreshTokenSlice,
  },
});
