import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';
import {Link} from 'react-router-dom';
import dateFormat from 'dateformat';
import { useParams } from 'react-router-dom';
import {useNavigate} from "react-router-dom";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

//RADIO GROUP IMPORT
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';

import FormControl from '@mui/joy/FormControl';
// import FormLabel from '@mui/joy/FormLabel';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
//RADIO GROUP IMPORT

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import QALoader from "./QALoader";


const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function LiveExamContent() {
    let navigate = useNavigate();
    const params=useParams();
    const limit=params.qsl-1;
    const prev=params.qsl-1;
    const next=prev+2;

    let [issavebtn,setIssavebtn]=useState(true);
    let [totalqid, setTotalqid]=useState("");

    // let [ismodalalert,setIsmodalalert]=useState("");

    function getcheckexam() {
        if (localStorage.getItem("enrollid")==null) {
            window.location.href="/liveexamresult";
        }
    }

    let [isradio,setIsradio]=useState(false);

    const [datetimeinterval,setDateTimeInterval]=useState("");
    let interval = useRef();

    // function getchecktimer() {

        const startTimer = (endeddate) => {
            const coundownDate = new Date(endeddate).getTime();

            interval=setInterval(()=>{
                const now = new Date().getTime();
                const distance = coundownDate-now;

                // const days=Math.floor(distance / (1000*60*60*24));
                const hours=Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
                const mins=Math.floor((distance % (1000*60*60)) / (1000*60));
                const secs=Math.floor((distance % (1000*60)) / 1000);

                if (distance < 0) {
                    //stop timer code
                    clearInterval(interval);
                    setDateTimeInterval("Schedule End");
                    setIsradio(true);
                    setIsskipbtn(true);
                    // autosubmitscore();
                    // setIsbtn(false);

                    // logintokencheck();


                } else {
                    setDateTimeInterval(hours+"h "+mins+"m "+secs+"s");
                    setIsradio(false);
                    // setIsbtn(true);

                    // logintokencheck();

                }
            
            }, 1)
        };
    // }

    let [qid,setQid]=useState("");
    let [question,setQuestion]=useState("");
    let [noted,setNoted]=useState("");
    let [answerarr,setAnswerarr]=useState([]);
    let [ansslno,setAnsslno]=useState("");

    let [isalertnoted,setIsalertnoted]=useState(false);

    let [studansoption,setStudansoption]=useState("");
    let [querypagecount,setQuerypagecount]=useState(0);
    let [rowpagenoarr,setRowpagenoarr]=useState([]);
    let [querycountcheck,setQuerycountcheck]=useState("");

    let [isskipbtn,setIsskipbtn]=useState(true);
    let [isnextbtn,setIsnextbtn]=useState(true);

    let [nextbtndisplay,setNextbtndisplay]=useState("block");
    

    let [correct,setCorrect]=useState("");
    let [wrong,setWrong]=useState("");
    let [studansmark,setStudansmark]=useState("");

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    let [loader,setLoader]=useState(false);

    //year-month [e.g 2022-February] added format in studliveexamscore
    const yearmonth=dateFormat(now, "yyyy-mmmm");
    async function liveexamqa() {

          setLoader(true);
          var fd=new FormData();
          fd.append("livesetid",localStorage.getItem("livesetid"));
          fd.append("limit",limit);
          fd.append("enrollid",localStorage.getItem("enrollid"));
          fd.append("shuffle",localStorage.getItem("shuffle"));
          fd.append("curdate",curdate);
          ////////////////ADDED FOR PARTIAL SHUFFLEING
          ////////////////fd.append("qcount",localStorage.getItem('qcount'));
          
          if (localStorage.getItem("shuffle")==0) {
            var resp=await axios.post("https://safalata.net/safalatalara/api/liveexamqa0_sec",fd);
            var data=resp.data;
          } else if (localStorage.getItem("shuffle")==1) {
            var resp=await axios.post("https://safalata.net/safalatalara/api/liveexamqa1_sec",fd);
            var data=resp.data;
          } else if (localStorage.getItem("shuffle")==2) {
            var resp=await axios.post("https://safalata.net/safalatalara/api/liveexamqa2_sec",fd);
            var data=resp.data;
          }
  
          
          setQid(data.qid);
          setQuestion(data.question);
          setNoted(data.noted);
          setAnswerarr(data.ans);
          setStudansoption(data.stud_ans_option);
          
          setRowpagenoarr(data.row_page_no);
          setQuerypagecount(data.query_page_count);
  
          setQuerycountcheck(data.querycountcheck);
  
          localStorage.setItem('qid',data.qid);
          localStorage.setItem('studansoption',data.stud_ans_option);
          localStorage.setItem('querycountcheck',data.querycountcheck);
  
          if (data.querycountcheck==1) {
            setIsskipbtn(true);
            setIsnextbtn(false);
          } else if (data.querycountcheck==0) {
            setIsskipbtn(false);
            setIsnextbtn(true);
          }
  
          setLoader(false);
  
      }


      async function savestudexamsheet() {
        // logintokencheck();
        // setLoader(true);

        setIsradio(true);

        setIssavebtn(true);
        setIsskipbtn(true);
        var fd=new FormData();
        fd.append("livesetid",localStorage.getItem("livesetid"));
        fd.append("enrollid",localStorage.getItem("enrollid"));
        fd.append("qid",qid);
        fd.append("ansslno",ansslno);
        fd.append("querycountcheck",querycountcheck);
        fd.append("studansoption",studansoption);
        fd.append("pageno",params.qsl);
        var resp=await axios.post("https://safalata.net/safalatalara/api/savestudexamsheet_sec",fd);
        var data=resp.data;

        setIsnextbtn(false);
        setIsalertnoted(true);
        setIsradio(true);
        
        setCorrect(data.correct);
        setWrong(data.wrong);
        setStudansmark(data.studansmark);

        liveexamqa();


    }

    async function skipstudexamsheet() {
        // logintokencheck();
        // setLoader(true);
        setIsskipbtn(true);
        var fd=new FormData();
        fd.append("livesetid",localStorage.getItem("livesetid"));
        fd.append("enrollid",localStorage.getItem("enrollid"));
        fd.append("qid",qid);
        fd.append("querycountcheck",querycountcheck);
        fd.append("pageno",params.qsl);
        var resp=await axios.post("https://safalata.net/safalatalara/api/skipstudexamsheet_sec",fd);
        var data=resp.data;
        // setIsskipbtn(true);
        setIsnextbtn(false);
        
        liveexamqa();
        // setLoader(false);
    }



    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let [oneqst,setOneqst]=useState("");

    // let [circleloader,setCircleloader]=useState(false);

    const [loaderopen, setLoaderopen] = useState(false);
    const loaderhandleOpen = () => setLoaderopen(true);
    const loaderhandleClose = () => setLoaderopen(true);

    const loaderhandlemodalClose = () => setLoaderopen(false);


    function modalhandleOpen() {
        setOneqst("");
        handleOpen();
    }

    async function submitscore() {
        if (querypagecount>0) {
            handleClose();
            // setCircleloader(true);

            loaderhandleOpen();


            var fd=new FormData();
            fd.append("livesetid",localStorage.getItem("livesetid"));
            fd.append("enrollid",localStorage.getItem("enrollid"));
            fd.append("examtotalqst",localStorage.getItem("qcount"));
            fd.append("curdate",curdate);
            fd.append("yearmonth",yearmonth);
            var resp=await axios.post("https://safalata.net/safalatalara/api/submitscorestudliveexam_sec",fd);
            var data=resp.data;

            setTimeout(()=>{
                //Remove List of Exam localStorage
                localStorage.removeItem("livesetid");
                localStorage.removeItem("qcount");
                localStorage.removeItem("shuffle");
                localStorage.removeItem('qid');
                localStorage.removeItem('studansoption');
                localStorage.removeItem('querycountcheck');
                // setCircleloader(false);
                loaderhandlemodalClose();
                let path = `/livescore`; 
                navigate(path);

            },3000);
        } else {
            setOneqst("Please, Attend One Question Before Submit Score..");
        }

    }

    async function getCheckToken() {

        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.net/safalatalara/api/checkusertoken",fd);
        var data=resp.data;
        if (data.usertoken!=localStorage.getItem('usertoken')) {
          localStorage.clear();
          let path = `/login`; 
          navigate(path);
        }
        
    }

    useEffect(()=>{

        getcheckexam();

        getCheckToken();

        // getchecktimer();

        if (params.qsl >= localStorage.getItem('qcount')) {
          setNextbtndisplay("none");
        } else {
          setNextbtndisplay("block");
        }
        
        setIsnextbtn(true);
  
        setTotalqid(localStorage.getItem('qcount'));
  
        // securestayliveexam();
  
        setIsradio(false);

        liveexamqa();
        setIssavebtn(true);
        setIsalertnoted(false);
        setStudansmark("");
        
      },[params.qsl])
    return(
        <>
            <Container>
                <Grid container >
                    <Grid item xs={12} pt={1}>
                        {/* <Typography variant="h6" component="h6" pb={1.5} fontWeight="normal"> 
                            Testing Administrator
                        </Typography>
                        <Typography variant="p" component="p" pb={1}> 
                            Batch Name: <span>सफलता-14</span>
                        </Typography>
                        <Typography variant="p" component="p" pb={1}> 
                            Enrollment ID: <span>1000</span>
                        </Typography>
                        <Typography variant="p" component="p" pb={1}> 
                            Student Status: <span>Active</span>
                        </Typography> 
                        

                        <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
                        Live Exam
                        </Typography> */}

                

                        <Card className='borderRadius10'>
                        <CardContent>
                            <Typography variant="h6" component="div" textAlign="center" className='text-green lineheight'>
                            Live Online Exam
                            </Typography>

                            <Typography textAlign="center"   className='text-green lineheight'>
                            {localStorage.getItem("examname")}
                            </Typography>
                            {startTimer(dateFormat(localStorage.getItem("exameddate"), "mmmm dd, yyyy HH:MM:00"))}
                            <Typography mb={3} textAlign="center"  >
                                <span children className='timegreenbox'>( {datetimeinterval} )</span>
                            </Typography>




                            {!loader?
                            
                            <Grid>



                        
                            
                            <Typography mb={1} >
                            {querypagecount>0?
                                <Typography  mr={.5} variant="body">Skip -</Typography>
                             :
                                ''
                            }

                            {querypagecount>0?
                                rowpagenoarr.map((r)=>
                                    r.stud_ans_option==null?
                                        <Button variant="contained" size='small' className='btn-green-small' ml={2} onClick={()=>{
                                            let path = `../liveexam/`+r.studliveexamsheet_pageno+`#`; 
                                            navigate(path);
                                        }}>{r.studliveexamsheet_pageno}</Button>
                                    :''
                                )
                            :''
                            }
                            </Typography>
                            <Typography mb={3} >
                            {querypagecount<=localStorage.getItem('qcount') && querypagecount>0?
                            <Button variant="contained" size='small' className='btn-green-small' ml={2}><Link to={"../liveexam/"+querypagecount} style={{color: '#000794'}}>Last Attend-{querypagecount}</Link></Button>
                            :''}
                            </Typography>
                            <Typography variant="body2"  mb={.5} fontSize="medium">Question: {params.qsl} of {localStorage.getItem('qcount')}</Typography>
                            <Typography variant="body2"  mb={.5}fontSize="medium" >QID: {qid} </Typography>                
                            <Typography variant="body2"  mb={4} fontSize="medium">Question - {question}</Typography>
                            
                            <FormControl>
                                
                                <RadioGroup
                                    // aria-labelledby="demo-radio-buttons-group-label"
                                    // defaultValue="q1"
                                    name="radio-buttons-group"
                                >
                                    {answerarr.map((e)=>
                        
                                        localStorage.getItem('querycountcheck') == 1 && localStorage.getItem('studansoption').length > 0?
                                            e.answer_slno==localStorage.getItem('studansoption')?

                                            // <FormControlLabel value={e.answer_slno} control={<Radio />} label={e.answer.replace(/&apos;/g, "'")} disabled />
                                            <Radio value={e.answer_slno} label={e.answer.replace(/&apos;/g, "'")} variant="outlined" checked disabled />
                                            : 
                                            // <FormControlLabel value={e.answer_slno} control={<Radio />} label={e.answer.replace(/&apos;/g, "'")} disabled />
                                            <Radio value={e.answer_slno} label={e.answer.replace(/&apos;/g, "'")} variant="outlined" disabled />
                                        : 
                                        // <FormControlLabel value={e.answer_slno} control={<Radio />} label={e.answer.replace(/&apos;/g, "'")} disabled={isradio} 
                                        //     onChange={()=>{
                                        //         setAnsslno(e.answer_slno);
                                        //         setIssavebtn(false);
                                        //     }} />
                                        <Radio value={e.answer_slno} label={e.answer.replace(/&apos;/g, "'")} variant="outlined" disabled={isradio} onChange={()=>{
                                            setAnsslno(e.answer_slno);
                                            setIssavebtn(false);
                                        }} />

                                    )}
                                    
                                </RadioGroup>

                            </FormControl>
                            
                            
                            
                            {studansmark==1?
                            <Typography>
                                <Typography variant="body2" mt={3} mb={1} className='text-deepblue' sx={{fontWeight: 'bold'}}>
                                Correct Answered: {correct.replace(/&apos;/g, "'")}
                                </Typography>
                            </Typography>
                            : studansmark=='-0.25'?
                            <Typography>
                                <Typography variant="body2" mt={3} mb={1} className='text-red' >
                                Wrong Answered: {wrong.replace(/&apos;/g, "'")}
                                </Typography>
                                <Typography variant="body2"  mb={1} className='text-greenlight'>
                                Correct Answer Is: {correct.replace(/&apos;/g, "'")}
                                </Typography>
                            </Typography>
                            : ''
                            }

                            {isalertnoted && noted!=null && noted.length>1?
                            <Typography variant="body2" mb={1} >
                            [Note: {noted.replace(/&apos;/g, "'")}]
                            </Typography>
                            :
                            ''}

                            <Grid Container mt={2} textAlign="center" className='btnRow'>
                            
                            {params.qsl > 1?
                            <Link to={"/liveexam/"+prev}><Button variant="contained" color='success'>prev</Button></Link>
                            :''
                            }

                            <Button variant="contained" color='info' disabled={issavebtn} onClick={savestudexamsheet}>Save</Button> 
                            
                            
                            <Button variant="contained" color='secondary' disabled={isskipbtn} onClick={skipstudexamsheet}>Skip</Button> 
                            
                            {params.qsl < 100?
                            isnextbtn?
                            <Button variant="contained" color='success' disabled={isnextbtn}>Next</Button>      
                            :
                            <Link to={"/liveexam/"+next}><Button variant="contained" color='success' disabled={isnextbtn}>Next</Button></Link>
                            :''
                            }
                            </Grid>






                            </Grid>

                            :
                            <QALoader/>
                            }




                        </CardContent>
                        
                        </Card >



                    </Grid>

                    {/* <Grid item xs={12}  p={1}><Button fullWidth variant='contained' className='btn-green' onClick={submitscore}>Live Score Submit</Button></Grid> */}
                    <Grid item xs={12}  p={1}><Button fullWidth variant='contained' className='btn-maroon' onClick={modalhandleOpen}>Live Score Submit</Button></Grid>

                    

                    {/* <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>
                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>

                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid> */}

                </Grid>   

                
                



                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={styles}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Exam : {localStorage.getItem("examname")}
                    </Typography>
                    
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Do You Want To Submit Your Live Online Exam Test?
                    </Typography>
                    <Typography id="modal-modal-description" className="text-red" sx={{ mt: 1, mb: 2 }}>
                        {/* <CircularIndeterminate size="lg" /> */}
                        {oneqst}
                    </Typography>
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={submitscore}>Final Submit</Button> <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>

                <Modal
                    open={loaderopen}
                    onClose={loaderhandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{backgroundColor: 'transparent',opacity: 1}}
                >
                    <Box sx={styles}>
                    
                    
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <img src="../images/submit_loader.gif" style={{display:'block', marginLeft: 'auto', marginRight: 'auto'}} />
                    </Typography>
                         
                    </Box>
                </Modal>


            </Container>

        </>
    );
}

export default LiveExamContent;






