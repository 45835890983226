import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
// import { HashRouter,Routes,  Route, Link } from 'react-router-dom';
// import {Routes, Route} from 'react-router-dom';


import Home from './layout/Home';
import ProfileSetting from './layout/ProfileSetting';
import Terms from './layout/Terms';
import Login from './layout/Login';
import ForgotPassword from './layout/ForgotPassword';
// import Liveexam from './layout/Liveexam';
import UpcomingLiveExam from './layout/UpcomingLiveExam';
import UpcomingPracticeExam from './layout/UpcomingPracticeExam';
import LiveExam from './layout/LiveExam';
import LiveScore from './layout/LiveScore';

import StartLiveExam from './layout/StartLIveExam';
import StartPracticeExam from './layout/StartPracticeExam';
import PracticeExam from './layout/PracticeExam';
import PracticeScore from './layout/PracticeScore';
import LiveScoreView from './layout/Livescoreview';
import MonthWiseScore from './layout/MonthWiseScore';

import PaymentHistory from './layout/PaymentHistory';
import ChangePassword from './layout/ChangePassword';
import FileUpload from './layout/FileUpload';
import UpdatePassword from './layout/UpdatePassword';
import Register from './layout/Register';
import SuccessStory from './layout/SuccessStory';
import ViewChart from './content/ViewChart';
import MonthWiseBarGraphContent from './content/MonthWiseBarGraphContent';
import ReactBarChart2 from './layout/ReactBarChart2';
import ThankYou from './layout/ThankYou';


import DashboardMocktest from './layout/DashboardMocktest';
import UpcomingLiveExamMocktest from './layout/UpcomingLiveExamMocktest';
import UpcomingPracticeExamMocktest from './layout/UpcomingPracticeExamMocktest';
import LiveScoreMocktest from './layout/LiveScoreMocktest';
import PracticeScoreMocktest from './layout/PracticeScoreMocktest';
import StartLiveExamMocktest from './layout/StartLIveExamMocktest';
import StartPracticeExamMocktest from './layout/StartPracticeExamMocktest';
import LiveExamMocktest from './layout/LiveExamMocktest';
import PracticeExamMocktest from './layout/PracticeExamMocktest';
import Packages from './layout/Packages';
import ExamPracticeSetList from './layout/ExamPracticeSetList';
import StartPracticeSet from './layout/StartPracticeSet';
import PracticeSet from './layout/PracticeSet';
import PracticeSetShuffle from './layout/PracticeSetShuffle';
import PackagePlan from './layout/PackagePlan';
import MockPracticeSetList from './layout/MockPracticeSetList';

// import ViewRazorpay from './content/ViewRazorpay';

// import {useNavigate} from "react-router-dom";

function App() {

  function openFullscreen() {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
      document.documentElement.msRequestFullscreen();
    }
  }

  useEffect(()=>{
    openFullscreen();
  }, []);

  return (
    <>
    {/* <BrowserRouter> */}
        <Routes>
          <Route exact path='/' element={<Terms />} />
          <Route exact path='/dashboard' element={<Home />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/forgotpassword' element={<ForgotPassword />} />
          <Route exact path='/upcomingliveexam' element={<UpcomingLiveExam />} />
          <Route exact path='/liveexam/:qsl' element={<LiveExam />} />
          <Route exact path='/livescore' element={<LiveScore />} />
          {/* <Route exact path='/livescoreview/:suid/:livexmid' element={<LiveScoreView />} /> */}
          <Route exact path='/livescoreview/:curdate/:livexmid' element={<LiveScoreView />} />
        
          <Route exact path='/upcomingpracticeexam' element={<UpcomingPracticeExam />} />
          <Route exact path='/practiceexam/:qsl' element={<PracticeExam />} />
          <Route exact path="/practicescore" element={<PracticeScore/>} />
          
          <Route exact path='/profilesetting' element={<ProfileSetting />} />

          <Route exact path='/startliveexam' element={<StartLiveExam />} />
          <Route exact path='/startpracticeexam' element={<StartPracticeExam />} />

          <Route exact path='/monthwisescore' element={<MonthWiseScore />} />

          <Route exact path='/paymenthistory' element={<PaymentHistory />} />

          <Route exact path='/changepassword' element={<ChangePassword />} />

          <Route exact path='/updatepassword' element={<UpdatePassword />} />

          <Route exact path='/fileupload' element={<FileUpload />} />

          <Route exact path='/successstory' element={<SuccessStory />} />

          <Route exact path='/viewchart' element={<ViewChart />} />
          <Route exact path='/reactbarchart2' element={<ReactBarChart2 />} />

          <Route exact path='/monthwisebargraph' element={<MonthWiseBarGraphContent />} />

          <Route exact path='/thankyou' element={<ThankYou />} />

          <Route exact path='/dashboardmocktest' element={<DashboardMocktest />} />
          <Route exact path='/upcomingliveexam_mock' element={<UpcomingLiveExamMocktest />} />
          <Route exact path='/upcomingpracticeexam_mock' element={<UpcomingPracticeExamMocktest />} />
          <Route exact path='/livescore_mock' element={<LiveScoreMocktest />} />
          <Route exact path='/practicescore_mock' element={<PracticeScoreMocktest />} />
          <Route exact path='/startliveexam_mock' element={<StartLiveExamMocktest />} />
          <Route exact path='/startpracticeexam_mock' element={<StartPracticeExamMocktest />} />
          <Route exact path='/liveexam_mock/:qsl' element={<LiveExamMocktest />} />
          <Route exact path='/practiceexam_mock/:qsl' element={<PracticeExamMocktest />} />
          <Route exact path='/packages/:packageid' element={<Packages />} />
          <Route exact path='/exampracticesetlist/:subpackageid/:packageid' element={<ExamPracticeSetList />} />
          <Route exact path='/startpracticeset' element={<StartPracticeSet />} />
          <Route exact path='/practiceset/:qsl' element={<PracticeSet />} />
          <Route exact path='/practicesetshuffle/:qsl' element={<PracticeSetShuffle />} />

          <Route exact path='/packageplan' element={<PackagePlan />} />

          <Route exact path='/mockpracticesetlist' element={<MockPracticeSetList />} />


          {/* <Route exact path='/viewrazorpay' element={<ViewRazorpay />} /> */}

        </Routes>
   {/* </BrowserRouter> */}

    </>
  );
}

export default App;
