import React  from 'react';

import StartPracticeSetContent from "../content/StartPracticeSetContent";

// import Header from "../inc/Header";
import BackHeader from "../inc/BackHeader";
import { Grid } from '@mui/material';


function StartPracticeSet() {
    return(
        <>
        
        <Grid className='bg-greenlight'>
        <Grid className='home_bg'>
        <BackHeader /> 
        </Grid>

        <Grid py={2}>
        <StartPracticeSetContent/>
        </Grid> 

        </Grid>

        
        </>
    );
}

export default StartPracticeSet;