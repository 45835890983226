import React  from 'react';

import PracticeScoreContent from "../content/PracticeScoreContent";

// import BackHeader from "../inc/BackHeader";
import { Grid } from '@mui/material';
import BackHeaderPracticeScore from '../inc/BackHeaderPracticeScore';



function PracticeScore() {
    return(
        <>
        
        <Grid className='bg-greenlight'>
        <Grid className='home_bg'>
        {/* <BackHeader />  */}
        <BackHeaderPracticeScore />
        </Grid>

        <Grid py={2}>
        <PracticeScoreContent/>
        </Grid> 
        </Grid>
        </>
    );
}

export default PracticeScore;