import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';
import {useNavigate} from 'react-router-dom';

import dateFormat from 'dateformat';

import axios from 'axios';

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { useParams } from 'react-router-dom';
import ExamListLoader from './ExamListLoader';

import Stack from '@mui/material/Stack';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function ExamPracticeSetListContent() {
    
    let navigate = useNavigate();
    const params=useParams();

    const dispatch = useDispatch();
    const data=useSelector((state) => {
        return state.users;
    });

    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    async function getReduxToken() {
        dispatch(getToken());
        setName(data.users.name);
        setEnrollid(data.users.enroll_id);
        setStatus(data.users.status);
        // console.log("profile pic ------->", data.users.name);
    }

    function getchecklogin() {
        if (localStorage.getItem("tokenID")==null) {
            let path = `/login`; 
            navigate(path);
        }
    }

    //UPCOMING PRACTICE EXAM LIST
    let [loader,setLoader]=useState(false);
    let [practicesetarr,setPracticesetarr]=useState([]);
    let [countexamallot,setCountexamallot]=useState("");
    let [parentpackagename,setParentpackagename]=useState("");
    let [subpackagename,setSubpackagename]=useState("");
    let [examcount,setExamcount]=useState("");

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    async function packagewisepracticesetlist() {
      setLoader(true);
      var fd=new FormData();
      fd.append("packageid",params.packageid);
      fd.append("subpackageid",params.subpackageid);
      fd.append("enrollid",localStorage.getItem("enrollid"));
      var resp=await axios.post("https://safalata.net/safalatalara/api/packagewisepracticesetlist",fd);
      var data=resp.data;
      setPracticesetarr(data.exam);
      setCountexamallot(data.count_exam_allot);
      setParentpackagename(data.package_name);
      setSubpackagename(data.subpackage_name);
      setExamcount(data.examcount);
      setLoader(false);
    }

    // let [orderstatus, setOrderstatus]=useState("");
    // async function getstudlogincheck() {
    //     var fd=new FormData();
    //     fd.append("enrollid",localStorage.getItem("enrollid"));
    //     var resp=await axios.post("https://safalata.net/safalatalara/api/studlogincheck",fd);
    //     var data=resp.data;
    //     setOrderstatus(data);
    // }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let openmsg="This Practice Set Will Soon.....";

    useEffect(()=>{
        getReduxToken();

        getchecklogin();

        packagewisepracticesetlist();

        // getstudlogincheck();

    }, []);

    return(
        <>

            <Container>
                <Grid container >
                    <Grid item xs={12}  p={1}>
                        {/* <Typography variant="p" component="p" fontWeight="bold" fontSize={18} pb={1}> 
                            Enrollment ID: <span>{enrollid}</span>
                        </Typography>
                        {status==1?
                        <Typography variant="p" component="p" fontWeight="bold" fontSize={18} color="#00630f" pb={1}> 
                            Status: ACTIVE
                        </Typography> 
                        :
                        <Typography variant="p" component="p" fontWeight="bold" fontSize={18} color="#eb0520" pb={1}> 
                            Status: INACTIVE
                        </Typography>
                        } */}
                        

                        <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
                        {subpackagename}
                        </Typography>


                        {loader==false?
                        // <Card>
                        // <CardContent>
                        countexamallot>=1? 
                            practicesetarr.map((e)=>

                            <Typography key={e.practiceset_slno}>
                                <Box sx={{borderRadius: 2, background: '#fff', lineHeight: 2, pl:0,pr:2, pt: 1, pb: 1, }} component="div" mb={1} color="maroon" onClick={()=>{
                                    if (e.imported_qa>0) {
                                        localStorage.setItem("shuffle",e.practiceexamsetselection_shuffle);
                                        console.log(localStorage.getItem("shuffle"));
                                        localStorage.setItem("practicesetid",e.practiceset_slno);
                                        let path = `/startpracticeset`;
                                        navigate(path);
                                    } else {
                                        handleOpen();
                                    }
                                     
                                }}>
                                    <Stack direction="row" alignItems="center" gap={0}>
                                    <ArrowRightIcon /><Typography variant="body1" sx={{fontSize:  '1.2rem'}}> {e.practiceset_name}</Typography>
                                    </Stack>
                       
                                {/* <Button size="small" variant='contained' onClick={()=>{
                                    let path = `/exampracticesetlist/`+e.package_id; 
                                    navigate(path);
                                }}>Exam List</Button>  */}
                    
                                </Box>
                                <Grid py={0.2}>
                                {/* <Divider  /> */}
                                </Grid>
                            </Typography>
                            
                            )
                        :
                        countexamallot==0?
                        <Typography variant="p" component="p" fontWeight="bold" color="#00630f" pb={1}> 
                            No Exam Found
                        </Typography>
                        :
                        ''
                        
                            
                           
          
                        :
                        <Card>
                        <CardContent>
                            <ExamListLoader />
                        </CardContent>
                        </Card>
                        }


                    

                        

                        
                        

                    </Grid>




                    

                    {/* <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>
                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid>

                    <Grid item xs={4}  p={2}>
                        <Button variant="contained" fullWidth>Contained</Button>     
                    </Grid> */}

                </Grid>   

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={styles}>
                    
                    <Typography id="modal-modal-description" className="text-red" sx={{ mt: 1, mb: 2 }}>
                        {openmsg}
                    </Typography>
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>



            </Container>

        </>
    );
}

export default ExamPracticeSetListContent;
