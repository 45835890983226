import React, { useState } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';



import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";

import DehazeOutlinedIcon from '@mui/icons-material/DehazeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import Leftnav from "../inc/Leftnav";

import {useNavigate} from "react-router-dom";


const HeaderMocktest = ({ variant, ...props }) => {
    let navigate = useNavigate();
//   const [open, setOpen] = useState(false);

  return (
<>

<Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" color="transparent" className="navbar">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}  alignItems="center">
            News
          </Typography> */}
          <Grid display="flex" sx={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
          <img src="../images/logo.png" />
          </Grid>
          <Button variant="contained"  startIcon={<PowerSettingsNewIcon style={{ color: 'white', size: '30px' }} />}  className="btnRounded-logout btn" onClick={()=>{
            localStorage.removeItem('enrollid_mock');
            let path = `/login`;
            navigate(path);
          }}></Button>
        </Toolbar>
      </AppBar>
    </Box>




    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        {/* <Drawer
          variant={variant}
          {...props}
          open={open}
          onClose={() => setOpen(false)}  className="drawerBox"
        >

           
            <Leftnav />

        </Drawer> */}
      </Grid>

      
    </Grid>
    </>

  );
};

export default HeaderMocktest;