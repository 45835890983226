import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import dateFormat from 'dateformat';

import axios from 'axios';

import {useNavigate} from 'react-router-dom';

function StartPracticeExamMocktestContent() {
    let navigate = useNavigate();

    let [isbtn,setIsbtn]=useState(true);


    if (localStorage.getItem("practicesetid")==null) {
        let path = `/dashboardmocktest`;
        navigate(path);
    }

    //PRACTICE EXAM DETAILS
    let [examname,setExamname]=useState("");
    let [examtotalqst,setExamtotalqst]=useState("");
    let [examstdate,setExamstdate]=useState("");
    let [exameddate,setExameddate]=useState("");

    async function practiceexamdetails() {
      let practicesetid=localStorage.getItem("practicesetid");
      var fd=new FormData();
      fd.append("practicesetid",practicesetid);
      var resp=await axios.post("https://safalata.net/safalatalara/api/practiceexamdetails_mock",fd);
      var data=resp.data;
      setExamname(data.practicesetname);
      setExamstdate(data.stdate);
      setExameddate(data.eddate);
      setExamtotalqst(data.qcount);

      localStorage.setItem("examname",data.practicesetname);
      localStorage.setItem("exameddate",data.eddate);
      localStorage.setItem("qcount",data.qcount);

      localStorage.setItem("shuffle",data.shuffle);
    }

    useEffect(()=>{
      practiceexamdetails();

    },[])

     return(


        <>

<Container>
    <Grid container >
        <Grid item xs={12}  p={1}>

            <Typography variant="h6" component="h6" pt={1.5} pb={1.5} fontWeight="normal"> 
            Start Practice Exam For Mock Test
            </Typography>

            <Card className='borderRadius10'>
            <CardContent>
                <Typography variant="h6" component="div" mb={2} textAlign="center" className='text-green lineheight'>
                Go To Test Your <br />Practice Online Exam
                </Typography>
                <Typography mb={1}>
                This is Multiple Choice Practice Set To Test Your Knowledge
                </Typography>
                <Typography variant="body2"  mb={1} >
                practice Exam Set Name : {examname}
                </Typography>

                <Typography variant="body2"  mb={1} >
                Number of Questions : {examtotalqst}
                </Typography>
                <Typography variant="body2"  mb={1} >
                Exam Date & Time : {dateFormat(examstdate, "dd-mm-yyyy ( HH:MM TT")} - {dateFormat(exameddate, "HH:MM TT )")}
                </Typography>
                <Typography variant="body2"  mb={1} >
                Question Type : Multiple Choice
                </Typography>

                                       
                

            </CardContent>
            
            </Card >


             

        </Grid>

        <Grid item xs={12} p={1}>
            <Button fullWidth variant='contained' className='btn-green' onClick={async()=>{
                let path = `/practiceexam_mock/1`;
                navigate(path);
            }}>Start Exam</Button>
        </Grid>

    </Grid>   
</Container>

        </>
    );
}

export default StartPracticeExamMocktestContent;






