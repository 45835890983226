import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getSuccessStory = createAsyncThunk(
    "getSuccessStory",
    async (args, { rejectWithValue }) => {
      try {
        var resp=await axios.get("https://www.safalata.in/safalatalara/api/successallcandidate");
        var data=resp.data;
        
        // console.log('axios--------->',data);

        return data;

      } catch (err) {
          return rejectWithValue("Opps found an error", err.response.data);
          // let path = `/`; 
          // navigate(path);
      }
    }
);


export const SuccessStorySlice = createSlice({
  name: "getSuccessStory",
  initialState: {
      successall: [],
      loading: false,
      error: null,
      // searchData: [],
  },
  extraReducers: {
      
      //get single user
      [getSuccessStory.pending]: (state) => {
        state.loading = true;
      },
      [getSuccessStory.fulfilled]: (state, action) => {
        state.loading = false;
        state.successall = action.payload;
        // console.log("fulfilled action --->", action.payload)
      },
      [getSuccessStory.rejected]: (state) => {
        state.loading = false;
      },
      
  }
});
export default SuccessStorySlice.reducer;