import React, { useState, useEffect } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Typography from '@mui/material/Typography';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Link as RouterLink } from 'react-router-dom';

import Leftnav from "../inc/Leftnav";

import {useNavigate} from "react-router-dom";

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

import axios from "axios";

import Modal from '@mui/material/Modal';
import dateFormat from 'dateformat';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ExamBackHeaderPackage = ({ variant, ...props }) => {
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();

  let [pic,setPic]=useState("");
  const dispatch = useDispatch();

  const data=useSelector((state) => {
    return state.users;
  });

  async function getReduxToken() {
    dispatch(getToken());
    console.log(data.users.pic)
    if (data.users.pic===null || data.users.pic==="") {
      setPic('user.png');
    } else {
      setPic(data.users.pic);
    }
    
    console.log("profile pic ---->", data.users.pic);
  }
  
  // async function getToken() {
  //   var tokenval=localStorage.getItem("tokenID");
  //   if (tokenval!=null) {
  //       var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
  //         headers: {
  //             "Content-Type":"application/json",
  //             "Authorization":"Bearer "+tokenval
  //         }
  //       });
  //       var data=await resp.json();
  //       setPic(data.pic);
  //       localStorage.setItem("pic",data.pic);
  //   } else {
  //       let path = `/`; 
  //       navigate(path);
  //   }
  // }

    const [openmodal, setOpenmodal] = useState(false);
    const handleOpen = () => setOpenmodal(true);
    const handleClose = () => setOpenmodal(false);

    let [oneqst,setOneqst]=useState("");

    // let [circleloader,setCircleloader]=useState(false);

    const [loaderopen, setLoaderopen] = useState(false);
    const loaderhandleOpen = () => setLoaderopen(true);
    const loaderhandleClose = () => setLoaderopen(true);

    const loaderhandlemodalClose = () => setLoaderopen(false);


    function modalhandleOpen() {
        setOneqst("");
        handleOpen();
    }

    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    let [loader,setLoader]=useState(false);

    const yearmonth=dateFormat(now, "yyyy-mmmm");

    async function gobackNavigate() {

        setOneqst("");
        handleOpen();

    }

    async function submitscore() {
        if (localStorage.getItem('querypagecount')>0) {

            handleClose();

            loaderhandleOpen();

            var fd=new FormData();
            fd.append("practicesetid",localStorage.getItem("practicesetid"));
            fd.append("enrollid",localStorage.getItem("enrollid"));
            fd.append("examtotalqst",localStorage.getItem("qcount"));
            fd.append("curdate",curdate);
            fd.append("yearmonth",yearmonth);
            var resp=await axios.post("https://safalata.net/safalatalara/api/submitscorestudpracticeset_sec",fd);
            var data=resp.data;

            setTimeout(()=>{
                 //Remove List of Exam localStorage
                 localStorage.removeItem("practicesetid");
                 localStorage.removeItem("qcount");
                 localStorage.removeItem("shuffle");
                 localStorage.removeItem('qid');
                 localStorage.removeItem('studansoption');
                 localStorage.removeItem('querycountcheck');
                 localStorage.removeItem("querypagecount");

                 loaderhandlemodalClose();
                 let path = `/practicescore`; 
                 navigate(path);

            },3000);
        } else {
            setOneqst("Please, Attend One Question Before Submit Score..");
        }
    }


    useEffect(()=>{
        getReduxToken();
        // dispatch(getToken());
        // setPic(data.pic);
        // console.log(data);
        
    },[])

    return (
        <>

        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="sticky" color="transparent" className="navbar">
                <Toolbar>
                {/* <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton> */}
                <Typography onClick={gobackNavigate}>
                <Button startIcon={<NavigateBeforeIcon style={{ fontSize: 50 }} />} labelStyle={{ fontSize: '63px'}} component={RouterLink} className="btnBack"></Button>
                {/* to="/dashboard" */}
                </Typography>

                {/* <Button startIcon={<NavigateBeforeIcon style={{ fontSize: 50 }} />} labelStyle={{ fontSize: '63px'}} component={RouterLink} to="/dashboard"  className="btnBack" ></Button> */}

                <Grid display="flex"  sx={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
                <img src="images/logo.png" />
                </Grid>
                {/* <Button variant="contained"  startIcon={<DehazeOutlinedIcon />}  onClick={() => setOpen(!open)} className="btnRounded" ></Button> */}
                <Button className="btnUser">
                    {pic!='user.png'?
                    <img src={"https://safalata.net/safalatalara/uploadpic/"+pic} className="btnUser" />
                    :
                    <img src="images/user.png" className="btnUser" />
                    }
                </Button>
                </Toolbar>
            </AppBar>
            </Box>




            <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item>
                <Drawer
                variant={variant}
                {...props}
                open={open}
                onClose={() => setOpen(false)}  className="drawerBox"
                >

                
                    <Leftnav />

                
                </Drawer>
            </Grid>

            
            </Grid>

            <Modal
                open={openmodal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <Box sx={styles}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Exam : {localStorage.getItem("examname")}
                </Typography>
                
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Do You Want To Submit Your Practice Online Exam Test?
                </Typography>
                <Typography id="modal-modal-description" className="text-red" sx={{ mt: 1, mb: 2 }}>
                    {/* <CircularIndeterminate size="lg" /> */}
                    {oneqst}
                </Typography>
                <Grid Container mt={2} textAlign="center" className='btnRow'>
                    <Button variant="contained" color='info' onClick={submitscore}>Final Submit</Button> <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                </Grid>     
                </Box>
            </Modal>

            <Modal
                open={loaderopen}
                onClose={loaderhandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{backgroundColor: 'transparent',opacity: 1}}
            >
                <Box sx={styles}>
                
                
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <img src="../images/submit_loader.gif" style={{display:'block', marginLeft: 'auto', marginRight: 'auto'}} />
                </Typography>
                        
                </Box>
            </Modal>
            </>

    );
};

export default ExamBackHeaderPackage;