import {Link} from 'react-router-dom';

import React, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import dateFormat from 'dateformat';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Divider from '@mui/material/Divider';

import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useSelector,useDispatch } from 'react-redux';
import { getToken } from "../IncSlice/BackHeaderSlice";

import {useNavigate} from 'react-router-dom';

import PackageLoader from './PackageLoader';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function createData(rank, name, mark) {
    return { rank, name, mark };
  }
  
  // const rows = [
  //   createData(1, 'Ashok', 86.25),
  //   createData(2, 'Ashok', 86.25),
  //   createData(3, 'Ashok', 86.25),
  // ];

function MonthWiseScoreContent() {

    let navigate = useNavigate();

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };


    // let [studname,setStudname]=useState('');
    // let [enrollid,setEnrollid]=useState('');
    // let [studstatus,setStudstatus]=useState('');
    // let [batchname,setBatchname]=useState('');

    const dispatch = useDispatch();
    const data=useSelector((state) => {
        return state.users;
    });

    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    async function getReduxToken() {
        dispatch(getToken());
        setName(data.users.name);
        setEnrollid(data.users.enroll_id);
        setStatus(data.users.status);
        // console.log("profile pic ------->", data.users.name);
    }


    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");



    // //LOADER
    // let [loader,setLoader]=useState(false);
    // //LOADER

    // let [pic,setPic]=useState(null);

    // async function getToken() {

    //   setLoader(true);

    //   var tokenval=localStorage.getItem("tokenID");
    //   if (tokenval!=null) {
    //     var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
    //       headers: {
    //         "Content-Type":"application/json",
    //         "Authorization":"Bearer "+tokenval
    //       }
    //     });
    //     var data=await resp.json();
    //     setStudname(data.name);
    //     setEnrollid(data.enroll_id);
    //     setStudstatus(data.status);
    //     setPic(data.pic);

    //     if (data.name.length>1 && localStorage.getItem("enrollid")==null) {
    //       localStorage.setItem("enrollid",data.enroll_id);
    //     }

    //     var fd=new FormData();
    //     fd.append("batchid",data.batch_slno);
    //     var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
    //     var data2=resp2.data;
    //     setBatchname(data2);

    //     setLoader(false);

    //   } else {
    //     window.location.href="/";
    //   }

    // }


    //PRACTICE EXAM SCORE LIST
    let [practicescorearr,setPracticescorearr]=useState([]);
    let [monthyeararr,setMonthyeararr]=useState([]);


    let [yearmonth,setYearmonth]=useState("");
    
    async function getstudscoremonthyear() {
        // alert(localStorage.getItem("enrollid"));
        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.net/safalatalara/api/getstudscoremonthyear_practice",fd);
        var data=resp.data;
        setMonthyeararr(data);
        // console.log()
    }

    // let [noticename, setNoticename]=useState("");
    // let [noticedatetime, setNoticedatetime]=useState("");

    // async function getnoticeboard() {
    //     var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard");
    //     var data=resp.data;
    //     setNoticename(data.noticename);
    //     setNoticedatetime(data.noticedatetime);
    // }


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let [oneqst,setOneqst]=useState("");

    function modalhandleOpen() {
        setOneqst("");
        handleOpen();
    }

    let [loader,setLoader]=useState(false);


    useEffect(()=>{

      // getToken();
      getReduxToken();

      getstudscoremonthyear();
      // getNostorage();
      // getnoticeboard();

    },[])

    return(
        <>

          <Container>
              <Grid container >
                  <Grid item xs={12}  p={1}>
                  <Typography variant="h6" component="h6" pb={1.5} fontWeight="bold" color="red"> 
                      {name}
                  </Typography>
                  {/* <Typography variant="p" component="p" pb={1}> 
                      Batch Name: <span>{batchname}</span>
                  </Typography> */}
                  <Typography variant="p" component="p" pb={1}> 
                      Enrollment ID: <span>{enrollid}</span>
                  </Typography>
                  {status==1?
                  <Typography variant="p" component="p" fontWeight="bold" color="#00630f" pb={1}> 
                      Student Status: ACTIVE
                  </Typography> 
                  :
                  <Typography variant="p" component="p" fontWeight="bold" color="#eb0520" pb={1}> 
                      Student Status: INACTIVE
                  </Typography>
                  }
                      

                      {/* <Grid container direction="row" justifyContent="center" alignItems="center" pb={1}>   */}
                      <Paper>
                      <Grid item p={1} container direction="row" justifyContent="center" alignItems="center" >
                    
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="demo-simple-select-standard-label">Select Month</InputLabel>
                          <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={yearmonth}
                          label="Select Month"
                          onChange={async(ev)=>{
                            setPracticescorearr([]);
                            if (ev.target.value.length>0) {                                
                                setLoader(true);
                                var fd=new FormData();
                                fd.append("enrollid",localStorage.getItem("enrollid"));
                                fd.append("yearmonth",ev.target.value);
                                var resp=await axios.post("https://safalata.net/safalatalara/api/practicescorelistbyyearmonth",fd);
                                var data=resp.data;
                                console.log(data.practicescore)
                                setPracticescorearr(data.practicescore);
                                setYearmonth(ev.target.value);
                                setLoader(false);
                            } else {
                                setLoader(true);
                                setPracticescorearr([]);
                                setYearmonth("");
                                setLoader(false);
                            }
                            
                          }}
                           >
                          <MenuItem value="">
                              <em>None</em>
                          </MenuItem>
                          {monthyeararr.map((e)=>
                          <MenuItem value={e.studpracticeexamscore_year_month}>{e.studpracticeexamscore_year_month}</MenuItem>
                          )}
                          </Select>
                        </FormControl>
                          
                          {/* <Grid justifyContent="center" p={1}><Button size="small" variant='contained'>Back</Button>  </Grid>
                          <Grid justifyContent="center" p={1}><Button size="small" variant='contained' color='secondary'>Download</Button></Grid>     */}
                      </Grid>
                      </Paper>
                      
                      
                      <Typography variant="h6" component="h6" pt={1.5} pb={.5} fontWeight="normal"> 
                      Month Wise Score List<br/>{yearmonth.length>0? '['+yearmonth+']' : ''}
                      </Typography>
                      
                      <Typography variant="h6" component="h6" pl={2} pt={0} pb={1} fontWeight="normal">
                        <Button variant='contained' className='btn-litebrown' disabled onClick={()=>{
                        // let path = `/monthwisescore`;
                        // navigate(path);
                      }}>Score List</Button> &nbsp; &nbsp;
                      {/* <Button variant='contained' ml={2} className='btn-brown' onClick={()=>{
                          let path = `../reactbarchart2`;
                          navigate(path);
                      }}>Month Wise Bar Graph</Button> */}
                      </Typography>

                      <Card>
                       
                      <CardContent className='font14'>

                      
                    {!loader?
                      practicescorearr.map((e)=>
                      <Typography key={e.stud_practice_set_id}>
                          <Typography variant="h6" sx={{fontSize: '16px'}} component="div" mb={1} color="maroon">
                          {e.practiceset_name}
                          </Typography>
                      
                          <Grid container >
                          <Grid item xs={5}  className='fontBold'>Score Date</Grid>
                          <Grid item xs={5}  className='fontBold'>Total Mark</Grid>
                          <Grid item xs={2}  className='fontBold'>Scored</Grid>
                          <Grid item xs={5}  pb={2} className='fontBold'>{dateFormat(e.studpracticeexamscore_date, "dd/mm/yyyy")}</Grid>
                          <Grid item xs={5}  pb={2}>{e.total_mark}%</Grid>
                          <Grid item xs={2}  pb={2}>{e.stud_practiceset_score}</Grid>
                          </Grid>

                          <Grid container >
                          <Grid item xs={5}  className='fontBold'>Correct</Grid>
                          <Grid item xs={5}  className='fontBold'>Wrong</Grid>
                          <Grid item xs={2}  className='fontBold'>Skip</Grid>
                          <Grid item xs={5}  pb={2}>{e.studpracticeexamscore_correct}</Grid>
                          <Grid item xs={5}  pb={2}>{e.studpracticeexamscore_wrong}</Grid>
                          <Grid item xs={2}  pb={2}>{e.studpracticeexamscore_skip}</Grid>
                          </Grid>

                          
                          <Grid py={2}>
                          <Divider  />
                          </Grid>
                      </Typography>
                      )
                    :
                    <PackageLoader />
                    }


                      </CardContent>
                      
                      </Card>



                  </Grid>
              </Grid>   

              <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={styles}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Selection First
                    </Typography>
                    
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        First Select Year Month
                    </Typography>
                    
                    <Grid Container mt={2} textAlign="center" className='btnRow'>
                        <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                    </Grid>     
                    </Box>
                </Modal>

          </Container>

        </>
    );
}

export default MonthWiseScoreContent;






