import React  from 'react';

import StartLiveExamMocktestContent from "../content/StartLiveExamMocktestContent";

// import Header from "../inc/Header";
import BackHeaderMock from "../inc/BackHeaderMock";
import { Grid } from '@mui/material';


function StartLiveExamMocktest() {
    return(
        <>
        
        <Grid className='bg-greenlight-mock'>
        <Grid className='home_bg'>
        <BackHeaderMock /> 
        </Grid>

        <Grid py={2}>
        <StartLiveExamMocktestContent/>
        </Grid> 

        </Grid>

        
        </>
    );
}

export default StartLiveExamMocktest;