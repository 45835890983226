import React  from 'react';

import LiveExamMocktestContent from "../content/LiveExamMocktestContent";

// import BackHeader from "../inc/BackHeader";
import ExamBackHeaderMock from '../inc/ExamBackHeaderMock';

import { Grid } from '@mui/material';



function LiveExamMocktest() {
    return(
        <>
        
        <Grid className='terms_bg '>
        <Grid className='home_bg'>
        {/* <BackHeader />  */}
        <ExamBackHeaderMock />
        </Grid>

        <Grid py={2}>
        <LiveExamMocktestContent/>
        </Grid> 

        </Grid>

        
        
       
        </>
    );
}

export default LiveExamMocktest;