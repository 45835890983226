import React, { useState, useEffect } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Leftnav from "../inc/Leftnav";

import {useNavigate} from "react-router-dom";

const ExamBackHeaderMock = ({ variant, ...props }) => {
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();

  // let [pic,setPic]=useState("");
  // const dispatch = useDispatch();

  // const data=useSelector((state) => {
  //   return state.users;
  // });

  // async function getReduxToken() {
  //   dispatch(getToken());
  //   console.log(data.users.pic)
  //   if (data.users.pic===null || data.users.pic==="") {
  //     setPic('user.png');
  //   } else {
  //     setPic(data.users.pic);
  //   }
    
  //   console.log("profile pic ---->", data.users.pic);
  // }
  
  // async function getToken() {
  //   var tokenval=localStorage.getItem("tokenID");
  //   if (tokenval!=null) {
  //       var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
  //         headers: {
  //             "Content-Type":"application/json",
  //             "Authorization":"Bearer "+tokenval
  //         }
  //       });
  //       var data=await resp.json();
  //       setPic(data.pic);
  //       localStorage.setItem("pic",data.pic);
  //   } else {
  //       let path = `/`; 
  //       navigate(path);
  //   }
  // }
    
  useEffect(()=>{
      // getReduxToken();
      // dispatch(getToken());
      // setPic(data.pic);
      // console.log(data);
  },[])

  return (
<>

<Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" color="transparent" className="navbar">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}  alignItems="center">
            News
          </Typography> */}

          {/* <Button startIcon={<NavigateBeforeIcon style={{ fontSize: 50 }} />} labelStyle={{ fontSize: '63px'}} component={RouterLink} to="/dashboard"  className="btnBack" ></Button> */}

          <Grid display="flex"  sx={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
          <img src="images/logo.png" />
          </Grid>
          {/* <Button variant="contained"  startIcon={<DehazeOutlinedIcon />}  onClick={() => setOpen(!open)} className="btnRounded" ></Button> */}
          {/* <Button variant="contained"  startIcon={<LogoutIcon style={{ color: 'white', size: '30px' }} />}  className="btnRounded-logout btn" onClick={()=>{
            localStorage.removeItem('enrollid_mock');
            let path = `/login`;
            navigate(path);
          }}></Button> */}
        </Toolbar>
      </AppBar>
    </Box>




    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Drawer
          variant={variant}
          {...props}
          open={open}
          onClose={() => setOpen(false)}  className="drawerBox"
        >

           
            <Leftnav />

          
        </Drawer>
      </Grid>

      
    </Grid>
    </>

  );
};

export default ExamBackHeaderMock;